.legals {
  .page-tabs__content-left {
    @extend %typo-base-body;
    padding: var(--gap-xxl) grid(0, 1);
    color: var(--color-grey-medium);

    &.centered {
      padding: var(--gap-xxl) grid(0, 3);
    }
  }

  h1 {
    @extend %typo-display-uppercase;
    color: var(--color-black);

    &:not(:first-of-type) {
      margin-top: rem(80);
    }
  }

  h2 {
    margin-top: rem(40);
    color: var(--color-black);
    @extend %typo-display-uppercase-s;
    scroll-margin-top: calc(rem(40) + var(--page-top) + rem(65));
  }


  h3 {
    margin-top: rem(10);
    margin-bottom: rem(10);
    color: var(--color-main);

  }

  table {
    border-collapse: collapse;
  }

  th {
    color: var(--color-black);
    text-align: left;
    white-space: nowrap;
  }

  tr {
    margin-top: var(--padding-m);
  }

  td,th {
    padding: var(--padding-s)  var(--padding-m);
    border: var(--border-size) solid var(--color-grey-divider-light);
    vertical-align: top;
  }

  p {
    margin-top: var(--padding-s);

    a {
      color: var(--color-black);
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  ul {
    padding-left: var(--padding-m);

    li {
      list-style-type: square;
    }
  }

  table {
    margin: rem(20) 0;
  }

  .insert {
    margin: rem(20) 0;
    padding: var(--padding-m);
    border: var(--border-size) solid var(--color-grey-medium);

    h2 { margin-top: 0; }
  }

  @include tablet-portrait {
    .page-tabs__content-left {
      padding: rem(60) grid(0, 1) !important;
    }
  }
}
