.panel {
  .login {
    .auth__title {
      display: flex;
    }

    .auth__wrapper {
      height: calc(100% - rem(102));
      width: 100%;
      padding-top: 0;
    }
  }
}

.login {
  .auth__title {
    display: none;
  }

  .auth__wrapper {
    height: auto;
    width: grid(6, 2);
    padding-top: var(--gap-xxl);
  }
}

@include tablet-portrait {
  .panel {
    .login {
      .auth__wrapper {
        padding-top: 0;
      }
    }
  }

  .login {
    .auth__wrapper {
      padding-top: var(--gap-xl);
    }
  }
}
