.infinite-line {
  position: relative;
  overflow: hidden;

  &:not(.infinite-line--vertical) {
    width: 100%;
  }

  & > * {
    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
    }
    will-change: transform;
  }
}
