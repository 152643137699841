.product-zoom {
  position: fixed;
  z-index: index('overlay-3');
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  background-color: var(--color-background);

  &.visible {
    display: block;
  }

  * {
    user-select: none;
  }

  & &__wrapper, & &__header {
    top: 0;
  }

  &__header {
    position: fixed;
    z-index: 500;
    top: 0;
    right: 0;
    height: auto;
  }

  &__wrapper {
    position: relative;
    z-index: index('overlay-3');
    top: 0;
    right: 0;
    left: 0;
    height: calc(var(--vh) - var(--header-top));



    // cursor: url(../svg/close2.svg) 50 50, not-allowed;

    // .prev & {
    //   cursor: url(../svg/next.svg) 50 50, not-allowed;
    // }

    // .next & {
    //   cursor: e-resize;
    // }
  }

  & &__wrapper {
    z-index: index('overlay-6');
    height: var(--vh);
  }

  & &__header {
    height: rem($header-height);
    align-items: center;
    justify-content: flex-end;

    // .desktop & {
    //   display: none;
    // }
  }

  // &__next, &__prev {
  //   position: fixed;
  //   top: 50%;
  //   z-index: 302;
  //   background-image: url("../svg/next.svg");
  //   background-size: 24rem auto;

  //   .no-desktop & {
  //     display: none;
  //   }
  // }

  // &__next {
  //   right: oldGutters(1);
  // }

  // &__prev {
  //   left: oldGutters(1);
  //   transform: scaleX(-1);
  // }

  .close__button {
    position: fixed;
    z-index: 2;
    top: var(--padding-s);
    right: var(--padding-s);
    overflow: hidden;
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: rem(10) auto;
  }

  &.opened & {
    &__wrapper, &__header {
      pointer-events: auto;
      transform: none;
    }

    &__header {
      opacity: 1;

      // transform: none;
    }
  }

  // visibility: hidden;

  &__products-wrapper {
    position: absolute;
    z-index: 10;
    bottom: oldGutters(1);
  }

  &__inner {
    display: flex;
    overflow: hidden auto;
    height: 100%;
    flex-direction: column;
    overscroll-behavior: contain;
    @include custom-scrollbar;
    transform: none !important;
  }

  &__description {
    position: fixed;
    z-index: 10;
    top: var(--padding-m);
    left: var(--padding-m);
    max-width: grid(3);
    pointer-events: none;

    & p {
      @include style(typo-base-uppercase)
    }

    & ul {
      padding-top: rem(5);
      padding-left: var(--padding-m);
    }

    & li {
      @include style(typo-base-body);
      font-size: rem(14);
      list-style: square;

      &::marker {
        width: rem(3);
        height: rem(3);
      }
    }
  }

  &__track-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  &__track {
    position: fixed;
    z-index: 3;
    right: var(--padding-m);
    bottom: var(--padding-m);
    width: rem(43.6);

    > li img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    > li video {
      display: none;
    }

    &-inner {
      position: absolute;
      top: var(--zoom-thumb-top);
      left: 0;
      width: 100%;
      height: var(--zoom-thumb-height);
      background: #001662;
      opacity: .3;
      pointer-events: none;
    }
  }

  &__item {
    width: var(--vw);
    height: auto;
    flex-shrink: 0;

    &-img {
      width: 100%;
      height: auto;
      pointer-events: none;
    }

    video {
      display: none;
    }

    &-inner {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }
}

@include tablet-portrait {
  .product-zoom {
    &__close {
      width: rem(32);
      height: rem(32);
    }

    & &__header {
      align-items: start;
      justify-content: flex-end;
    }

    &__inner {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    &__description {
      display: none;
    }

    &__item {
      width: 100%;
      height: auto;

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-inner {
        width: 100%;
        height: auto;
        padding: 0;
      }
    }
  }
}
