@use 'sass:math';

.product-colors {
  width: 100%;
  padding: oldGutters(1) 0;
  border-bottom: 1px solid $border-color;

  &__title {
    margin-bottom: oldGutters(1);

    span {
      display: inline-block;
      margin-right: rem(5);
      color: var(--color-grey-medium);
    }
  }

  &__prev, &__next {
    position: absolute;
    z-index: 10;
    top: 0;
    width: rem(30);
    height: 100%;
    background-image: url("../svg/next.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: rem($icon-size) auto;
    text-indent: 999em;
    transition: transform .3s $cubic-ease-out;
    white-space: nowrap;
  }

  &__prev {
    left: -1px;
    transform: translateX(-100%) scaleX(-1);

    &.visible {
      transform: scaleX(-1);
    }
  }

  &__next {
    right: -1px;
    transform: translateX(100%);

    &.visible {
      transform: none;
    }
  }

  &__list {
    display: inline-flex;
    transition: transform .5s $cubic-ease-in-out;

    // flex-flow: wrap;

    &-inner {
      line-height: 0;

      // overflow: hidden;
    }

    &-wrapper {
      position: relative;
      overflow: hidden;
      padding-right: rem(30);
    }
  }

  &__link {
    display: block;
    width: 100%;
  }

  &__item {
    width: rem(48);
    flex-shrink: 0;
    transition: opacity .3s linear;
    @include keepRatio(math.div(48, 65));

    &.fadeOut {
      opacity: 0;
    }

    &.selected {
      &::after {
        box-sizing: border-box;
        border: 1px solid $dark;
        content: '';
        @include fullscreen;
      }
    }

    &:not(:last-child) {
      margin-right: rem(30);
    }

    img {
      width: 100%;
      height: 100%;
      box-sizing: cover;
    }
  }

  .open-color-selector {
    padding: var(--padding-xs);
    border-radius: rem(3);
    background-color: rgb(51 255 0);
  }
}

@include tablet-portrait {
  .product-colors {
    padding: oldGuttersM(2) 0 oldGuttersM(1);

    &__title {
      margin-bottom: oldGuttersM(2);
    }

    &__prev, &__next {
      display: none;
    }

    &__list {
      padding-bottom: oldGuttersM(1);
      transform: none !important;

      &-inner {
        overflow: auto hidden;
      }

      &-wrapper {
        padding-right: 0;
      }
    }

    &__item {
      width: rem(60);

      &.fadeOut {
        opacity: 1;
      }

      &:first-child {
        display: none;
      }

      &:not(:last-child) {
        margin-right: oldGuttersM(1);
      }
    }
  }
}
