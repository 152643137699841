.select {
  position: relative;

  &__announcement {
    overflow: hidden;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

   button {
    position: relative;
    display: flex;
    align-items: center;
    background-color: var(--color-background);

    i {
      transition: transform .3s ease-in;
    }

    &[aria-expanded="true"] {
      i {
        transform: rotate(180deg);
      }
    }

  }


  ul {
    position: absolute;
    z-index: 2;
    top: calc(100% + var(--padding-m));
    right: 0;
    left: 0;
    display: flex;
    width: max-content;
    min-width: rem(290);
    flex-direction: column;
    padding: var(--padding-m);
    background-color: var(--color-background);
    box-shadow: 0 rem(4) rem(40) 0 rgba(0 0 0 / 10%);
    gap: var(--gap-xxs);
    list-style-type: none;
    opacity: 0;
    overflow-y: auto;
    pointer-events: none;
    transform-origin: top left;
    transition: all .3s ease-in;
    @include hide-scrollbar;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    li {
      position: relative;
      padding: rem(.6) rem(.5);
      cursor: pointer;
      transition: all .3s ease-in;
      @include style(typo-base-uppercase);

      &.active {
        // border: 2px solid;
      }

      &.active::before {
        opacity: 1;
      }
    }
  }
}
