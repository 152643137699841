.recap {
  padding: 0;
  border: 1px solid $border-color;
  margin-bottom: oldGutters(1);
  @extend %typo-base-uppercase;

  & .icon {
    width: rem(32);
    height: rem(32);

    &--hidden {
      display: none;
      width: auto;
    }
  }

  &__step {
    display: flex;
    align-items: center;
    padding: var(--padding-s) var(--padding-m) var(--padding-s) var(--padding-s);
    gap: rem(10);

    &.large__address {
      align-items: flex-start;
      padding: var(--padding-m) var(--padding-m) var(--padding-m) var(--padding-s);
    }

    & + & {
      border-top: 1px solid $border-color;
    }


    &.editable {
      justify-content: space-between;



      .recap__title-wrapper {
        display: flex;
        gap: rem(10);
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: rem(10);

    .large__address & {
      align-items: flex-start;

      .icon {
        margin-top: calc(-1 * var(--padding-s));
      }
    }

    :not(.editable) & {
      &:has(.icon--hidden) {
        width: rem(80);

      }
    }
  }

  &__content {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    text-decoration: none !important;

    img {
      margin-right: rem(10);
    }
  }

  &__link, &__content {
    color: var(--color-grey-medium);

    --color: var(--color-grey-medium);
  }
}

@include tablet-portrait {
  .recap {
    position: relative;
    margin-bottom: rem(30);

    &__step {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: var(--padding-s) var(--padding-m) var(--padding-m) var(--padding-s);
      gap: 0;

      &.editable {
        flex-direction: row;
        align-items: flex-start;

        .button {
          position: absolute;
          right: var(--padding-m);
          margin-top: var(--padding-s);
        }

        .recap__title {
          align-items: flex-start;

          &-wrapper {
            display: flex;
            width: grid(3.5, 1);
            flex-direction: column;
            padding-top: rem(7.5);
            gap: rem(7.5);

            .recap__content {
              margin: 0;
            }
          }
        }
      }
    }

    &__content {
      width: 100%;
      flex-direction: column;
      flex-shrink: 0;
      order: 99;
      margin-left: calc(var(--padding-s) + rem(32));
    }
  }
}
