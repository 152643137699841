.toaster {
  position: fixed;
  z-index: 900;
  bottom: var(--padding-s);
  left: grid(0,1);
  display: flex;
  flex-direction: column;
  gap: var(--padding-s);
  pointer-events: none;


  &__message {
    @include style(typo-base-uppercase);
    position: relative;
    width: grid(4, 0);
    height: var(--height);
    box-sizing: content-box;
    color: var(--color-main);
    text-align: left;
    transition: padding-top .5s $cubic-ease-out, height .5s $cubic-ease-out, opacity .3s $cubic-ease-out;

    &.initial {
      height: 0;
    }

    &.hide {
      overflow: hidden;
      height: 0;
      padding-top: 0 !important;
      opacity: 0;
    }

    &-close {
      pointer-events: all;
    }

    &-inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: var(--padding-s) var(--padding-s) var(--padding-s) var(--padding-m);
      border: var(--border-size) solid var(--color-grey-divider-light);
      border-radius: rem(4);
      background: var(--color-grey-light);
    }

    &.error {
      color: var(--color-red);
    }

    &.cart-toaster {
      width: calc(grid(6) + 5px);

      .toaster__message-inner {
        overflow:hidden;
        justify-content: flex-start;
        padding: 0;
        padding-right: var(--padding-s);
        gap: rem(20);

        .cart-message__inner {
          display: flex;
          width: 70%;
          gap: rem(16);

           & > div {
            @include on-line-text-no-wrap;
          }

          .button {
            pointer-events: all;
            white-space: nowrap;
          }
        }
      }

      .toaster__message-close {
        position: absolute;
        top: 50%;
        right: var(--padding-s);
        width: max-content;
        justify-self: flex-end;
        transform: translateY(-50%);
      }

      img, video {
        width: auto;
        height: rem(60);
      }
    }
  }
}

@include tablet-portrait {
  .toaster {
  bottom: var(--padding-m);
  left: calc(grid(0,1) - 2.5px);

    &__message {
      width: grid(6);

      &.cart-toaster {
        .toaster__message-inner {
          .cart-message__inner  {
            flex-direction: column;
            gap: var(--gap-xxxs);
          }

        }

        .cart-product__name {
          display: none;
        }
      }
    }

  }
}
