.size-selector {
  height: var(--vh);
  background-color: transparent;

  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: var(--color-background);
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--padding-m);

    &-tabs {
      display: flex;

      button {
        display: flex;
        width: 50%;
        justify-content: space-between;
        padding: var(--padding-m);
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);
        @include style(typo-base-uppercase);



        &.active {
          border-bottom: var(--border-size) solid var(--color-main);
        }
      }
    }
  }

  &__tabs {
    display: flex;
    overflow: hidden;

    &[data-active="0"] {
      .size-selector__tab {
          transform: translateX(0);
      }
    }

    &[data-active="1"] {
      .size-selector__tab {
          transform: translateX(-100%);
      }

    }
  }

  &__tab {
    display: flex;
    overflow: hidden auto;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    padding-top: rem(40);
    transition: transform 0.3s ease-in-out;

    .forced & {
      transition: none;
    }
    @include custom-scrollbar;
  }


  &__title {
    @extend .panel__header;

    &-bundle {
      display: flex;
      align-items: center;
      gap: grid(0, 0.5);

      button {
        margin-left: calc(grid(0, 0.5) * -1);
      }
    }
  }

  &__inner {
    display: flex;
    width: 100%;
    height: calc(100% - var(--size-confirm-height) - var(--size-header-height));
    flex-direction: column;
    overflow-y: scroll;
    @include custom-scrollbar;
  }

  &__confirm {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--padding-m);
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    background-color: var(--color-background);

    button {
      :not(.is_bundle_product).not-valid & {
        color: var(--color-grey-medium);

        // pointer-events: none;
        cursor: not-allowed;
      }
    }


  }

  &__link {
    @include style('typo-base-uppercase-link');
  }

  &__infos {
    padding: 0 var(--padding-m) var(--padding-xl) var(--padding-m);
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);


    &-size {
      @include style('typo-base-body');
      padding-bottom: var(--padding-m);
    }

    &-buttons {
      display: flex;

      & > *:first-child {
        margin-right: auto;
      }
    }
  }

  &__list {
    .size-selector__size {
      position: relative;
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);

      }

      &-radio {
        position: absolute;
        opacity: 0;
        pointer-events: none;

        &:checked ~ {
          .icon {
            opacity: 1;
          }

          label .size-selector__size-one-item-left {
            opacity: 0;
          }

          .size-selector__size-label-wrapper {
            background-color: var(--color-grey-light);
          }
        }
      }

      &-label-wrapper, &-oos-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: var(--padding-l) var(--padding-m);
        cursor: pointer;
        transition: background var(--opacity-duration) ease-in-out;
      }

      &-label-wrapper {
        @include hover {
          &:hover {
            background-color: var(--color-grey-lighter);
          }
        }

      }

      &-available, &-oos--title, &-one-item-left {
        @include style('typo-base-uppercase');
      }

      &-oos--title, &-one-item-left {
        color: var(--color-grey-medium);
      }

      &-one-item-left {
        opacity: 1;

        // transition: opacity 0.3s ease;
      }

      .icon {
        position: absolute;
        top: 50%;
        right: var(--padding-m);
        opacity: 0;
        transform: translateY(-50%);

        // transition: opacity 0.3s ease;
      }

      &.selected .icon {
        opacity: 1;
      }

      &.selected .size-selector__size-one-item-left {
        opacity: 0;
      }
    }
  }
}


@include tablet-portrait {
  .size-selector {
    &__wrapper {
      height: calc(0.9 * var(--vh));
      margin-top:calc(0.1 * var(--vh));
    }

    &__title {
      // padding-bottom: var(--padding-);
    }

    &__header {
      gap: 0;
    }

    &__infos {
      &-size {
        padding-bottom: rem(8);
      }
    }

    &__inner {
      width: 100%;
      height: 100%;
      align-items: flex-start;

      // justify-content: flex-start;
      justify-content: space-between;
      gap: var(--padding-l);
    }

    &__list {
      width: 100%;
    }

    &__confirm {
      position: sticky;
      bottom: 0;
    }

    // &__list {
    //   .size-selector__size {
    //     &-label-wrapper, &-oos-wrapper {
    //       padding: var(--padding-m) grid(0, 1);
    //     }
    //   }
    // }
  }
}
