.panel {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-s) var(--padding-s) var(--gap-xl) var(--padding-m);
    @include style(typo-base-uppercase);


    .scrollable & {
      position: sticky;
      z-index: 10;
      top: 0;
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
      background-color: var(--color-background);
    }
  }

  &__wrapper {
    position: fixed;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    width: grid(5, 1);
    background: var(--background);
    transform: translateX(100%);
  }

  &.loading & {
    &__inner {
      cursor: wait !important;
      opacity: .5;
      transition-timing-function: $quad-ease-out;

      * {
        pointer-events: none;
      }
    }
  }

  &__inner {
    position: relative;
    overflow: hidden scroll;
    width: 100%;
    height: 100%;

    // transform: translateZ(0);
    transition: opacity .5s $quad-ease-in;
    @include hide-scrollbar;
    @include overlay-children;

    --no-prevent-scroll: 1;
  }

  &__wrapper, &__overlay {
    visibility: hidden;
  }

  .app-started & {
    &__wrapper, &__overlay {
      // @include transition ('transform, opacity, visibility',.4s, $cubic-ease-out );
    }
  }



  &__close {
    position: absolute;
    z-index: index('overlay-4');
    top: 0;
    right: 0;
    display: block;
    padding: oldGutters(1);
  }

  &__overlay {
    @include fullscreen(fixed);
    z-index: index('overlay-3') - 1;
    background: $black;
    opacity: 0;
    pointer-events: none;
  }
}

@include tablet-portrait {
  .panel {
    &__header {
      padding-bottom: var(--padding-m);
    }

    &__wrapper {
      width: 100%;
      border: none;
    }

    &__inner {
      transform: none;
    }

    &__close {
      position: fixed;
      top: 0;
      height: rem($header-height-m);
      padding: 0 oldGuttersM(1);
    }

    &__overlay {
      display: none;
    }
  }
}
