@use 'sass:math';

.article {
  .page-inner {
    width: 100%;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: var(--padding-mega) 0;
    gap: var(--padding-mega);
  }

  &__title + .article-text {
    margin-top: #{rem(-60)}
  }

  &__controls {
    position: sticky;
    bottom: 0;
    width: 100%;
  }

  &-prev {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: oldGutters(1) 0;
  }


  @include tablet-portrait {
    --margin: #{rem(40)};

    &__inner {
      width: 100%;
      gap: rem(90);
    }

    &__title {
      --margin: #{rem(20)};
      margin-top: rem(40);

      & + .article-text {
        margin-top: 0;
      }
    }

    &__controls {
      display: none;
    }
  }
}
