.page-tabs {
  &__router {
    overflow: visible;
  }

  &__wrapper {
    background-color: var(--background);
  }

  &__inner {
    display: flex;
    min-height: var(--available-height);
    flex-direction: column;
  }

  .internal-page {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  &__page {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: space-between;
  }

  &__content {
    position: relative;
    width: 100%;

    &-left {
      display: flex;
      width: grid(6);

      // padding: rem(80) 0 rem(80) var(--grid-gutter);
      flex-direction: column;
      justify-content: flex-start;

      &.fullwidth {
        width: 100%;
        padding: 0 var(--grid-gutter);

        .wishlist &, .alerts & {
          padding: 0;

        }
      }
    }

    &-right {
      position: sticky;
      top: 0;
      display: flex;
      width: grid(6);
      min-height: 100%;
      justify-content: flex-end;

      .page-tabs__right {
        position: sticky;
        top: calc(var(--page-tabs-header-height,0) + var(--header-height));
        height: min-content;
        transition: top var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

        .scroll-down & {
          top: calc(var(--page-tabs-header-height,0) + var(--header-height-scrolled));
        }
      }
    }
  }

  &__left {
    width: 100%;
    padding: rem(80) 0;

    &-category-wrapper {
      display: none;

      &.active {
        display: block;
      }
    }

    &-title {
      @extend %typo-display-uppercase;
      padding: var(--padding-m) 0;
      border-bottom: 1px solid $border-color;
      margin-top: rem(80);

      &:first-of-type {
        margin-top: 0;
      }
    }

    .accordion__header {
      padding: 0;
    }

    .accordion__inner {
      padding: 0 0 var(--padding-m) 0;
      @extend %typo-base-body;
      color: var(--color-grey-medium);

      a {
        text-decoration: underline;
      }
    }
  }

  &__right {
    display: flex;
    width: 100%;
    max-width: grid(5, 1);
    flex-direction: column;
    padding: rem(80) grid(0, 1) rem(80) 0;
    gap: rem(40);
  }

  &__select {
    display: none;
  }

  &__menu {
    position: sticky;
    z-index: 100;
    bottom: 0;
    height: 0;

    &-inner {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: oldGutters(1);
    }

    .appearing & {
      position: fixed;
    }
  }

  &__link {
    color: var(--color-grey-medium);
    transition: color .15s linear;

    .loading & {
      pointer-events: all !important;
    }

    // &.current, .desktop &:hover {
    //   color: var(--color);
    // }

    & + & {
      margin-top: remMin(12);
    }
  }

  & &__button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: oldGutters(.5);
    display: flex;
    align-items: center;
    color: var(--color-grey-medium);
  }

  &__header {
    position: sticky;
    z-index: 10;
    top: var(--header-height);
    display: flex;
    flex-direction: column;
    padding: rem(28) grid(0, 1) 0;
    border-bottom: rem(0.5) solid $border-color;
    background-color: var(--color-background);
    gap: grid(0, 1);
    text-align: left;
    transition: top var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

    &-track {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: .5px;
      background-color: var(--color-main);
      transition: left .25s ease-in-out, width .25s ease-in-out;
    }

    &-links {
      display: flex;
      gap: rem(16);
    }

    &-link {
      padding: rem(10) 0;
      @extend %typo-base-uppercase;
      color: var(--color-grey-medium);
      transition: color var(--opacity-duration) ease-in-out;

      &.active {
        color: var(--color-main);
      }

      @include hover {
        &:hover {
          color: var(--color-main);
        }
      }

      &.logout {
        position: absolute;
        right: grid(0, 1);
        bottom: 0;
        color: var(--color-main)
      }
    }
  }


  &__title {
    @extend %typo-display-uppercase;
  }
}

.scroll-down {
  .page-tabs__header {
    top: var(--header-height-scrolled);
  }
}

.frequently-mobile {
  display: none;
  padding: 0 var(--padding-m);
  margin-bottom: rem(60);
}

.frequently-desktop {
  display: block;
}

@include tablet-portrait {
  .page-tabs {
    &__wrapper {
      margin: 0;
    }

    &__header {
      transition: gap var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

      .scroll-down & {
        gap: 0;
      }
    }

    &__page {
      flex-direction: column;
    }

    &__title {
      padding-left: grid(0, 1);
    }

    .internal-page {
      width: 100%;
      flex-direction: column;
    }

    &__content {
      position: relative;
      width: 100%;

      &-left {
        display: flex;
        width: 100%;
        justify-content: center;
        padding-bottom: rem(60);
        padding-left: 0;
      }

      &-right {
        position: relative;
        display: flex;
        width: 100%;
        min-height: 0;
        justify-content: center;
      }
    }

    &__left, &__right {
      width: 100%;
      padding: rem(60) 0;
    }

    &__right {
      max-width: 100%;
      padding: 0 var(--padding-m) rem(60) var(--padding-m);
    }

    &__left {
      padding: rem(60) 0 0;

      &-title {
        padding: var(--padding-m);
        border-bottom: 1px solid $border-color;
        margin-top: rem(60);

        &:first-of-type {
          margin-top: 0;
        }
      }

      .accordion__header {
        padding: 0 var(--padding-s) 0 var(--padding-m);
      }

      .accordion__inner {
        padding: 0 var(--padding-m) rem(40) var(--padding-m);
        @extend %typo-base-body;
        color: var(--color-grey-medium);

        a {
          text-decoration: underline;
        }
      }
    }

    &__select {
      position: relative;
      z-index: 9;
      display: flex;
      width: 100%;
      height: oldGuttersM(4);
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      background-color: var(--background);

      &::after {
        display: block;
        width: rem($icon-size);
        height: rem($icon-size);
        background-image: url("../svg/down.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        pointer-events: none;
      }

      &-tag {
        @include fullscreen;
        opacity: 0;
      }
    }

    &__menu {
      display: none;
    }

    & &__button {
      display: none;
    }

    &__header {
      padding: var(--padding-m) 0 0 0;
      border-bottom: rem(0.5) solid $border-color;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: grid(0, 1);
        height: 100%;
        background: linear-gradient(to right, rgb(255 255 255 / 50%), var(--color-background));
        content: '';
        pointer-events: none;
      }

      &::before {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        display: block;
        width: grid(0, 1);
        height: 100%;
        background: linear-gradient(to left, rgb(255 255 255 / 50%), var(--color-background));
        content: '';
        pointer-events: none;
      }

      &-track {
        display: none;
      }

      &-links {
        overflow-x: scroll;
        @include hide-scrollbar;

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: grid(1);
          height: 100%;
          background: linear-gradient(to right, rgb(255 255 255 / 50%), var(--color-background));
          content: '';
          pointer-events: none;
        }
      }

      &-link {
        padding: var(--padding-m) 0;
        white-space: nowrap;

        &.active {
          border-bottom: none;
          color: var(--color-black);
        }

        &:first-of-type {
          margin-left: var(--padding-m);
        }

        &:last-of-type {
          margin-right: rem(60);
        }
      }
    }
  }

  .frequently-mobile {
    display: block;
  }

  .frequently-desktop {
    display: none;
  }
}
