.block-products {
  position: relative;
  width: 100%;

  .article-text {
    padding-bottom: var(--padding-m);
  }

  &__title {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: rem(40);
  }

  &__list {
    @extend .category__list;
    display: flex !important;
    width: 100%;
    min-height: 0;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;


    &--slider {
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll !important;
      scroll-snap-type: x mandatory !important;
      @include hide-scrollbar;
    }
  }

  &__navigation {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    padding: 0;
    gap: var(--gap-s);
    pointer-events: none;

    button {
      height: 100%;
      padding: 0 calc(var(--padding-s));
      border: none;
      cursor: pointer;
      pointer-events: all;

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  &__track {
    display: none;
    height: rem(2);
    margin: rem(20);
    background-color: var(--color-grey-divider-light);

    &-inner {
      width: var(--track-width);
      height: 100%;
      border: none;
      background-color: var(--color-main);
    }
  }

  &__product {
    @extend .category__product;
    width: calc(100% / 3);
    scroll-snap-align: start;


    .card & {
      .product-card__tag,
      .product-card__toggle-wishlist,
      .product-card__navigation,
      .product-card__infos{
        display: none;
      }

      .product-card__images {
        overflow: hidden;
      }
    }

    .pdp & {
      width: calc(100% / 4);
    }

    .article &, .block-products.list & {
      width: calc(100% / 4);
    }

    .related & {
      width: calc(100% / 6);
    }

    .block-products__list--slider & {
      width: calc(var(--vw) / 3);
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
}

@include tablet-portrait {
  .block-products {
    &__title {
      margin: 0;
      margin-bottom: rem(60);
    }

    &__navigation {
      display: none;
    }

    &__list {
      align-items: flex-start;

      // flex-wrap: wrap;
      justify-content: flex-start;


      .card & {
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        @include hide-scrollbar;
      }

      .block-products.home & {
        flex-wrap: nowrap;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        @include hide-scrollbar;
      }
    }

    &__track {
      .card & {
        display: block;
      }

      .block-products.home & {
        display: block;
      }
    }

    &__product {
      .pdp & {
        min-width: calc(100% / 2);

        .product-card__navigation {
          display: none;
        }
      }

      .block-products.list & {
        width: calc(100% / 2);
      }

      .block-products.related & {
        width: calc(100% / 3);
      }

      .block-products__list--slider &, .block-products.home & {
        min-width: calc(100% / 1.5);
      }
    }
  }
}
