.checkout-breadcrumb {
  position: sticky;
  z-index: 21;
  top: 0;
  display: flex;
  width: grid(7, 2);
  padding: rem(80) var(--padding-m) 0 grid(1, 2);
  border-bottom: rem(.5) solid var(--color-grey-divider-light);
  margin-bottom: rem(80);
  background-color: var(--color-background);
  @extend %typo-base-uppercase;

  &__inner {
    display: flex;
    width: max-content;
    gap: rem(14);
  }

  &__step {
    position: relative;
    display: block;
    flex-grow: 0;
    padding: var(--padding-s);
    color: var(--color-grey-medium);
    line-height: 16.8px !important;
    transition: color .3s linear;
    user-select: none;
    white-space: nowrap;

    &.disabled {
      color: var(--color-grey-medium);
      pointer-events: none;
    }

    &.current {
      color: $dark;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(.5);
        background: $dark;
        content: '';
      }
    }
  }
}

@include tablet-portrait {
  .checkout-breadcrumb {
    width: var(--vw);
    padding-top: rem(60);
    padding-right: 0;
    padding-left: 0;
    margin-bottom: rem(60);
    gap: rem(16);
    overflow-x: scroll;
    @include hide-scrollbar;

    &__inner {
    padding:0 var(--padding-m);

    }

  // place-content: center center;



    &::after {
      top: -(oldGuttersM(1) + rem($header-height-m));
    }

    &__step {
      width: max-content;
      flex-grow: 0;
      flex-shrink: 0;

    }

  }
}
@media only screen and (max-width: 360px) {
  .checkout-breadcrumb {
    align-items: flex-start;
    justify-content: flex-start;
  }

}
