.wishlist-product {
  &__img {
    img, video {
      width: 100%;
    }
  }

  &.oos &__img {
    opacity: .4;
  }

  &__top, &__bottom {
    display: flex;
  }

  &__top {
    justify-content: space-between;
    margin: rem(22) 0 rem(12);
  }

  &__price {
    white-space: nowrap;
  }

  &__color {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bottom {
    --color: var(--color-grey-medium);
    margin-bottom: rem(30);
    color: var(--color-grey-medium);

    & > * {
      position: relative;
      white-space: nowrap;

      &:not(:first-child) {
        margin-left: oldGutters(.5);
      }
    }

    .space {
      margin: 0 !important;
    }
  }

  &__remove {
  }
}
