.footer {
	&__icon {
		width: rem(32);
		height: rem(32);
		margin-right: var(--padding-s);
	}

	&__reinsurance {
		display: flex;
		padding: grid(0, 1);
		gap: grid(0, 1);


		&-tab {
			width: grid(4);
			padding: var(--padding-s);
      padding-right: var(--padding-m);
			padding-bottom: var(--padding-m);
			border: var(--border-size) solid var(--color-grey-divider-light);

			&.contact {
				padding-bottom: var(--padding-m);
			}
		}

		&-tabHeader {
			display: flex;
			align-items: center;
			@extend %typo-display-uppercase-s;
		}

		&-tabContent {
			display: flex;
			width: calc(grid(3) - var(--padding-s));
			flex-direction: column;
			padding-left: var(--padding-s);
			margin: 0;
			margin-top: var(--padding-xl);
			color: var(--color-grey-medium);
			@extend %typo-base-body;


      a {
        transition: color var(--opacity-duration) ease-in-out;
        @include hover {
          &:hover {
            color: var(--color-main);
          }
        }
      }
		}

		&-content {
			&:not(:last-child, div) {
				margin-bottom: var(--padding-s);
			}
		}

    .idz-online {
      margin-bottom: var(--padding-s);
      transition: color var(--opacity-duration) ease-in-out;

      @include hover {
        &:hover {
          color: var(--color-main);
        }
      }
    }


	}

	&__center {
		display: flex;
		padding: var(--gap-xxl) grid(0, 1);
		padding-bottom: rem(220);
		gap: grid(0, 1);
	}

	&__newsletter {
		width: grid(6);

    .footer__form {
			position: relative;
			width: grid(4);

      &-submit {
				@extend %typo-base-uppercase;
				position: absolute;
				top: var(--padding-m);
				right: var(--padding-m);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;

        &.visible {
          opacity: 1;
          pointer-events: all
        }
			}


		}

		&-title {
			@extend %typo-display-uppercase;
			margin-bottom: var(--padding-s);
		}

		&-description {
			margin: 0;
			margin-bottom: var(--padding-xl);
			@extend %typo-base-body;
			color: var(--color-grey-medium);
		}
	}



	&__utilities {
		display: flex;
		justify-content: space-between;
		padding: grid(0, 1);
		border-top: 1px solid var(--color-grey-divider-light);
		color: var(--color-grey-medium);
		@extend %typo-base-uppercase;
	}
}

@include tablet-portrait {
	.footer {
		&__reinsurance {
			width: 100%;
			flex-direction: column;

			&-tab {
				width: 100%;
        min-height: rem(169);
			}


			&-tabContent {
				width: 100%;
        margin-top: var(--padding-l);
			}
		}

    &__center {
      flex-direction: column;
			align-items: center;
			padding: var(--padding-xxl) 0 0 0;
      gap: rem(2); // --error-height + 4px gap
		}

		&__newsletter {
      &-title {
        margin-bottom: var(--padding-m);
      }

      &-description {
        margin-bottom: var(--padding-xxl);
      }

			.footer__form {
				width: 100%;


				&-submit {
					display: block;
          padding-bottom: rem(1);
          opacity: 1;
          pointer-events: all;
          }

          &.not-valid {
            .footer__form-submit {
              color: var(--color-grey-medium);
            }
          }
			}
		}

    &__utilities {
      border-top: none;
    }

	}
}
