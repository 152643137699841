.product {
  &.page {
    margin-top: calc(-1 * var(--header-height));
  }

  &__legal {
    display: none;
    padding: var(--padding-m);
    padding-top: 0;
    @include style(typo-footnote);
    color: var(--color-grey-medium);
  }

  &__top {
    position: relative;
    display: flex;
    align-items: stretch;
    padding-bottom: rem(160);

    .page:not(.appearing) &.alignBottom {
      // align-items: flex-end;
    }
  }

  &__bis {
    display: none;
    align-items: center;
    @include style(typo-base-uppercase);
    text-align: right;
  }

  &__bottom {
    .appearing & {
      display: none;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: var(--padding-m);
  }

  .seo-links {
    padding-bottom: 0;
  }
}

@include tablet-portrait {
  .product {
    &__legal {
    display: block;
    }

    &__top {
      position: relative;
      flex-direction: column;
      align-items: flex-start !important;
      padding-bottom: rem(0);
      margin-bottom: rem(60);
      margin-left: 0;
    }

    &__bis {
      display: flex;
      margin: var(--padding-m);
      margin-left: var(--padding-s);
    }

    &-footer {
      margin-top: oldGuttersM(4);
    }

    &__bottom {
      padding-top: var(--padding-m);
    }
  }
}
