
.address-form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: rem(16);

  &__states {
    display: none;
  }

  &.without-zipcode & {
    &__zipcode {
      display: none;
    }
  }


  &.with-states & {
    &__states {
      display: block;
    }
  }

  .field.with-suggestions {
  position: relative;

  }

  .field__suggestions {
    position: absolute;
    z-index: 20;
    display: none;
    width: 100%;
    border: rem(.5) solid var(--color-grey-divider-light);
    margin-top: rem(-8);
    margin-bottom: rem(14);
    box-shadow: 0 10px 20px 0 rgb(0 0 0 / 3%);

   ;
    & button {
      @extend %typo-base-uppercase;
      width: 100%;
      padding-top: var(--padding-m);
      padding-bottom: var(--padding-m);
      background-color: var(--color-background);
      text-align: left;
      transition: background-color .3s ease-in-out;

      @include hover {
        &:hover {
          background-color: var(--color-grey-light);
        }
      }

      h5 {
        padding:0 var(--padding-m);

        span {
          color: var(--color-main);
        }
      }
    }

    &.opened {
      display: block;
    }
  }

  .form__split-third {
  }

  .form__split {
  }

  .field__suggestion:not(:last-of-type) {
    border-bottom: 0.5px solid var(--color-grey-divider-light);
  }

  @include tablet-portrait {
    gap: var(--padding-m);
  }
}
