@use 'sass:math';

.spacer {
  flex-grow: 1;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

body {
  .grey-text {
    color: var(--color-grey-medium) !important;
  }

  .dark-text {
    color: $dark !important;
  }

  .red-text {
    color: $red !important;
  }

  .blue-text {
    color: $blue !important;
  }

  .green-text {
    color: $green !important;
  }
}

.center {
  text-align: center !important;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.empty-message {
  min-height: 50vh;
  padding-top: oldGutters(1);
  color: var(--color-grey-medium);
  text-align: center;

  & + .footer {
    margin-top: 0;
  }
}

.wysiwyg-block {
  @include wysiwyg;
}

.iframe-block {
  position: relative;
  width: oldGrid(6);
  margin: oldGutters(2) auto;

  iframe, video {
    @include fullscreen;
    border: none;
  }

  &::after {
    display: block;
    padding-top: math.div(9, 16) * 100%;
    content: '';
  }

  @include tablet-portrait {
    width: 100%;
    margin: oldGuttersM(2) auto;
  }
}
