.home {
  &.page {
    padding: 0;

    // margin-top: calc(var(--header-height) * -1);
  }

  &__blocks {
    position: relative;

    & > * {
      // scroll-snap-align: start;
      // scroll-snap-stop: normal;

      // &:not(:last-child) {
      //   border-bottom: 1px solid $border-color;
      // }
    }
  }

}

@include tablet-portrait {
  .home {
    &.page {
      // margin-top: -1 * (rem($header-height-m) + rem($notification-height-m));
    }

  }
}
