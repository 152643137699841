@mixin switch-button-hover {
  .switch-button__inner {
    transform: translateY(rem(8));
    // transform: rotateX(-30deg);
    opacity: 0;
  }

  &::after {
    transform: none;
    opacity: 1;
    user-select: none;
  }
}

.switch-button {
  position: relative;
  display: block;

  &__inner, &::after {
    // transform-origin: center  center rem(-10);
    transition: opacity .3s $cubic-ease-in-out, transform .3s $cubic-ease-in-out;
    display: block;
    pointer-events: none;
  }

  @include hover {
    &:hover {
      @include switch-button-hover;
    }
  }

  &.current {
    @include switch-button-hover;
  }

  &__inner {
    opacity: 1;
    transform: none;
    // transform: translateY(0%);
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    // transform: rotateX(30deg);
    transform: translateY(rem(-8));
    opacity: 0;
    color: var(--color);
    content: attr(data-hover);
  }
}
