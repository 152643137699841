.webstore-switcher {
  // display: none;
  pointer-events: none;


  &.drawer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: transparent;
  }

  &__header {
    @extend .panel__header;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: var(--padding-m);
    gap: var(--padding-xl);

    p {
      @include style(typo-base-body);
    }

  }


  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: var(--color-background);
    pointer-events: none;

    .opened & {
    pointer-events: all;
    }
  }

  form {
    display: flex;flex-direction: column;
    gap: var(--padding-xl);

    .opened & {
      pointer-events: all;
      }
  }

  &__buttons {
    display: flex;
  }

  @include tablet-portrait {
    &__wrapper {
    height: unset;
  }
}
}
