@use 'sass:math';

.addresses {
  display: flex;
  flex-wrap: wrap;
  gap: grid(0, 1);

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: var(--gap-xxl) 0;
    gap: var(--grid-gutter);
  }

  .account-item {
    max-width: grid(4);
    flex-shrink: 0;
  }

  &__add {
    display: flex;
    width: grid(4);
    min-height: rem(201);
    transition: background var(--opacity-duration) ease-in-out;

    &:hover {
      background-color: #FCFCFC;
    }

    .account-item {
      height: 100%;
      border-color: var(--color-main);
      aspect-ratio: #{math.div(453,200)};
    }

    .account-item__header {
      border-bottom: none;
    }
  }

  &.internal-page {
  }
}

@include tablet-portrait {
  .addresses {
    flex-direction: column;

    &__list {
      padding: var(--gap-xl) 0;
    }

    .account-item {
      width: 100%;
      max-width: grid(6);

      .address-label {
        p {
          font-size: rem(16);
        }
      }
    }

    &__add {
      width: grid(6);
      min-height: rem(201);
    }

    &.internal-page {
      width: 100%;

      .form {
        width: 100%;
      }
    }

    .recap {
      &__content {
        max-width: rem(180);
      }
    }
  }
}
