.product-related {
  margin-bottom: rem(40);

  &__title {
    padding-left: var(--padding-m);
    margin-bottom: var(--padding-m);
    @include style(typo-display-uppercase);
  }

  &__list {
    display: grid;
    min-height: 0;
    grid-template-columns: repeat(6, 1fr);
    @extend .category__list;
  }

  &__product {
    @extend .category__product;
    margin-bottom: 0;
  }
}

@include small {
  .product-related {
    &__list {
      grid-template-columns: repeat(4, 1fr);
    }
  }

}

@include tablet-portrait {
  .product-related {
    width: 100%;
    margin-bottom: rem(40);

    // margin-right: oldGuttersM(-1);
    // margin-left: oldGuttersM(-1);
    // overflow: scroll hidden;

    &__list {
      padding: 0;
      margin-right: 0;
      margin-left: 0;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
