.returns__item {
  display: flex;
  flex-direction: column;
  padding: var(--padding-m);
  border: var(--border-size) solid var(--color-grey-divider-light);
  gap: var(--padding-m);

  &-sku {
    @include style(typo-footnote);
    color: var(--color-grey-medium);
    text-transform: uppercase;

    &::before {
      position: relative;
      content: 'REF. '
    }
  }

  &-card {
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);
  }

  &-actions {
    display: flex;
    flex-direction: column;
    gap: var(--padding-m);



    &-title {
      @include style(typo-base-body);
    }


  }

  &-choices {
    display: flex;

    .checkbox {
      width: 100%;
    }
  }

  &-reasons {
    display: none;

    [data-choice] & {
      display: flex;
    }

    .native-select {
      width: 100%;
    }
  }

  &-reason-exchange {
    display: none;
    width: 100%;


    [data-choice="exchange"] & {
      display: flex;
    }
  }

  &-reason-return {
    display: none;
    width: 100%;

    [data-choice="return"] & {
      display: flex;
    }
  }

  &-row {
    display: flex;
    flex-direction: column;
    gap: var(--padding-m);
  }

  &-variants {
    .field__hidden {
      margin-top: calc(var(--padding-s) * -1);

      &:not(.error) {
        display: none;
      }

    }

    &.error {
      h3 {
        color: var(--color-red);
      }
    }

    [data-reason][data-choice="exchange"] & {
      display: flex;
    }
    display: none;
    flex-direction: column;
    gap: var(--padding-m);

    h3 {
      @include style(typo-base-uppercase);
    }

    .variants {
      display: flex;
      flex-direction: row-reverse !important;
      gap: 0 !important;
    }

    .variants__variant-item {
      &:last-of-type:not(:first-of-type) {
        border-right: var(--border-size) solid var(--color-grey-divider-light) !important;
      }

      &:first-of-type:not(:last-of-type) {
        padding-left: var(--padding-s) !important;
      }
    }
  }

  &-summary {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      gap: var(--padding-s);
      @include style(typo-base-uppercase);

      button {
        color: var(--color-grey-medium)
      }
    }

    &-right {
      a {
        color: var(--color-grey-medium)
      }
    }

    &-variants {
      display: flex;
      flex-wrap: wrap;
      color: var(--color-grey-medium);
      gap: rem(2) var(--padding-s);
    }

    &-color {
      display: inline-flex;
      align-items: center;
      color: var(--color-grey-medium);
      gap: var(--padding-s);

      span {
      @include ellipsis(1, 120%)

      }


      &-swatch {
        @include style(color-swatch);
      }
    }
  }

  @include tablet-portrait {
    &-choices {
      flex-direction: column;
      gap: rem(8);
    }

    &-variants {
      h3 {
        font-size: rem(14);
      }
    }

    &-summary {
      gap: var(--padding-m);

      &-left {
        &>span {
          text-wrap: nowrap;
        }
      }

      &-variants {
        flex-direction: column-reverse;
      }

      &-color {
        span {
          max-width: 80%;
        }
      }
    }
  }

}
