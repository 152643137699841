.fairlymade {
  .page__inner {
    display: flex;
  height: var(--vh);
  flex-direction: column;
  }

  .panel__header {
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);
  }

  .product-fairlymade {
    width: 100%;
    height: 100%;
    border: none;
  }

}
