.text-cta {
  position: relative;
  padding: var(--padding-m);

  &__solid {
    &.text-cta__grey {
      background-color: var(--color-grey-light);
    }

    &.text-cta__light {
      background-color: var(--color-background);
    }
  }

  &__outline {
    border: var(--border-size) solid;
    background-color: transparent;

    &.text-cta__grey {
      border-color: var(--color-grey-divider-light);
      color: var(--color-main);
    }

    &.text-cta__light {
      border-color: var(--color-background);
      color: var(--color-background);
    }
  }

  &__title {
    @extend %typo-base-uppercase;
    margin: 0 0 var(--padding-m) 0;
  }

  &__text {
    @extend %typo-base-body;

    a {
      text-decoration: underline;
     }
  }

  &__list {
    @extend %typo-base-body;
    display: flex;
    flex-direction: column;
    gap: rem(6);

    a {
      margin-bottom: 0 !important;
      transition: color var(--opacity-duration) ease-in-out;
      word-break: unset !important;
      @include hover {
        &:hover {
          color: var(--color-grey-medium);
        }
      }
    }
  }

  .button {
    margin-top: rem(20);
  }

  @include tablet-portrait {
    &__text {
      font-size: rem(16);
      gap: rem(8);
    }
  }
}
