.player {
  color: $white;

  // width: 100%;

  &__wrapper {
    position: relative;
    overflow: hidden;
  }

  &-play, &-fullscreen {
    width: rem(32);
    height: rem(32);
  }

  &-play {
    .icon__play {
      display: block;
    }

    .icon__pause {
      display: none;
    }
  }

  &.playing &-play {
    .icon__play {
      display: none;
    }

    .icon__pause {
      display: block;
    }
  }

  &-video {
    width: 100%;
    object-fit: contain;
  }

  &__controls {
    position: absolute;
    bottom: 0;
    padding: var(--padding-s);
    width: 100%;
    z-index: 100;
    display: flex;
    gap: var(--padding-m);
  }

  &-timeline {
    width: calc(100% - 1px);
    height: rem(2);
    background: rgb(255 255 255 / 20%);

    &__wrapper {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      flex-grow: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-progress {
    height: 100%;
    opacity: 1;
    background-color: $white;
    width: 100%;
    position: relative;
    transform: translateX(-50%);
  }

  &-mute {
    @include style(typo-base-uppercase);
    margin-right: calc(-1 * (grid(0, 1) * .5));
  }

  &__description {
    @include style(typo-base-uppercase);
    color: var(--color-main);
    margin-top: var(--padding-s);
  }

  @include tablet-portrait {
    &__controls {
      gap: var(--padding-s);
    }

    &__timeline {
    }


    &-mute {
      display: none;
    }
  }
}
