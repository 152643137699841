.checkout-summary {
  width: 100%;
  min-height: var(--vh);
  padding: var(--padding-m);
  background: var(--color-grey-light);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-m);
    border-bottom: rem(.5) solid var(--color-grey-divider-light);
    background: var(--background);

    &-title {
      @extend %typo-base-body;
    }

    &-quantity {
      @extend %typo-base-uppercase;
      color: var(--color-grey-medium);
    }
  }

  &__button {
    display: none;
  }

  &__wrapper {
    display: block;
    transform: translateY(0);
  }

  &__inner-wrapper {
    border: rem(.5) solid var(--color-grey-divider-light);
    background: var(--background);
  }

  &__products {
    max-height: rem(300);
    padding: 0 var(--padding-m);
    border-bottom: 1px solid $border-color;
    overflow-y: auto;
    @include custom-scrollbar;
  }

  &__product {
    .row-product-card__checkout .row-product-card__inner {
      justify-content: space-between;

      .row-product-card__bottom {
        margin-bottom: var(--padding-s);
      }
    }

    &:not(:last-child) {
      border-bottom: rem(.5) solid var(--color-grey-divider-light);
    }
  }

  &__promo-totals {
    padding-top: var(--padding-m);

  }

  .checkout-promo {
    margin: 0 var(--padding-m);
    margin-bottom: rem(4);

  //   padding: var(--padding-s) var(--padding-m) rem(16) var(--padding-m);

  //   .field__input {
  //     position: relative;
  //     padding: var(--padding-s) 0 var(--padding-s) 0;
  //     border-radius: 0;
  //     border-bottom: rem(.5) solid var(--color-grey-divider-light);
  //     background-color: var(--color-white);

  //     &:focus {
  //       border-color: var(--color-main);
  //     }
  //   }

  //   .button {
  //     right: 0;
  //   }
  }
}



@include not-tablet-portrait {
  .checkout-summary {
    & &__wrapper {
      overflow: auto;
      height: auto;
    }

    &__header-title {
      & span {
        display: none;
      }
    }

    &__header-close {
      display: none;
    }

    &__overlay {
      display: none;
    }
  }
}

@include tablet-portrait {
  .checkout-summary {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    border-top: 1px solid $border-color;
    background-color: transparent;

    .checkout-footer {
      display: none;
    }

    &__overlay {
      position: fixed;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 0%);
      transition: background .3s $cubic-ease-in-out;

      .checkout__summary.opened & {
        background: rgb(0 0 0 / 80%);
      }
    }

    &__header {
      position: sticky;
      top: 0;
      left: 0;
      padding: var(--padding-s) var(--padding-s) rem(40) var(--padding-m);
      border-bottom: none;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 120%;
      text-transform: uppercase;

      &-title {
        font-size: rem(14);
      }

      &-quantity {
        display: none;
      }

      &-close {
        .icon {
          width: rem(32);
          height: rem(32);
        }
      }
    }

    &__button {
      position: sticky;
      z-index: 1;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: var(--padding-m) var(--padding-s) var(--padding-m) var(--padding-m);
      border-top: rem(.5) solid var(--color-grey-divider-light);
      background: var(--color-white);
      pointer-events: all;

      .checkout__summary.opened & {
        z-index: -1;
      }

      &-content {
        display: flex;
        color: var(--color-grey-medium);
        font-size: rem(14);
        font-weight: bold;
        gap: rem(8);
        letter-spacing: 0;
        line-height: 120%;
        text-transform: uppercase;

        & span:first-child {
        color: var(--color-black);
        }

        & span:last-child {
        color: var(--color-grey-medium);
        }
      }

      .icon {
        width: rem(32);
        height: rem(32);
        transition: transform .2s $cubic-ease-out;

        .checkout__summary.opened & {
          transform: rotate(90deg);
        }
      }
    }

    &__wrapper {
      position: relative;
      z-index: 2;
      display: none;
      overflow: hidden;
      transform: translateY(100vh);
    }



    &__products {
      max-height: none;
      padding: 0 var(--padding-m);
    }


    &__promo-totals {
      position: sticky;
      bottom: 0;
      left: 0;
      background-color: var(--color-background);
    }
  }


}
