.contact {
  position: relative;
  opacity: 1;
  scrollbar-gutter: stable;

  &.page-tabs {
    margin-top: var(--gap-xxl);
  }


  &-blocks {
    display: flex;
    flex-direction: column;
    padding: var(--padding-m);
    gap: var(--padding-m);
  }

  &__wrapper {
    // padding: rem(60) grid(0, 1);
    .panel & {
      margin-bottom: var(--submit-wrapper-height);
    }
  }



  .page-tabs__content-left {
    width: grid(6,2);
  }

  .page-tabs__right {
    top: var(--header-height);
    padding-top: 0;

    .scroll-down & {
    top: var(--header-height);

    }

  }

  &__header {
    @include style(typo-base-uppercase);
    position: sticky;
    z-index: 1;
    top: 0;
    left: 0;
    @extend .panel__header;
  }


  &__title {
    @include style(typo-base-uppercase);

  }

  &__subtitle {
    @include style(typo-base-body);
    padding: 0 grid(0,1);
  }

  .contact__form-submit-wrapper {
    padding: 0 var(--padding-m);

  }


  // .panel & .contact__form-submit-wrapper {
  //   position: fixed;
  //   z-index: 1;
  //   right: 0;
  //   bottom: 0;
  //   width: grid(5,1);
  //   padding: var(--padding-m);
  //   border-top: rem(.5) solid var(--color-grey-divider-light);
  //   background-color: var(--color-background);
  // }

  &__form-inline, &__form-inner, .text-cta {
    padding-right: grid(0, 1);
    padding-left: grid(0, 1);
  }



  &__form {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;

    .page-tabs__content-left & {
      margin-bottom: rem(80);
    }

    .panel & {
      margin-bottom: var(--padding-xl);
    }

    // justify-content: space-between;
    gap: var(--padding-xl);

    & .contact__title {
      padding-right: grid(0, 1);
      padding-left: grid(0, 1);
    }

    &-inner {
      display: flex;
      flex-direction: column;
      gap: rem(16);

      .native-select {
        margin-bottom: rem(16);
      }
    }

    &-inline {
      display: flex;

      .radio {
        color: var(--color-black);
      }
    }

    &-2-col {
      display: grid;
      gap: rem(16);
      grid-template-columns: repeat(2, 1fr);
    }

    textarea {
      height: rem(200);
      padding: var(--padding-m);
      border: 0;
      border-radius: rem(4);
      background: var(--color-grey-light);
      resize: none;
      transition: background var(--opacity-duration) ease-in-out;

      &:focus-visible {
        outline: none;
      }

      &:hover {
        background: #F2F2F2;
      }

      &:focus {
        background: var(--color-grey-light);

        &::placeholder {
          color: var(--color-grey-medium);
          opacity: 0.5;
        }
      }

      & + .field__error {
        display: none;
      }
    }
  }

  @include tablet-portrait {
    .page-tabs__content-left {
      width: grid(6,2);
    }


    &__form {
      gap: var(--padding-l);

      .panel & {
        margin-bottom: var(--padding-xxl);
      }

      .page-tabs__content-left & {
        margin-bottom: 0;
      }
    }

    .panel & .contact__form-submit-wrapper {
      width: 100%;
    }
  }
}
