.order-tracking {
  &__header {
    @extend %typo-base-uppercase;
    @extend .panel__header;
  }

  &__wrapper {
    padding: 0 var(--padding-m);
    @extend %typo-base-body;
  }

  &__text {
    margin-bottom: rem(40);
  }

  & .button {
    margin-top: rem(24);
  }
}
