.infinite-carousel {
  cursor: grab;

  &.drag-disabled {
    cursor: inherit;
  }

  &.is-dragging {
    cursor: grabbing;
  }
}

@include tablet-portrait {
  .infinite-carousel {
    // transform: none !important;
    // overflow-x: scroll;
    // scroll-snap-type: x mandatory;

    // &__clone {
    //   display: none;
    // }

    // img {
    // object-fit: cover;
    // }
  }
}
