.map {
  &__marker {
    padding: rem(10);
    cursor: pointer;



    &::before {
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background: black;
      content: '';
    }

    &--active {
      &::before {
        background: black;
      }
    }

    &--inactive {
      &::before {
        background: var(--color-grey-medium);
      }
    }
  }

  &__popin {
    &-button {
      height: rem(30);
      margin-top: rem(10);
    }
  }

  &__zoom {
    $item-size: rem(32);
    position: absolute;
    z-index: 1000;
    top: calc(100% - #{$item-size} - grid(0, 1));
    right: grid(0, 1);
    display: flex;
    background: white;

    &-in, &-out {
      width: $item-size;
      height: $item-size;
      border: var(--border-size) solid var(--color-grey-divider-light);
    }

    &-in {
      border-right: none;
      background: no-repeat center/8px url("../svg/zoom-in.svg");
    }

    &-out {
      background: no-repeat center/8px url("../svg/zoom-out.svg");
    }
  }
}

.mapboxgl-popup {
  box-sizing: content-box;
}

.mapboxgl-popup-content {
  padding: rem(20) !important;
  font-family: $main_font;

  .stores__item-hours {
    display: none;
  }

  .stores__item-address--details {
    flex-direction: column;
    border:0;
    gap: 0;
    @include style(typo-base-uppercase);
  }


  .mapboxgl-popup-close-button {
    top: rem(10);
    right: rem(10);
    overflow: hidden;
    width: 10px;
    height: 10px;
    border-radius: 0;
    background: no-repeat 100% url("../svg/close.svg");
    text-indent: 999em;
    white-space: nowrap;
  }
}

.mapboxgl-map {
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-bottom {
  &-left {
    display: none;
  }

  &-right {
    display: none;
  }
}

@include tablet-portrait {
  .map {
    &__zoom {
      top: initial;
      bottom: 0;
    }
  }
}
