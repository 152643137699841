@use 'sass:math';

.home-products {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: var(--vw);

  &.has-scroll {
    .home-products__cards {
      // overflow-x: scroll;
      // scroll-snap-type: x mandatory;
      @include hide-scrollbar;

      // &.switching {
        // scroll-snap-type: none;
      // }
    }
  }

  &__inner {
    padding: 0;
    position: relative;
    width: 100%;

    &:not(.native) {
      cursor: grab;
    }

    &.grabbing {
      * {
        cursor: grabbing;
      }
    }

    &.moving {
      * {
        user-select: none;
      }

      img, a {
        pointer-events: none;
      }
    }

    &.native {
      .home-products__cards {
        overflow: scroll hidden;

        // .menu-pushes__push {
        //   &:first-child {
        //     margin-left: var(--grid-gutter);
        //   }
        // }
      }
    }
  }

  .home-product {
    width: calc(var(--vw) / 3);
    flex-shrink: 0;
    scroll-snap-align: start;
  }

  &__cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: rem(2);
    @include hide-scrollbar;
    position: relative;
    overflow: hidden;

    a {
      .grabbing & {
        pointer-events: none;

        * {
          user-select: none;
        }
      }
    }


    // &.grabbing {
    //   cursor: grabbing;
    //   * {
    //     cursor: grabbing;
    //   }
    // }

    // &.moving {
    //   a {
    //     pointer-events: none;
    //   }
    // }
  }

  &__navigation {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    gap: var(--gap-s);
    padding: 0;
    pointer-events: none;

    button {
      border: none;
      cursor: pointer;
      height: 100%;
      padding: 0 calc(var(--padding-s));
      pointer-events: all;

      &.disabled {
        opacity: 0.2;
      }
    }
  }

  &__track {
    display: none;
    height: rem(2);
    margin: rem(20);
    background-color: var(--color-grey-divider-light);

    &-inner {
      width: var(--track-width);
      height: 100%;
      background-color: var(--color-main);
      border: none;
    }
  }
}

@include tablet-portrait {
  .home-products {
    .home-product {
      width: grid(4, 1);
      flex-shrink: 0;
    }

    &__navigation {
      display: none;
    }

    &__track {
      display: block;
    }
  }
}
