@import "/home/lapoz/Work/web/fursac/src/styles/libs/_old_variables.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_variables.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_icons.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_zindex.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_z-index.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_wysiwyg.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_rem.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_mq.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_mixins.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_grid.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_functions.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_fonts.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_font-name.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_easing.scss"; 
@import "/home/lapoz/Work/web/fursac/src/styles/libs/_styling.scss"; 

.adaptable-wrapper {
  height: var(--adaptable-wrapper-height, auto);
  transition: height .6s $quad-ease-out;
}

