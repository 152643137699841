.accordion {
  position: relative;
  border-bottom: var(--border-size) solid var(--color-grey-divider-light);


  &__header, &__checkbox {
    height: rem(72)
  }


  &__wrapper {
    height: 0;
  }

  &__checkbox {
    &:checked ~ .accordion__wrapper {
      height: var(--drawer-height);
    }
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    cursor: pointer;
    opacity: 0;

  &:checked ~ .accordion__header {
    &::after {
    background-image: url("../icons/minus-alt.svg");

    }
  }
  }


  &__wrapper.drawer-panel {
    .js & {
      transition: height .6s $cubic-ease-in-out;
    }
  }


  &__inner {
    max-width: 100%;
    padding: var(--padding-m);
    padding-top: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--padding-s) 0 var(--padding-m);
    @include style(typo-base-body);

    &::after {
      display: block;
      min-width: rem(32);
      height: rem(32);
      background-image: url("../icons/plus-alt.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      pointer-events: none;
      transition: transform .3s $cubic-ease-in-out;
    }
  }
}

@include tablet-portrait {
  .accordion {
    &__header {
      &::after {
      background-image: url("../icons/plus-alt.svg");
      }
    }

    &__wrapper {
      height: 0;
    }

    &__checkbox {
      &:checked ~ .accordion__wrapper {
        height: var(--drawer-height);
      }

    &:checked ~ .accordion__header {
      &::after {
      background-image: url("../icons/minus-alt.svg");
      }
    }
    }


  }

}
