.radio-group {
  padding: 0;

  a[data-navigo] {
    position: relative;
    z-index: 10;
  }

  &__item {
    border: var(--border-size) solid var(--color-grey-divider-light);

    &-inner {
      position: relative;
      display: flex;
      padding: grid(0, 1);



      .radio, .checkbox {
        position: static;
        align-items: center;
        margin-bottom: 0;



        &__label {
          margin-left: rem(10);
          color: $dark;
        }
      }
    }

    &.shipping {
      .radio-group__item {
        &-inner {
          display: flex;
          justify-content: space-between;
        }

        &-wrapper {
          .radio-group__detail {
            width: fit-content;
            margin-top: rem(6);
            margin-left: var(--padding-m);
          }
        }
      }

      .radio-group__tail {
        @include style(typo-base-uppercase);
      }

      .radio-group__content {
        padding-top: 0;

        &-inner {
          padding-top: var(--padding-m);
        }
      }
    }


    .radio__label {
      transition: color var(--opacity-duration) ease-in-out;
    }

    &.new, &.different {
      .radio__box {
        & + .radio__label {
          color: var(--color-grey-medium);
        }
      }

      &.checked  {
        .radio__box {
          & + .radio__label {
            color: var(--color-main);
          }
        }
      }

    }

    & + & {
      margin-top: var(--padding-m);
    }
  }

  &__item.checked &__content {
    height: var(--drawer-height);
  }

  &__content {
    height: 0;

    &.drawer-panel {
      overflow: unset;
      overflow: visible auto !important;

      @include hide-scrollbar;
    }

    &-inner {
      padding: var(--padding-m) grid(0, 1) var(--padding-m);

    }

    // .field, .radio, .select {
    //   margin-bottom: oldGutters(.5);
    // }
  }

  &__detail {
    @include style(typo-base-uppercase);
    margin-left: var(--padding-s);
    color: var(--color-grey-medium);
  }


  &__tail {
    margin-left: rem(6);
  }
}

@include tablet-portrait {
  .radio-group {
    padding: 0;
    margin-bottom: 0;

    &__item {
      &-inner {
        flex-flow: wrap;
        padding: grid(0, 1);
        gap: rem(6);
      }

      .radio {
        // max-width: 80%;

      }

      .radio, .checkbox {
        align-items: flex-start;

        .new & {
          max-width: unset;
        }
      }

      &.shipping {
        .radio-group__item {
          &-wrapper {
            width: grid(4);
          }
        }
      }
    }

    &__content {
      &-inner {
        padding-bottom: oldGutters(.5);
      }

      .field {
        margin-top: 0 !important;
      }
    }

    &__detail {
      max-width: 80%;
      order: 99;
      margin-left: var(--padding-m);
    }

    &__tail {
      margin-left: rem(6);
    }
  }
}
