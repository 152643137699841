@use 'sass:math';

.product-details {
  position: sticky;
  top: 0;
  display: flex;
  overflow: hidden;
  width: grid(6,1.5);
  height: min(auto,calc(var(--vh) - var(--notification-height)));
  min-height: min-content;
  max-height: calc(var(--vh) - var(--notification-height));
  box-sizing: border-box;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding: var(--padding-m);
  padding-right: grid(1,2);

  @media only screen and (max-height: 700px) {
      padding-top: calc(var(--header-height) + var(--notification-height));
  }

  &__has-discount {
    align-items: flex-end;
  }

  &__buttons {
    &-oos {
      cursor: not-allowed;

     &> span {
      color: var(--color-grey-medium);
     }
    }
  }

  &__inner {
    position: relative;
    display: flex;
    width: grid(4);
    flex-direction: column;
    justify-content: center;
    gap: var(--grid-gutter);

    form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--grid-gutter);
    }
  }

  &__legal {
    width: grid(4);
    padding-top: var(--padding-m);
    color: var(--color-grey-medium);
    @include style(typo-footnote);
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: rem(6);
    @include style(typo-base-uppercase);
  }

  &__description {
    @include style(typo-base-uppercase);
    margin: 0;
    color: var(--color-grey-medium);

    button {
      display: inline-block;
      padding: 0;
      vertical-align: 2%;
    }
  }

  &__variants {
    @include style(typo-base-uppercase);
    display: flex;
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    color: var(--color-grey-medium);

    &.has_alt_colors {
      flex-direction: column;

      .product-details__variant-item {
        padding: 0;

        &:first-child:not(:last-child) {
          padding: 0;
          border-right: none;
        }
      }


      .product-details__variant-item.product-details__variant-color {
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);
      }

      // & > * {
      //   &:not(:first-child) {
      //     border-top: var(--border-size) solid var(--color-grey-divider-light);
      //   }
      // }
    }

    &.is_bundle {
      flex-direction: column;

      .product-details__variant-item.product-details__variant-color {
          border-right: 0;
          border-bottom: var(--border-size) solid var(--color-grey-divider-light);

          .product-details__variant-item-inner {
            padding-left: 0;
          }
      }

      .product-details__variant-item.product-details__variant-size {
        padding-left: 0;

        .product-details__variant-item-inner {
          padding-left: 0;

          &:last-of-type {
            padding-left: rem(10);
          }
        }
      }
    }

    .product-details__variant-size {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }
  }

  &__variant {
    &-toggle {
      display: flex;
      align-items: center;
    }

    &-item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;


      &-inner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: rem(8) 0;
        gap: rem(20);

        .icon {
          transition: opacity var(--opacity-duration) ease-in-out;

        }

        button:not(:disabled) & {
          @include hover {
            &:hover {
              color: var(--color-main);
              transition: color var(--opacity-duration) ease-in-out;

              .icon {
                opacity: 1;
              }
            }
          }
        }

        &.is_os {
          height: rem(48);
        }

        &:first-child {
          &:not(:last-child) {
            padding-right: var(--padding-s);
            border-right: var(--border-size) solid var(--color-grey-divider-light);
          }
        }
      }



      span {
        display: flex;
        align-items: center;
      }

      .icon {
        opacity: 0.453;
      }

      &:first-child:not(:last-child) {
        padding-right: rem(10);
        border-right: var(--border-size) solid var(--color-grey-divider-light);
      }

      &:last-child {
        padding-left: rem(10);
      }
    }
  }

  &__color {
    display: flex;
    height: rem(32);
    align-items: center;
    gap: var(--gap-s);

    &-swatch {
      @include style(color-swatch)
    }

    p {
      overflow: hidden;
      max-width: grid(2, 2);
      margin-top: 0;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }


  &__notice-bis {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__notice {
    color: var(--color-grey-medium);
    font-weight: medium;
    text-transform: initial;
  }

  &__bis {
    display: flex;
    align-items: center;
    gap: rem(5);
    text-align: right;
    @include style(typo-base-uppercase);
    @include basic-underline;

    &.mobile {
      display: none;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: var(--padding-m);
    gap: rem(6);
  }


  &__price {
    .price {
      flex-direction: row;
      align-items: flex-start;
      gap: var(--gap-s);
    }
  }

  &__wishlist {
    &-desktop {
      position: absolute;
      top: calc(var(--padding-s) * -1);
      right: 0;
    }

    &-mobile {
      display: none;
      width: grid(6);
      margin-left: var(--padding-m);
    }
  }
}

@include tablet-portrait {
  .product-details {
    position: relative;
    display: flex;
    overflow: initial;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 0;

    &__legal {
      position: relative;
      display: none;
      width: grid(4);
      margin-left: grid(1,2);
      color: var(--color-grey-medium);
      @include style(typo-footnote);
    }

    &__price {
      .price {
        flex-direction: column;
        align-items: flex-end;
        gap: var(--gap-xxxs);
      }
    }

    &__wishlist {
      &-desktop {
        display: none;
      }

      &-mobile {
        display: block;
        margin-top: rem(20);
      }
    }

    &__inner {
      width: grid(6,2);
      gap: 0;
    }

    &__header {
      flex-direction: row;
      justify-content: space-between;
      padding: rem(27.5) var(--padding-m);
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }

    &__description {
      display: none;
    }

    &__variants {
      flex-direction: column;
      border-top: 0;
      color: var(--color-black);

      &:not(.has_alt_colors) {
        display: none;
      }

      &.has_alt_colors {
        .product-details__variant-item {
          &:first-child:not(:last-child) {
            padding-right: var(--padding-s);
            padding-left: grid(0, 1);
          }
        }
      }
    }

    &__variant-size {
      display: none;
    }

    &__variant-item {
      height: rem(72);
      padding-right: var(--padding-s);
      padding-left: grid(0, 1);
      text-transform: capitalize;

      &-inner {
        gap: grid(0, 1);

        & > span {
          font-size: rem(16);
          line-height: 120%;
        }
      }


      .icon {
        opacity: 1;
      }
    }

    &__variant-toggle {
      width: 100%;
      justify-content: flex-end;
    }

    &__color {
      height: unset;
      justify-content: space-between;
      text-transform: uppercase;

      p {
        overflow: hidden;
        max-width: grid(3, 3);
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-swatch {
        min-width: rem(8);
      }

      .single & {
        padding-right: var(--padding-s);
      }
    }

    &__links {
      display: none;
    }

    &__notice-bis {
      display: none;
      justify-content: flex-end;
      padding: 0 var(--padding-m);
    }

    &__notice {
      display: none;
    }

    &__buttons {
      display: none;
    }

    &__title {
      width: grid(4);
    }

    .appearing & {
      position: static;
    }


    &__price-info {
      margin-bottom: 0;
    }



  }

}
