.sizes {
  &__header {
    position: sticky;
    z-index: 2;
    top: 0;
    width: grid(5, 1);
    background: var(--color-background);

    &-inner {
      @extend .panel__header;
      padding-left: var(--padding-s);
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }
  }

  &__inner {
    display: flex;
    overflow: hidden auto;
    max-width: grid(5, 1);
    max-height: var(--vh);
    flex-direction: column;
    padding: grid(0, 1) 0;
    gap: var(--padding-xl);


    @include custom-scrollbar;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: var(--gap-xs);


    span:last-child {
      color: var(--color-grey-medium);
    }
  }

  &__toggles {
    display: flex;
    align-items: flex-start;
    gap: rem(8);

    button {
      &:not(.active) {
        color: var(--color-grey-medium);

        &::after {
          opacity: 0;
        }
      }
    }
  }

  &__toggle {


  }

  &__scrollbar {
    position: relative;
    display: none;
    width: calc(100% - var(--grid-gutter) * 2);
    height: rem(2);
    margin-left: var(--grid-gutter);
    background-color: var(--color-grey-light);

    &.scrollable {
      display: block;
    }

    & + .sizes__how-to,& + .sizes__help  {
      margin-top: rem(80);
    }

  }

  &__track {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--color-main);
    cursor: grab;
    transition: transform 0.1s ease-in-out;

    &.grabbing {
      cursor: grabbing;
    }


    &:hover {
      transform: scaleY(2);
    }
  }


  &__subtitle {
   @include style(typo-base-body);

  }

  &__description {
   padding:0  var(--grid-gutter);
    margin: 0;
   color: var(--color-grey-medium);
   @include style(typo-base-body);


  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--grid-gutter);

    &::before {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      display: block;
      width: grid(0, 1);
      height: 100%;
      background-color: var(--color-white);
      content: '';
    }

    &-header {
      display: flex;
      justify-content: space-between;
   padding:0  var(--grid-gutter);


    }

    &-content {
      position: relative;
      display: flex;

      // overflow: hidden;
      overflow: visible hidden;
      max-width: grid(5,1);
      flex-direction: column;
      gap: var(--padding-m);

      @include hide-scrollbar;
    }
  }

  &__how-to {
    display: flex;
    flex-direction: column;
    padding: var(--padding-m);
   margin:0  var(--grid-gutter);
    background-color: var(--color-grey-light);
    gap: var(--gap-s);

   // when previous item is the scrollbar








    h3 {
      @include style(typo-base-uppercase);
    }

    p {
      @include style(typo-footnote-l);
      padding-left: 0;
    }
  }

  &__help {
   padding:0  var(--grid-gutter);
    margin-top: calc(var(--padding-m) * -1);
   color: var(--color-grey-medium);
    @include style(typo-footnote-l);


  }


  &__table {
    position: relative;
    display: table;
    width: max-content;
    min-width: calc(100% - var(--grid-gutter) * 2);
    height: auto;

    // border: var(--border-size) solid var(--color-grey-divider-light);
    margin: 0 grid(0,1);

    // border-collapse: collapse;
    border-spacing: 0;
    @include style(typo-base-uppercase);

    &[data-measures='inches'] { display: none; }

    .sizes[data-unit='inches'] & {
      &[data-measures='inches'] { display: block; }
      &[data-measures='cm'] { display: none; }
    }

    tr {
      height: oldGutters(1);

      th {
        border-top: rem(.5) solid var(--color-grey-divider-light);
        vertical-align: text-top;

        &:first-of-type {
          position: sticky;
          left: grid(0, 1);
          width: rem(40);
          min-width: rem(10);
          flex-grow: 0;

        }
      }

      &:first-of-type {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);

        // td,th {
        //   border-top: 0;
        // }
      }

      // &:last-of-type {
      //   td,th {
      //     border-bottom: 0;
      //   }
      // }
    }

    td, th {
      min-width: rem(120);

      // max-width: rem(120);
      box-sizing: border-box;
      padding: var(--padding-s) var(--padding-m);
      border: var(--border-size) solid var(--color-grey-divider-light);
      border-top: 0;
      border-right: 0;
      table-layout: fixed;
      text-align: left;


      &:first-of-type {
        // min-width: rem(105);
        left: 0;
        border-right: rem(.5) solid var(--color-grey-divider-light);
        background-color: var(--color-background);
      }

      &:nth-of-type(2) {
        border-left: 0;
      }

      &:last-of-type {
        border-right: rem(.5) solid var(--color-grey-divider-light);
      }
    }

    td {
      // border-top: 1px solid $border-color;

      &:first-of-type {
        position: sticky;
        left: grid(0, 1);
      }
    }
  }

  &__table + h3, &__description + h3 { margin-top: rem(20); }
}

@include tablet-portrait {
  .sizes {
    &__header {
    width: grid(6, 2);
    }

    &__inner {
    max-width: grid(6, 2);

    }

    &__item {
      &-content {
        max-width: unset;
      }
    }

    &__scrollbar {
      & + .sizes__how-to,& + .sizes__help  {
        margin-top: rem(60);
      }
    }
  }

}
