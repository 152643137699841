.breadcrumbs {
  @include style(typo-base-uppercase);
  padding: var(--padding-xl) 0 var(--padding-m) 0;
  border-bottom: var(--border-size) solid var(--color-grey-divider-light);

  ul {
    display: flex;
    overflow: scroll hidden;
    align-items: center;
    @include hide-scrollbar;

    // justify-content: center;

    li {
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      &:first-child {
        margin-left: var(--padding-m);
      }

      &:last-child {
        position: relative;

        &::after {
          position: relative;
          display: block;
          width: var(--padding-m);
          height: var(--padding-m);
          content: '';
        }
      }

      &:not(:first-child) {
        &::before {
          padding: 0 var(--padding-s);
          color: var(--color-grey-medium);
          content:'•';
        }
      }

      &:not(:last-child) {
        a {
          color: var(--color-grey-medium);
          transition: color var(--opacity-duration) ease-in-out;

          &:hover {
            color: var(--color-main);
          }
        }
      }

      a, span {
        white-space: nowrap;
      }
    }
  }

  @include tablet-portrait {
    padding-top: var(--padding-m);
  }
}
