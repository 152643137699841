@use 'sass:math';

.menu {
  position: fixed;
  z-index: index('overlay-3');
  top: 0;
  left: 0;
  display: block;
  width: grid(6,1);
  height: var(--vh);
  pointer-events: none;

  &.visible {
    z-index: index('overlay-4');
    pointer-events: all;

  }

  &-overlay {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-black);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;


    &.visible {
      cursor: pointer;
      opacity: 0.8;
      pointer-events: all;
    }
  }


  &-close {
    position: fixed;
    top: calc(var(--page-top) / 2);
    left: var(--padding-s);
    display: flex;
    align-items: center;
    @include style(typo-base-uppercase);
    gap: var(--gap-xs);
  }

  &-level {
    &__first {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      display: flex;
      width: grid(3,1);
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding: var(--page-top) var(--padding-m) var(--padding-m) var(--padding-m);
      background-color: var(--color-background);
      transform: translateX(calc(-100% - rem(1)));

      .menu-list {
        display: flex;
        flex-direction: column;

        &__item {
          display: inline-block;
          height: max-content;
          color: var(--color-main);

          &:first-of-type {
            a {
             padding-top: 0;
            }
           }

           &:last-of-type {
             a {
               padding-bottom: 0;
             }
           }


          .safari & {
            will-change: transform;
          }
        }

        button, a {
          display: inline-block;
          padding: rem(2) 0;
          text-align: left;
          text-wrap: nowrap;
          transition: color .06s ease-in-out;
          @include style(typo-display-uppercase);

          &.inactive {
            color: var(--color-grey-medium);
          }

          &.active {
            color: var(--color-main);
          }
        }

        .red-text {
          & .active {
            color: var(--color-red);
          }
        }

        .green-text {
          & .active {
            color: var(--color-green);
          }
        }

        .blue-text {
          & .active {
            color: var(--color-blue);
          }
        }
      }
    }

    &__second {
      position: absolute;
      z-index: 1;
      top: 0;
      left: grid(3,1);
      display: flex;
      overflow:  hidden auto;
      width: grid(3,1);
      height: 100%;
      padding: var(--page-top) 0 0 0;
      border-left: var(--border-size) solid var(--color-grey-divider-light);
      background-color: var(--color-background);
      transform: translateX(-200%);
      @include hide-scrollbar;




      &-inner {
        position: absolute;
        z-index: 0;
        top: var(--page-top);
        left: 0;
        display: flex;
        width: 100%;
        height: calc(100% - var(--page-top));
        flex-direction: column;
        justify-content: space-between;
        overflow-y: scroll;
        pointer-events: none;
        @include hide-scrollbar;

        &.visible {
        pointer-events: all;
        }
      }


      .menu-list {
        display: flex;
        flex-direction: column;
        padding: 0 var(--padding-m);

        &__subitem {
          @include style(typo-base-uppercase);
          display: inline-block;
          height: max-content;

          &:first-of-type {
           a {
            padding-top: 0;
           }
          }

          &:last-of-type {
            a {
              padding-bottom: 0;
            }
          }

          a {
            display: flex;
            width: 100%;
            padding: rem(3) 0;
            color: var(--color-main);
            opacity: 0;
            transition: color .06s ease-in-out;


            &.inactive {
              color: var(--color-grey-medium);
            }

            &.active {
              color: var(--color-main);
            }


          }
        }

      }

      &-back {
        display: none;
        align-items: center;
        padding: var(--padding-header-scrolled-mobile);
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);
        margin-bottom: var(--padding-m);
          color: var(--color-main);
        gap: var(--gap-xs);

          @include style(typo-base-uppercase);
      }

    }

  }

  &-links {
    &__account {
      &:first-child {
        .logged & {
          display: none;
        }
      }

      &:nth-child(2) {
        display: none;

        .logged & {
          display: none;
        }
      }

    }



    &__main {
      display: flex;
      flex-direction: column;
      gap: var(--gap-m);
    }

    &__secondary {
      ul {
        display: flex;
        flex-direction: column;
        color: var(--color-main);
        @include style(typo-base-uppercase);

        li {
          &:not(:last-child) {
            padding-bottom: rem(6);
          }
        }

        .inactive {
          color: var(--color-grey-medium);
        }

        .active {
          color: var(--color-main);
        }
      }
    }
  }


  &-pushes {
    color: var(--color-main);


    &__single {
      padding: var(--padding-m);
    }

    &__vertical {
      padding: var(--padding-m);

      .menu-pushes__inner {
        display: flex;
      flex-direction: column;
      gap: rem(15);
      }
    }

    &__horizontal {
    padding: var(--padding-m) 0 var(--padding-m) var(--padding-m);


      .menu-pushes__inner {
        position: relative;
        display: flex;
        width: grid(3,-1);
        flex-direction: row;
        align-items: flex-start;
        @include hide-scrollbar;
        gap: var(--grid-gutter);



        .menu-pushes__push {
          width: grid(2);
          flex-shrink: 0;

          &:last-child {
            // margin-right: var(--grid-gutter);
          }



          img {
            aspect-ratio: 1/1;
            object-fit: cover;
            object-position: center top;
            pointer-events: none;
          }
        }
      }
    }

    &__push {
    opacity: 0;

      a {
        display: flex;
        flex-direction: column;
        gap: var(--gap-s);
      }

      img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center top;
      }

      p {
        @include style(typo-base-uppercase);
        margin-top: 0;
      }
    }
  }


  @include tablet-portrait {
    z-index: -1;
    width: 100%;
    height: var(--vh);

    // top: var(--page-top);
    padding-top: var(--page-top);

    // background-color: var(--color-background);
    // opacity: 0;
    // transform: translateX(-50%);

    .inactive, .active {
      color: var(--color-main);
    }

    &.visible {
      z-index: -1;
    }


    .menu {
      &-close {
        display: none;
      }

      &-overlay {
        display: none;
      }

      &-level {
        &__first {
          z-index: 0;
          width: grid(6,2);
          padding-top: calc(var(--page-top) + rem(15));
          opacity: 0;
          transform: translateX(-50%);

          // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          // transform: unset;
        }

        &__second {
          z-index: 0;
          left: 0;
          width: grid(6,2);
          padding-top: calc(var(--page-top));
          border-left: 0;
          background-color: transparent;

          // background-color: var(--color-background);
          opacity: 0;
          pointer-events: none;
          transform: unset;

          &-back {
            display: flex;
            width: 100%;
            padding: var(--padding-s);
            border-bottom: var(--border-size) solid var(--color-grey-divider-light);
            margin-bottom: rem(16);

          }

          &-inner {
          top: 0;
          width: 100%;
          height: 100%;
          padding-top: calc(var(--page-top));
          opacity: 0;
          pointer-events: none;


            &.visible {
              opacity: 1;
              pointer-events: all;
            }
          }

          .menu-list {
            // gap: 0;
            gap: rem(8);
          }


        }
      }

      &-pushes {
        padding: var(--padding-m) 0;


        &__vertical {
          .menu-pushes__inner {
            flex-flow: row nowrap;
            gap:var(--grid-gutter);
            -webkit-overflow-scrolling: touch;
            overflow-y: auto;
            @include hide-scrollbar;

            &::before {
              display: inline-block;
              min-width: var(--grid-gutter);
              max-width: var(--grid-gutter);
              height: var(--grid-gutter);
              margin-left: calc(-1 * var(--grid-gutter));
              content: "";
          }

            &::after {
              display: inline-block;
              min-width: var(--grid-gutter);
              max-width: var(--grid-gutter);
              height: var(--grid-gutter);
              margin-left: calc(-1 * var(--grid-gutter));
              content: "";
          }
          }

          .menu-pushes__push {
            width: grid(4);
            flex-shrink: 0;

            img {
              aspect-ratio: #{math.div(229, 155)};
              object-position: 50% 0%;
            }
          }
        }

        &__horizontal {
          padding: var(--padding-m) 0 var(--padding-m) 0;


            .menu-pushes__inner {
              position: relative;
              display: flex;
              width: grid(6,2);
              flex-direction: row;
              align-items: flex-start;
              gap: var(--grid-gutter);

              &::before {
                display: inline-block;
                min-width: var(--grid-gutter);
                max-width: var(--grid-gutter);
                height: var(--grid-gutter);
                margin-left: calc(-1 * var(--grid-gutter));
                content: "";
            }

              &::after {
                display: inline-block;
                min-width: var(--grid-gutter);
                max-width: var(--grid-gutter);
                height: var(--grid-gutter);
                margin-left: calc(-1 * var(--grid-gutter));
                content: "";
            }



              .menu-pushes__push {
                width: grid(4);
                flex-shrink: 0;


                &:last-child {
                  margin-right: 0
                }



                img {
                  aspect-ratio: 229/155;
                  object-position: 50% 0%;
                }
              }
            }
          }

        &__single {
          padding: var(--padding-m);

          .menu-pushes__push {
            width: grid(4);

            img {
              aspect-ratio: 229/155;
            }
          }
        }

        &__push {
          a {
            display: flex;
            flex-direction: column;
            gap: var(--gap-s);
          }

          img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            object-position: center top;
          }

        }
      }


    }

  }
}
