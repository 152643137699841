.account-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: var(--border-size) solid var(--color-grey-divider-light);
  transition: border-color 0.3s ease-in-out;


  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-m);
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);

    &-right {
      align-self: flex-start;
      margin-left: rem(10);
      color: var(--color-grey-medium);
    }

    h2 {
      @include style(typo-display-uppercase);
    }

    h3 {
      @include style(typo-base-uppercase);
      color: var(--color-grey-medium);
    }


    &-icon {
      img {
        height: rem(20);
      }

      .icon {
        background-position: center;
        background-repeat: no-repeat;
      }
    }

  }


  &__subtitle {

  }

  &__body {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--padding-m);
    font-size: rem(16);
    @include style(typo-base-body);

    & > * {
      &:not(:last-child) {
        padding-bottom: var(--padding-m);
      }
    }

    &-content {
      & span {
        margin-right: rem(6);
        letter-spacing: rem(4);
      }
    }

   &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: var(--padding-m);
    @include style(typo-base-uppercase);
    }

    &-ctas {
      display: flex;
      padding: var(--padding-m) 0 0 0;
      gap: rem(16);
    }
  }

  @include tablet-portrait {
    &__subtitle {
      margin-top: rem(8);
    }
  }

}
