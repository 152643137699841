.product-focus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: rem(100);

  &__image {
    max-width: oldGrid(6);
    height: auto;
    margin: 0 0 oldGutters(1);

    // object-fit: contain;
  }

  &__inner {
    width: rem(386);
    text-align: center;
  }

  &__description {
    margin-top: oldGutters(.5);
    color: var(--color-grey-medium)
  }

  &__link {
    display: inline-block;
    margin-top: oldGutters(.5);
    @extend .button-underlined;
  }
}

@include tablet-portrait {
  .product-focus {
    margin-bottom: oldGuttersM(3);

    .product-related + & {
      padding-top: oldGuttersM(3);
      border-top: 1px solid $border-color;
    }

    &__image {
      width: 100%;
      height: auto;
      margin: 0 0 oldGuttersM(3);
    }

    &__inner {
      width: 100%;
    }

    &__description {
      margin-top: oldGuttersM(2);
    }

    &__link {
      margin-top: oldGuttersM(2);
    }
  }
}
