.checkout-promo {
  display: flex;
  flex-direction: column;
  gap: var(--gap-s);

  .field {
    &__input {
      padding: var(--padding-m);
      border: 0;
      border-radius: rem(4);
      background-color: var(--color-grey-light);

      &:hover {
        background-color: #F2F2F2;
      }

        &:focus {
          border:0;
          background-color: var(--color-grey-light);

          &::placeholder {
            color: var(--color-grey-medium);
            opacity: 0.5;
          }
        }

    }

    &__error {
      display: none;
    }
  }

  &__form {
    position: relative;

    &.valid .checkout-promo__submit {
      opacity: 1;
    }

    & button {
      margin-top: 0 !important;
    }
  }

  &__submit {
    @include style(typo-base-uppercase);
    position: absolute;
    top: 50%;
    right: var(--padding-m);
    bottom: 0;
    height: max-content;
    opacity: 0;
    transform: translateY(-50%);
    @include transition('opacity', .3s, $cubic-ease-out);

    &.visible {
      opacity: 1;
    }
  }

  &__message {
    display: flex;
    align-items: center;
    color: var(--color-green);
    gap: var(--gap-s);
    @include style(typo-base-uppercase);

  }
}
