.product-bar {
  position: fixed;
  z-index: 10;
  top: var(--header-height);
  left: 0;
  width: 100%;
  padding-bottom: 0 !important;
  background-color: var(--color-white);
  opacity: 1;
  transition: all var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);
  visibility: visible;
  @include style(typo-base-uppercase);

  .appearing & {
    display: none;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.in-flow {
    position: relative;
    top: 0;
    transition: none;

  }

  &.not-valid {
    .button-rect, .button-rect-light {
      &::after {
        content: attr(data-invalid);
      }
    }
  }

  &__inner {
    display: flex;
    height: auto;
    align-items: center;
    padding: 0;
    border: 1px solid $border-color;
    border-right: 0;
    border-left: 0;
  }

  &__column {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    border-right: 0;

    &:last-child {
      display: flex;
      height: auto;
      justify-content: flex-end;
    }
  }

  &__image, video {
    width: rem(60);
    height: rem(80);
    margin-right: var(--padding-m);
    object-fit: cover;
  }

  &__price {
    margin-top: rem(3);

    .price {
      flex-direction: row;
      align-items: flex-start;
      gap: rem(10);
    }
  }

  &__add {
    width: grid(3);
    margin: auto;
    margin-right: grid(0, 1);

    button {
      margin-top: 0;
      text-align: center;
    }
  }

  .button__text-inner {
    text-align: center !important;

    &::after {
      justify-content: center !important;
      text-align: center !important;
    }
  }

  .button__aside {
    display: none;

    .price {
      flex-direction: row-reverse;
      gap: rem(8);

      ins {
        color: var(--color-background) !important;
      }

      del {
        display: none;
      }

      var {
        display: none;
      }
    }
  }
}

.scroll-down {
  .product-bar:not(.in-flow) {
    top: var(--header-height-scrolled);
  }
}

@include tablet-portrait {
  .product-bar {
    position: sticky;
    bottom: 0;
    padding: var(--padding-m);
    padding-bottom: var(--padding-m) !important;
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    margin-top: 0;

    // &.force-hidden {
    //   display: none;
    // }

    &.in-flow {
      padding-bottom: 0 !important;
      border-top: 0;
      border-bottom: 0;
    }

    &__inner {
      height: auto;
      border: 0;

    }

    &__column {
      width: 100%;
      padding: 0;

      &:first-child {
        display: none;
      }
    }

    &__add {
      display: flex;
      width: 100%;
      margin-right: 0;
    }

    .button__text-inner {
      .product-details__buttons-availability &, .product-details__buttons-pre-order & {
        text-align: center !important;
      }
    }

    .product-details__buttons-add {
      .button__text-inner {
      text-align: left !important;
      }
    }

    .button__aside {
      display: flex;
    }
  }
}
