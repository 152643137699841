.journal-card {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;

  &__img {
    &.portrait {
      width: oldGrid(4);
    }
    display: flex;
    overflow: hidden;
    width: oldGrid(8);
    justify-content: center;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: rem(40);
  }

  &__title {
    margin-bottom: rem(10);
  }

  &__view {
    color: var(--color-grey-medium)
  }

  @include tablet-portrait {
    &, &__img {
      width: 100% !important;

      &.portrait {
        width: gridM(4, 2) !important;
      }
    }

    &__footer {
      margin-top: rem(20);
    }
  }
}
