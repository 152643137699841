.header {
  position: sticky;
  z-index: calc(index('header') + 1);
  top: 0;
  left: 0;
  width: 100%;
  height: var(--header-height);
  pointer-events: none;


&.header__only-logo, &.header__only-logo-returns {
  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: grid(7,2);
    height: var(--header-height);
    background-color: var(--color-background);
    content: '';
  }
}

  & svg, i {
    transition: all var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &-checkout {
    display: none;

   .header__only-logo & {
    display: flex;
   }
    align-items: flex-end;

    a {
      display: flex;
      align-items: center;
      @include style(typo-base-uppercase);
    }
  }

  &-returns {
    display: none;

   .header__only-logo-returns & {
    display: flex;
   }
    align-items: flex-end;

    a {
      display: flex;
      align-items: center;
      @include style(typo-base-uppercase);
    }
  }

  &__inner {
    display: grid;
    height: var(--header-height);
    box-sizing: border-box;
    padding: var(--padding-header);
    font-weight: 500;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    pointer-events: all;
    transition: all var(--header-duration) $cubic-ease-in-out;
  }

  &-link {
    display: flex;
    align-items: center;
    gap: var(--gap-xs);


  }

  &-left {
    display: flex;
    align-items: flex-end;
    gap: var(--grid-gutter);
    grid-column: 1;

    button {
      @include style(typo-base-uppercase);
      min-width: var(--grid-column);
      text-align: left;

    }
  }

  &-logo {
   width: auto;
   height: rem(24);
   margin-top: rem(27);
   grid-column: 2;
   place-self: start center;
  @include transition('margin-top, transform, height,width', var(--header-duration), cubic-bezier(0.645, 0.045, 0.355, 1));

  // transition: all .3s $cubic-ease-in-out;

 @include tablet-portrait {
  .menu-opened & {
    color: var(--color-main);
      filter: invert(0%) !important;
  }
 }



   a {
    display: block;
    width: auto;
    height: 100%;

    svg {
      width: auto;
      height: 100%;
    }
   }
  }

  &-right {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: var(--padding-m);
    grid-column: 3;

    &__wishlist {
      a:last-child {
        display: none;
      }

      .logged & {
        a:first-child {
          display: none;
        }

        a:last-child {
          display: block;
        }
      }
    }

    &__auth {
      transition: var(--opacity-duration) opacity ease-in-out;



      a {
        display: block;
        width: rem(32);
        height: rem(32);

       i {
        .menu-opened & {
          // filter: invert(0%) !important;
        }
       }

        &:last-child {
          display: none;
        }

        // auth anim
        @include hover {
          &:hover {
            opacity: 0.5;
          }
        }

        @include hover-scale-down;
      }

      @keyframes pop {
        0% {
          transform: translateY(0)
        }

        50% {
          transform: translateY(-2px)
        }

        100% {
          transform: translateY(0)
        }
      }

      .logged & {
        a {
          &:first-child {
            display: none;
          }

          &:last-child {
            display: block;
          }
        }
      }
    }

    a {
      @include style(typo-base-uppercase);
      text-align: right;
    }

    &__links {
      display: flex;
      height: rem(32);
      align-items: center;
      gap: var(--gap-l);

      a {
        @include hover {
          transition: var(--opacity-duration) opacity ease-in-out;

          &:hover {
            opacity: 0.5;
          }
        }
      }

    }

    &__actions {
      display: flex;
      gap: var(--gap-s);
    }

    &__cart {
      position: relative;
      @include transition('opacity', var(--opacity-duration), ease-in-out);
      @include transition('transform', 150ms, ease-in-out);

      i {
        @include transition('transform', 150ms, ease-in-out);
      }


      @include hover {
        &:hover {
          opacity: 0.5;
        }
      }
      @include hover-scale-down;


      @include tablet-portrait {
        .menu-opened & {
          color: var(--color-main);

          i {
            filter: invert(0%) !important;
          }

            &::after {
              background-color: var(--color-main) !important;
              color: var(--color-background) !important;

        }
      }
      }

      &:not([data-count="0"]) {
        &::after {
          position: absolute;
          z-index: 0;
          top: rem(3);
          right:rem(2);
          display: flex;
          width: rem(12);
          height: rem(12);
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: var(--color-main);
          color: var(--color-background);
          content: attr(data-count);
          font-family: $main-font;
          font-size: rem(8);
          font-weight: bold;
          transition: all var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);
         }
      }

    }
  }


  /** States */

  /** States -> Solid
    Black text on white background
  */
  &__solid & {
    &__inner {
      background-color: var(--color-background);
    }
  }

  &__search & {
    &__inner {
      // background-color: var(--color-background) !important;
    }
    color: var(--color-main) !important;

    .icon {
      filter: invert(0%) !important;

    }

    .header-logo {
      filter: invert(0%) !important;
    }

    .header-menu svg {
      filter: invert(0%) !important;
    }

    .header-right__cart.header-right__cart-not-empty {
      &::after {
        background-color: var(--color-main) !important;
        color: var(--color-background) !important;
      }
    }
  }

  &__solid-fixed & {
    &__inner {
      background-color: var(--color-background);
    }
  }

  /** States -> Light
    To use in combination with .header__transparent.
    Black text icons and logo on transparent background when layer below is light-ish.
  */
  &__light & {
    &__inner {
      color: var(--color-main);
    }
  }

  /** States -> Dark
    To use in combination with .header__transparent.
    White text, icons and logo on transparent background when layer below is dark-ish.
  */
  &__dark & {
    color: var(--color-background);

    .icon {
      &:not(.menu-back) {
        @extend %icon-inverted;
      }
    }

    .header-logo {
      filter: invert(100%);
    }

    .header-menu svg, .header-right__auth i {
      filter: invert(100%);
    }

    .header-right__cart.header-right__cart-not-empty {
      &::after {
        background-color: var(--color-background);
        color: var(--color-main);
      }
    }
  }


  /** States -> Transparent
    Transparent background
  */
  &__transparent & {
    &__inner {
      background-color: transparent;
    }
  }



  /** States -> Only logo
    Only show logo, no other elements
  */

  &__only-logo &, &__only-logo-returns & {
    &-left,
    &-right {
      display: none;
    }
  }

  /** States -> Scrolled
    Shrink header height and padding when scrolled, align everything to vertical center
  */
  .scroll-down & {
    &__inner {
      height: var(--header-height-scrolled);
    }

    // padding: var(--padding-header-scrolled);

    .header-logo {
      margin-top: rem(4);
      transform: scale(0.74);
    }
  }


  /** Responsive */
  @include tablet-portrait {
    height: var(--header-height);

    &-checkout, &-returns {
      a {
        span {
          display: none;
        }
      }
    }




    &__inner {
      height: var(--header-height);
      align-items: center;
      padding: var(--padding-header-mobile) var(--padding-s);
    }

    &-left {
      gap: rem(8);

      button {
        min-width: unset;

        span {
          display: none;
        }

      }
    }

    &-logo {
      height: rem(16);
      align-self: center;
      margin-top: 0;
    }

    &-right {
      &__links {
        display: none;
      }

      &__auth {
        i {
          .menu-opened & {
            filter: invert(0%) !important;
          }
         }
      }

      &__actions {
        gap: rem(8);
      }
    }

    .scroll-down & {
      .header-logo {
        margin-top: 0;
        transform: none;
      }
    }
  }

}


.header-menu {
  position: relative;
  z-index: 800;
  cursor: pointer;
  @include style(typo-base-uppercase);

  line {
  transition: transform 100ms $quad-ease-in-out;
  will-change: transform;

  }


  &:not(.menu-opened &) {
    transition: var(--opacity-duration) opacity ease-in-out;

 @include hover {
  &:hover {
    opacity: 0.5;

    line {
      &:first-child {
        transform: translate3d(0, .5px, 0);
      }

      &:last-child {
        transform: translate3d(0, -.5px, 0);
      }
    }
  }
 }
  }

  .menu-opened & {
    color: var(--color-main);

    svg {
      filter: invert(0%) !important;
    }
  }

  // .header__dark.header__transparent & {
  //     color:var(--color-background);

  //     svg {
  //       filter: invert(100%);
  //     }
  // }

  &.opened {
    color:var(--color-main) !important;

    svg {
      filter: invert(0%) !important;
    }
  }

 &>div {
  position: relative;
  overflow: hidden;
  width: rem(50);
  height: rem(16);

 }

    span {
      // opacity: 0;
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      line-height: 0;

      &:last-child {
        opacity: 0;
        transform: translateX(-10px);
      }
    }

    // &.open {
    //   svg {
    //     line {
    //       &:first-child {
    //         transform: rotate(45deg);
    //         transform-origin: center;
    //       }

    //       &:last-child {
    //         transform: rotate(-45deg);
    //         transform-origin: left;
    //       }
    //     }
    //   }
    // }



}

.header-search {
  transition: opacity var(--opacity-duration) ease-in-out;

  i {
    transition: transform 200ms ease-in-out;
  }
  @include hover {
    &:hover {
      opacity: .5;

      i {
        transform: scale(0.93);
      }
    }
  }

@include tablet-portrait{
  .menu-opened & {
    color: var(--color-main);

    i {
      filter: invert(0%) !important;
    }
  }
}
}


@include tablet-portrait {
.header-menu {
      &>div {
        display: none;
      }
}

.header-search {
  body:has([data-page="Home"]) &:not(.forced) {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

  }

  .scroll-down & {
    opacity: 1 !important;
    pointer-events: all !important;
  }

  }
}
