.profile__form {
  display: none;
  width: 100%;

  &.visible {
    display: block;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    .form-group {
      display: flex;
      flex-direction: column;
      gap: rem(16);
    }

    .form__inline {
      padding-bottom: rem(24);
    }

    h3 {
      @include style(typo-base-body);
    }
  }

  &-edit-password {
    display: flex;
    flex-direction: column;
    gap: rem(16);
    padding-bottom: rem(24);


    &-drawer {
      overflow: hidden;
      height: 0;
      transition: height .4s $cubic-ease-in-out;

     .opened & {
      height: var(--drawer-height);
     }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      padding-top: rem(16);
      gap: rem(16);
    }
  }

  @include tablet-portrait {
    form {
      gap: var(--padding-l);
    }
  }
}
