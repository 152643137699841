.search-panel {
  &__wrapper, &__header {
    position: fixed;
    z-index: index('header');
    top: var(--page-top);

    .scroll-down & {
      top: var(--header-height-scrolled);
    }
    right: 0;
    left: 0;
    overflow: scroll;
    overflow: hidden;
    width: 100%;
    height: auto;
    background-color: $white;
    pointer-events: none;
    transform-origin: top;
  }

  &__header {
    z-index: 151;
    right: oldGutters(1);
    left: oldGutters(1);
    display: flex;
    width: auto;
    justify-content: space-between;
  }

  &__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    transform-origin: top;
  }

  .app-started & {
    &__overlay {
      @include transition ('transform, opacity, visibility, border',.4s, $cubic-ease-out );
    }
  }

  &.opened & {
    &__wrapper, &__header {
      pointer-events: auto;
    }

    &__inner {
      border-bottom: 1px solid $border-color;
    }

    &__overlay {
      opacity: .7;
      pointer-events: auto;
      visibility: visible;
    }
  }

  &__page {
    position: absolute;
    top: 0;
    overflow: hidden auto;
    width: 100%;
    min-height: auto;
    box-sizing: content-box;
    padding: var(--header-height) 0 0;
    background-color: var(--background);

    .page__inner {
      // padding: oldGutters(1);
    }

    &.appearing {
      position: absolute;
      top: 0;

      // top: var(--header-top);
      bottom: initial;
      contain: content style;
      pointer-events: none;

      // max-height: var(--available-height);
    }
  }

  &__button {
    visibility: hidden;
  }
}

@include tablet-portrait {
  .search-panel {
    &__wrapper {
      bottom: 0;
      width: 100%;
      height: max-content;
      border: none;
    }



    &__page {
      height: max-content;
      padding-top: var(--header-height-scrolled);

      &, &.appearing {
        bottom: 0;
      }

      .page__inner {
        height: 100%;
      }
    }

    &__inner {
      transform: none;
    }
  }
}
