.transversal {
  &__wrapper {
    background-color: var(--background);

    // margin: 0 oldGutters(-1);
  }

  &__inner {
    display: flex;
    min-height: var(--available-height);
    flex-direction: column;
  }

  &__router {

  }

  &__select {
    display: none;
  }

  &__menu {
    position: sticky;
    z-index: 100;
    bottom: 0;
    height: 0;

    &-inner {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: oldGutters(1);
    }

    .appearing & {
      position: fixed;
    }
  }

  &__link {
    color: var(--color-grey-medium);
    transition: color .15s linear;

    .loading & {
      pointer-events: all !important;
    }

    // &.current, .desktop &:hover {
    //   color: var(--color);
    // }

    & + & {
      margin-top: remMin(12);
    }
  }

  & &__button {
    display: flex;
    align-items: center;
    color: var(--color-grey-medium);
  }

  &__header {
    position: relative;
    z-index: 10;
    padding: 0 0 oldGutters(.5);
    border-bottom: 1px solid $border-color;
    text-align: center;

    &::after {
      position: absolute;
      z-index: -1;
      background: var(--background);
      content: '';
      inset: -(oldGutters(1) + rem($header-height)) 0 0;
      pointer-events: none;
    }
  }

  &__title {
  }
}

@include tablet-portrait {
  .transversal {
    &__wrapper {
      margin: 0;
    }

    &__router {
      width: 100%;
      margin: oldGuttersM(2) auto oldGuttersM(1);
    }

    &__select {
      position: relative;
      z-index: 9;
      display: flex;
      width: 100%;
      height: oldGuttersM(4);
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      background-color: var(--background);

      &::after {
        display: block;
        width: rem($icon-size);
        height: rem($icon-size);
        background-image: url("../svg/down.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        pointer-events: none;
      }

      &-tag {
        @include fullscreen;
        opacity: 0;
      }
    }

    &__menu {
      display: none;
    }

    & &__button {
      display: none;
    }

    &__header {
      position: relative;
      padding: rem(35) 0;
      border-bottom: 1px solid $border-color;
      text-align: center;

      &::after {
        top: -(oldGuttersM(1) + rem($header-height-m));
      }
    }

    &__title {
    }
  }
}
