.facets {
  position: relative;
  overflow: hidden;
  width: 100%;
  @include style(typo-base-uppercase);



  ul {
    position: relative;
    display: flex;
    overflow: scroll hidden;
    gap: rem(16);
    @include hide-scrollbar;

    li {
      text-wrap: nowrap;
      white-space: nowrap;

      &:first-child {
        margin-left: grid(0,1);
      }

      &:last-child {
        position: relative;
        display: flex;

        &::after {
          position: relative;
          top: 0;
          right: 0;
          display: block;
          width: grid(1);
          height: 100%;
          content:'';
        }
      }

      a {
        display: block;
        transition: color var(--opacity-duration) ease-in-out;



        &.active {
          color: var(--color-main);
        }

        &.inactive {
          color: var(--color-grey-medium);
        }
      }
    }
  }
}
