.popin {
  display: none;

  &__wrapper, &__header {
    position: fixed;
    right: 0;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 150;
    pointer-events: none;
  }

  &__header {
    z-index: 201;
    right: oldGutters(1);
    left: oldGutters(1);
    width: auto;
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    position: absolute;
    top: 0;
  }

  &__wrapper {
    background: var(--background);
    bottom: 0;
    transform: translateY(-100%);
  }

  &__inner {
    // overflow-y: scroll;
    // overflow-x: hidden;
    overflow: auto;
    position: relative;

    // margin-top: rem($header-height);
    width: 100%;
    height: 100%;

    //transform: translateZ(0);
  }

  &__wrapper, &__overlay {
    visibility: hidden;
  }

  .app-started & {
    &__wrapper, &__overlay {
      @include transition ('transform, opacity, visibility',.6s, $cubic-ease-in-out );
    }
  }

  &.opened {
    display: block;

      &__wrapper {
        transform: none;
        visibility: visible;
        pointer-events: auto;
      }
  }
}

@include tablet-portrait {
  .popin {
    &__inner {
      // transform: none;
      // margin-top: rem($header-height-m);
    }

    &__header {
      right: oldGuttersM(1);
      left: oldGuttersM(1);
    }
  }
}
