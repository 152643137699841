.stores {
  $top: (rem($header-height));

  .page__title {
    @extend %typo-base-uppercase;
  }

  &.page {
    &.appearing, &.disappearing {
      overflow: visible;
      contain: none;
    }
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  @include not-tablet-portrait {
    .page__title {
      position: relative;
      margin: 0;
      margin-bottom: rem(14);
      text-align: left;
    }

  }

  &__wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: flex-end;
    margin-right: oldGutters(-1);
  }

  &__router {
    position: relative;
    overflow: hidden;

  }

  &__map {
    position: sticky;
    top: var(--header-height);
    width: grid(8, 1);
    height: calc(var(--ivh) - var(--page-top));
  }

  &__inner {
    width: grid(4, 2);
    padding: rem(30) 0 0;
  }

  &__store {
    padding-bottom: oldGutters(1);
  }

  &__header {
    position: sticky;
    z-index: 21;
    top: var(--header-height);
    display: flex;
    flex-direction: column;
    padding: rem(30) grid(0,1);
    background-color: var(--color-white);
    gap: rem(16);
    transition: top var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

    &--footer {
      display: none;
    }

    &::after {
      position: absolute;
      z-index: 20;
      bottom: 0;
      left: grid(0, -1);
      width: calc(100% + grid(0, 2));
      height: rem(.5);
      background-color: var(--color-grey-divider-light);
      content: '';
    }

    .scroll-down & {
      top: var(--header-height-scrolled);
    }


    .native-select {
      & select:focus-visible {
        outline: none;
      }
    }

    .field.with-suggestions {
      margin-bottom: 0 !important;
    }

    .select {
      margin: 0;
    }
  }

  &__item {
    position: relative;
    padding: 0 grid(0,1);
      padding-top: rem(40);
    padding-bottom: rem(40);
    transition: background-color var(--opacity-duration) ease-in-out;

   .stores__list & {
    @include hover {
      &:hover {
        background-color: var(--color-grey-lighter);
      }
     }
   }

    &:not(:last-of-type) {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }

    &:last-of-type {
      padding-bottom: rem(20);
      border-bottom: none;
    }

    &-address {
      @extend %typo-base-uppercase;

      h1, h2 {
        @extend %typo-display-uppercase;
        padding-top: rem(10);
        padding-bottom: rem(20);
        color: var(--color);
      }

      &--details {
        display: flex;
        justify-content: space-between;
        padding-bottom: var(--padding-m);
        border-bottom: rem(.5) solid var(--color-grey-divider-light);
        gap: rem(20);
      }
    }

    &-back {
      @extend %typo-base-uppercase;
      position: relative;
      display: block;

      // padding-top: rem(30);
      padding-bottom: rem(20);

      &::after {
        position: absolute;
        bottom: 0;
        left: grid(0, -1);
        width: calc(100% + grid(0, 2));
        height: rem(.5);
        background-color: var(--color-grey-divider-light);
        content: '';
      }
    }

    &-image {
      width: 100%;
      height: auto;
      margin-top: var(--padding-m);
      margin-bottom: var(--padding-m);
    }

    &-description {
      color: var(--color-grey-medium);
      @extend %typo-base-body;
    }

    &-image, &-description {
      width: grid(4);
    }

    &-hours {
      margin-top: rem(20);
      color: var(--color-grey-medium);
      @extend %typo-base-body;
    }

    &-hours--details {
      @extend %typo-base-uppercase;
      margin-top: rem(20);
    }

    .button.desktop, .button.mobile {
      margin-top: rem(20);
    }

    &-discover {
      @extend %typo-base-uppercase-link;

      h2 {
        margin-top: rem(40);
      }
    }

    &-subtitle {
      color: var(--color);
    }

    &-contact {
      display: flex;
      align-items: flex-start;

      &-item {
        position: relative;
        display: flex;

        &:not(:last-child) {
          margin-right: rem(6);

          &::after {
            padding-left: rem(6);
            content: '|';
          }
        }
      }
    }

    &-link {
      position: relative;
      display: block;
    }
  }
}

@include tablet-portrait {
  .stores {
    $top: rem($header-height-m) + oldGuttersM(1);

    &.page {
      margin-top: rem(-$header-height-m);
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }

    .page__title {
      position: relative;
      z-index: 40;
      margin: 0;
      margin-bottom: var(--padding-s);
      text-align: left;
    }

    &__header {
      position: absolute;
      top: var(--header-height);
      width: 100%;
      padding: var(--padding-m);
      gap: var(--padding-m);
      transition: transform .5s $cubic-ease-out;

      .scroll-down & {
        top: var(--header-height);
      }

      &--footer {
        display: flex;
        justify-content: space-between;
        margin-top: rem(10);

        .tabs {
          margin-right: calc(-1 * var(--padding-m));
        }

        & span {
          color: var(--color-grey-medium);
          font-size: rem(14);
          font-weight: bold;
          letter-spacing: 0;
          line-height: 120%;
          text-transform: uppercase;
        }

        .tabs__header-link {
          padding: 0;

          &:last-of-type {
            margin-right: 0;

            &.active {
              color: var(--color-black);
            }
          }

          &.active {
            text-decoration: underline;
          }
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-end;
      padding-top: rem(320);
      margin: 0 oldGuttersM(-1);

      &--details {
        padding-top: rem(20);
      }
    }

    .is-hidden {
      display: none;
    }

    .map__zoom {
      position: absolute;
      z-index: 30;
      right: rem(40);
      bottom: rem(20);
      left: auto;
      width: rem(64);

      & button {
        width: rem(32);
        height: rem(32);
        border: rem(0.5) solid var(--color-grey-divider-light);
      }
    }

    &__map {
      position: relative;
      z-index: 20;
      top: 0;
      width: 100%;
      height: rem(420);

      &-wrapper {
        position: relative;
        margin: 0;

        &::before {
          position: absolute;
          z-index: 30;
          top: 0;
          left: 0;
          width: rem(40);
          height: 100%;
          content: '';
        }

        &::after {
          position: absolute;
          z-index: 20;
          top: 0;
          right: 0;
          width: rem(40);
          height: 100%;
          content: '';
        }
      }

      &-inner {
        width: 100%;
        height: 100%;
      }
    }

    &__inner {
      position: unset;
      width: 100%;
      padding: 0 oldGuttersM(1);
      margin-top: 0;
      background-color: $background;

      &::before {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        display: block;

        // transform: translateY(-100%);
        width: 100%;
        height: $top;
        background-color: inherit;
        content: '';
      }
    }

    &__store {
      padding: oldGuttersM(1) oldGuttersM(1) oldGuttersM(2);

      &::before {
        content: none;
      }
    }

    &__list {
      margin: oldGuttersM(1) 0;
      background-color: transparent;
    }

    &__item {
      padding-right: var(--padding-m);
      padding-left: var(--padding-m);

      & .button {
        &:not(.stores__item-link) {
          margin-top: rem(40);
        }
      }

      &-image, &-description {
        width: 100%;
      }

      &-description {
        font-size: rem(16);
      }

      &-address {
        &--details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: rem(8);

          .stores__item-contact {
            margin-top: 0;
          }
        }
      }

      &:first-of-type & {
        &-address{
          h1, h2 {
            padding-top: rem(20);
          }
        }
      }

      &-image {
        margin-bottom: oldGuttersM(1);
      }

      & + & {
        margin-top: oldGuttersM(1.5);
      }

      &-hours--details {
        margin-top: rem(20) !important;
      }
    }
  }
}
