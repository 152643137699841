@use 'sass:math';

.search {
  position: fixed;
  z-index: index('header');
  top: 0;
  width: 100%;
  background-color: var(--color-background);
  clip-path: polygon(0 0, 100% 0%, 100% 78%, 0 81%);
  opacity: 0;
  pointer-events: none;
  transition: top var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

  &.opened {
    pointer-events: all;
  }

  &__inner {
    padding-top: var(--page-top);
    transform: translateY(rem(-30));

    .scroll-down & {
      padding-top: var(--header-height-scrolled);
    }
  }

  &.page .page__inner {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    &::before {
      display: block;
      width: 100%;
      content: '';
    }
  }

  &-form {
    position: relative;
    padding: var(--padding-m);
    border-top: var(--border-size) solid var(--color-grey-divider-light);


    &__group {
      position: relative;
      display: flex;
      align-items: center;


      .icon {
        position: absolute;
        z-index: 2;
        left: rem(10);
        opacity: 0.4333;
      }
    }

    button {
      position: absolute;
      top: 50%;
      right: var(--padding-m);
      margin-left: auto;
      transform: translateY(-50%);
    }

    .field {
      width: 100%;
      margin: 0;

      &__error {
        display: none;
      }
    }

    &.valid .search__submit {
      opacity: 1;
      transform: none;
    }
  }

  &__submit[type='submit'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-left: rem(20);
    color: var(--color-grey-medium);
    opacity: 0;
    transform: translateY(rem(-5));
    @include transition('opacity, transform', .3s, $cubic-ease-out);
  }

  .xlinking {
    &__title {
      padding: 0;
      border-bottom: 0;
    }
  }



  &-suggestions {
    display: flex;
    padding-top: rem(15);
    padding-bottom: var(--padding-m);
    padding-left: var(--padding-m);
    border-top: var(--border-size) solid var(--color-grey-divider-light);

    &__block {
      display: flex;
      width: grid(3,1);
      flex-direction: column;
      gap: rem(6);

      ul {
        display: flex;
        flex-direction: column;
        gap: rem(6);
        @include style(typo-base-uppercase)
      }
    }

    &__title {
      @include style(typo-display-uppercase);
      margin-bottom: rem(6);
      color: var(--color-grey-medium);
    }



  }

  &-related {
    width: grid(6);

    &__title {
      @include style(typo-display-uppercase);
      margin-bottom: rem(15) !important;
      color: var(--color-grey-medium);
    }

    &__list {
      &-inner {
        display: flex;
        flex-wrap: wrap;
        gap: grid(0,1);

        .search-related__img {
          width: grid(3);

          .search-related__item {
            img {
              width: 100%;
              height: auto;
              aspect-ratio: #{math.div(335,220)};
              object-fit: cover;
            }

            p {
              display: none;
              @include style(typo-base-uppercase);

              // transition: color var(--opacity-duration) ease-in-out;
            }
          }

          // hover state: standby
          // &.active {
          //   p {
          //     color: var(--color-main);

          //   }
          // }

          // &.inactive {
          //   p {
          //     color: var(--color-grey-medium);
          //   }
          // }

          &.mini {
            display: flex;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: grid(0, 1);
              @include style(typo-base-uppercase);

              img {
                width: grid(1);
                aspect-ratio: #{math.div(360,500)};
              }

              p {
                display: block;
                margin: auto 0;
              }
            }
          }
        }
      }
    }
  }
}

@include tablet-portrait {
  .search {
    &.page .page__inner {
      border-bottom: none;
    }

    &-form {
      width: 100%;
      padding: var(--padding-s);
      padding-top: 0;
      border-top: 0;

      &__group {
        gap: var(--padding-xs);
      }
    }

    &-suggestions {
      flex-direction: column;
      padding: 0;
      padding-top: rem(15);
      padding-bottom: var(--padding-m);
      gap: var(--padding-m);

      &__block {
        width: 100%;
        padding: 0 var(--padding-m);
        gap: var(--gap-xxs);

        &__list {
          &-inner {
            gap: var(--gap-xxs);

          }
        }

        // &:nth-child(2) {
          // display: none;
        // }
      }
    }

    &-related {
      width: 100%;


      &__title {
        display: none;
      }

      &__list {
        overflow: scroll hidden;
        @include hide-scrollbar;

        &-inner {
          width: max-content;

          .search-related__img {
            width: grid(4);

            &:first-child {
              margin-left: var(--padding-m);
            }

            &:last-child {
              margin-right: var(--padding-m);
            }

            .search-related__item {
              display: flex;
              flex-direction: column;
              gap: var(--gap-s);

              img {
                aspect-ratio: #{math.div(229,163)};
              }

              p {
                display: block;
              }
            }

            &.mini {
              width: grid(2);
              min-width: grid(2);


              a {
                img {
                  width: grid(2);
                  aspect-ratio: #{math.div(100,130)};
                }

                h6 {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .xlinking {
      &__list {
        margin: 0;

        &-inner {
          flex-direction: column;
          padding: 0;
        }
      }

      &__item {
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }

  }
}
