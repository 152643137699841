.faq {
  $test: 1;

  .page-tabs__content-left {
    width: grid(5,1);
    padding-left: grid(0, 1);
  }

  .page-tabs__content-right {
    width: grid(5,1);
  }


  .frequently-mobile {
    display: none;
    margin-bottom: rem(60);
  }

  .frequently-desktop {
    display: block;
  }

  @include medium {
    .page-tabs__content-left {
      width: grid(6,1);
    }

    .page-tabs__content-right {
      width: grid(6,1);
    }

  }
  @include tablet-portrait {
    flex-direction: column;

    .page-tabs__page {
      flex-direction: column;
    }

    .page-tabs__content-left {
      width: 100%;
      padding-left: 0;
    }

    .page-tabs__content-right {
      width: 100%;
    }

    .frequently-mobile {
      display: block;
    }

    .frequently-desktop {
      display: none;
    }
  }
}
