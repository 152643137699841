.close__button {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: rem(4);
  @include style(typo-base-uppercase);

  i {
    transition: transform 150ms ease-in-out;
  }



  & > div {
    position: relative;
    display: block;
    overflow: hidden;
    width: max-content;
    margin-bottom: rem(-1);
    line-height: 0px;


    span {
      display: block;
      height: rem(32);
      opacity: 0;

    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: attr(data-content);
      transform: translateY(-50%);
      transition: all 416.66ms cubic-bezier(0.22, 1, 0.36, 1);
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      content: attr(data-content);
      opacity: 0;
      transform: translateX(30%) translateY(-50%);
      transition: all 416.66ms cubic-bezier(0.22, 1, 0.36, 1)
    }
  }

  @include hover {
    &:hover {
      i {
        transform: scale(0.93);
      }

      & > div {
        &::before {
          opacity: 0;
          transform: translateX(-30%) translateY(-50%);
        }

        &::after {
          opacity: 1;
          transform: translateX(0) translateY(-50%);
        }

      }
    }
  }

  @include tablet-portrait {
    div {
      display: none;
    }
  }
}

