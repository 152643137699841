.home-block-content {
  &__container {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    position: sticky;
    z-index: 1;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
  }

  &__inner {
    position: sticky;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(20);
    padding-top: 20%;
    color: var(--color-background);
    pointer-events: none;

    .dark & {
      color: var(--color-main);
    }
  }

  &__subtitle {
    // text-align: center;
    margin: 0;
    @include style(typo-base-body);


    .margin & {
      max-width: grid(4)
    }

    .medium & {
      margin-top: rem(3);
    }

    .large & {
      margin-top: rem(7);
    }
  }

  &__title {
    &.small {
      @include style(typo-display-uppercase);
      font-weight: 700;


    }

    &.medium {
      @include style(typo-display-m);
      margin-left: rem(-5);
      font-weight: 500;
    }

    &.large {
      @include style(typo-display-l);
      margin-left: rem(-10);
      font-weight: 500;
    }
  }

  &__button {
    z-index: 1;
    display: flex;
    width: grid(3);
    align-items: center;
    justify-content: center;
    margin-top: rem(20);
    font-size: rem(12);
    pointer-events: all;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

@include tablet-portrait {
  .home-block-content {
    &__wrapper {
      bottom: rem(20);
      height: 100%;
      min-height: rem(80);
      justify-content: center;
    }

    &__title {
      width: gridM(5);

      &.small {
        font-size: rem(22);
      }

      &.medium {
        margin-left: rem(-2);
        font-size: rem(46);
        line-height: 100%;
      }

      &.large {
        margin-left: rem(-4);
        font-size: rem(60);
        line-height: 100%;

      }
    }

    &__subtitle {
      .margin & {
        max-width: grid(6)
      }

      .medium & {
        margin-top: rem(2);

      }

      .large & {
        margin-top: rem(5);
      }
    }

    &__button {
      width: calc(var(--vw) - var(--grid-gutter) * 2);
      margin-top: var(--padding-m);
      font-size: rem(14);
    }
  }
}
