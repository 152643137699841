.catalog-footer {
  padding-bottom: rem(100);

  .facets {
    width: 100%;
    max-width: var(--vw);
    padding: rem(100)  0 var(--padding-m) 0;
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);
  }

  p {
    margin: 0;
    @include style(typo-base-body);
    color: var(--color-grey-medium);

    strong, a {
      color: var(--color-main);
    }

    a {
      @include basic-underline;
    }
  }

  .previous,
  .next {
    & .button__inner {
      gap: rem(5);
    }
  }

  &__text {
    width: grid(5, 2);
    padding: var(--padding-m) var(--padding-m) 0;
    color: var(--color-grey-medium);
    @include style(typo-base-body);
  }

  @include tablet-portrait {
    .facets {
      padding-top: var(--padding-l);

      ul {
        padding-top: var(--padding-m);
      }

    }

    &__text {
      width: 100%;
      padding-bottom: var(--padding-xxl);
    }
  }
}
