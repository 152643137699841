.text-button {
  width: grid(6);
  padding-left: grid(0,1);

  .panel & { width: auto; }

  &__title {
    @include style(typo-base-body);
    margin-bottom: var(--padding-m);
  }

  a {
    width: grid(4);
  }

  @include tablet-portrait {
    padding: grid(0,1);
    width: 100%;

    a {
      width: 100%;
    }
  }
}
