.page-header {
  display: flex;
  max-width: var(--vw);
  flex-direction: column;
  padding: var(--padding-m) var(--padding-m) 0 var(--padding-m);
  gap: var(--padding-mega);



  &__top {
    display: flex;
    flex-direction: column;
    gap: var(--padding-s);

    .no-bottom & {
      padding-bottom: var(--padding-m);
    }

  }

  &__title {
    display: flex;
    align-items: center;
    @include style(typo-display-uppercase);
    gap: var(--gap-s);

    .page-header__breadcrumb-first {
      color: var(--color-grey-medium);

      &:last-child {
        color: var(--color-main);
      }

      & + span {
      color: var(--color-grey-medium);

      }
    }

    h1 {
        text-wrap: nowrap;
        white-space:pre;
        word-wrap:break-word;

        i {
          position: absolute;
          top: 0;
          left: 0;
        }
    }

  }

  &__breadcrumbs-separator {
    width: rem(4);
    min-width: rem(4);
    max-width: rem(4);
    height: rem(4);
    flex-grow: 1;
    background-color: var(--color-grey-medium);
  }

  &__select {
    position: relative;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--color-main);
      gap: rem(8);
    }

    &-mask {
      position: relative;
      overflow: hidden;
      width: rem(20);
      height: rem(20);
      background-color: var(--color-grey-light);

      i:first-child {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    &-dropdown {
      position: absolute;
      z-index: calc(index('header') - 1);
      top: calc(100% + rem(16));
      left: 0;
      display: none;
      min-width: rem(290);
      flex-direction: column;
      gap: var(--gap-xxs);

      &.opened {
        display: flex;
      }

    }

    &-inner {
      background-color: var(--color-background);
      box-shadow: 0 rem(4) rem(40) 0 rgba(0 0 0 / 10%);

      ul {
        padding: var(--padding-m);
      }
    }

    &-title {
      @include style(typo-base-uppercase);
    }


    &-header {

      @extend .panel__header;
      display: none;
      align-items: center;
      justify-content: space-between;
    }

   &-list {
    display: flex;
    flex-direction: column;

    // gap: var(--gap-xxs);

    li {
      @include style(typo-base-uppercase);
      user-select: none;

      &:last-of-type {
        a {
          padding-bottom: 0;
        }
      }

      &:first-of-type {
        a {
          padding-top: 0;
        }
      }
    }


    a {
      display: block;
      padding: rem(2) 0;
      transition: color .06s ease-in-out;

      &.inactive {
        color: var(--color-grey-medium);
      }

      &.active {
        color: var(--color-main);
      }
    }

   }
  }

  &__desc {
    @include style(typo-base-body);
    width: grid(5);
    margin: 0 0 var(--gap-xl);
    color: var(--color-grey-medium);
    text-align: left;


    p {
      display: inline-block;
      max-height: 100%;
      margin: 0;
    }

    @include medium {
      width: grid(6);
    }

    &-inner {
        &:not(.opened &) {
          display: inline-block; /* Fallback for non-webkit */
      display: -webkit-box;
      overflow: hidden;
      width: 100%;
      max-height: calc(2 * 19.2px);
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
        }

      p {
        display: inline;
      }

      }

      &-truncated-first {
        display: inline;
      }

      &-truncated-last {
        display: none;
      }

      &-view--more, &-view--less {
        color: var(--color-grey-medium);

        &.visible {
          display: inline;
        }
      }

      &-actions {
        display: none;

        &.visible {
          display: block;
        }
      }

      &-view--less {
        display: none;
      }

      &.opened & {
        &-ellipsis {
          display: none;
        }

        &-view--more {
          display: none;
        }

        &-view--less {
          display: inline;
        }

        &-truncated-last {
          display: inline;
        }
      }
  }




  &__bottom {
    position: sticky;
    z-index: 10;
    top: calc(var(--header-height) - rem(10));
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-m) grid(0, 1);
    padding-bottom: var(--padding-s);
    padding-left: 0;
    border-bottom: var(--border-size) solid transparent;
    background: var(--color-white);
    gap: var(--padding-m);
    transition: all var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

    .editorial & {
      padding-bottom: var(--padding-m);
    }
    @include style(typo-base-uppercase);


    .scroll-down & {
      top: calc(var(--header-height-scrolled) - rem(10));
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }
  }

  &__facets {
    position: relative;
    overflow: hidden;
    width: 100%;


    &::after {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: grid(1);
      height: 100%;
      background: linear-gradient(to right, rgb(255 255 255 / 50%), var(--color-background));
      content: '';
      pointer-events: none;
    }

    ul {
      position: relative;
      display: flex;
      overflow: scroll hidden;
      gap: rem(16);
      @include hide-scrollbar;

      li {
        &:last-child {
          margin-right: grid(1);
        }
      }
    }
  }

  &__filters {
    display: flex;
    width: max-content;
    align-items: center;
    margin-left: auto;
    gap: var(--padding-m);

    &-button {
      display: flex;
      align-items: center;
      gap: var(--gap-xs);

      @include hover {
        &:hover {
            span {
              &::before {
                transform: scaleX(1);
                transition-delay: 0.15s;
              }

              &::after {
                transform: scaleX(0);
                transition-delay: 0s;

              }
            }
        }
       }

      span {
        position: relative;
        display: block;

        &::before {
          position: absolute;
          z-index: 2;
          bottom: rem(-2);
          left: 0%;
          width: 100%;
          height: rem(1);
          background-color: currentColor;
          background-image: unset;
          content: '';
          pointer-events: none;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform .3s $cubic-ease-out;
        }

        &::after {
          position: absolute;
          bottom: rem(-2);
          left: 0%;
          width: 100%;
          height: rem(1);
          background-color: currentColor;
          content: '';
          pointer-events: none;
          transform: scaleX(1);
          transform-origin: right;
          transition: transform .3s $cubic-ease-out;
          transition-delay: 0.15s;

        }

      }

      svg {
        transition: transform 150ms ease-in-out;
      }

      @include hover {
        &:hover {
        color: var(--color-grey-medium);


          svg {
            transform: scale(0.9);

            path {
              fill: var(--color-grey-medium);
            }

            circle {
              stroke: var(--color-grey-medium);
            }
          }
        }
      }
    }

    & > div {
      width: max-content;
      color: var(--color-grey-medium);
    }

  }

  &__terms {
    display: flex;
    margin-left: var(--padding-m);
    color: var(--color-grey-medium);
    gap: var(--gap-s);

    span {
      &:last-child {
        color: var(--color-main);
      }
    }
  }


  @include tablet-portrait {
    padding-right: 0;
    padding-left: 0;
    gap: rem(60);

    &__top {
      h1 {
        text-wrap: nowrap;
        white-space:pre;
        word-wrap:break-word;
      }

      .page-header__breadcrumb-first {
        text-wrap: nowrap;
        white-space:pre;
        word-wrap:break-word;

        &:not(.page-header__select-title) {
          margin-left: var(--padding-m);
        }
      }
    }

    &__desc {
      margin-bottom: rem(2);

      &-inner {
      padding: 0 var(--padding-m);

        &:not(.opened &) {
          display: inline-block; /* Fallback for non-webkit */
      display: -webkit-box;
      overflow: hidden;
      width: 100%;
      max-height: calc(2 * 19.2px);
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      text-overflow: ellipsis;
        }

      p {
        display: inline;
      }

      }

      &-truncated-first {
        display: inline;
      }

      &-truncated-last {
        display: none;
      }

      &-view--more, &-view--less {
        color: var(--color-grey-medium);

        &.visible {
          display: inline;
        }
      }

      &-actions {
        display: block;
        padding: 0 var(--padding-m);
      }

      &.opened & {
        &-ellipsis {
          display: none;
        }

        &-view--more {
          display: none;
        }

        &-view--less {
          display: inline;
        }

        &-truncated-last {
          display: inline;
        }
      }
    }

    &__title {
      overflow: scroll hidden;
      overscroll-behavior: none;
      @include hide-scrollbar;
    }

    &__title-wrapper {
      position: relative;


      &::before, &::after {
        position: absolute;
        z-index: 1;
        top: 0;
        display: block;
        width: grid(1);
        height: 100%;
        content: '';
        opacity: 0;
        pointer-events: none;
      }

      &.left {
        &::before {
          z-index: 1;
          left: 0;
          background: linear-gradient(to left, rgb(255 255 255 / 0%), var(--color-background));
          opacity: 1;
        }
      }

      &.right {
        &::after {
          z-index: 1;
          right: 0;
          width: grid(3);
          background: linear-gradient(to right, rgb(255 255 255 / 0%), var(--color-background) 75%);
          opacity: 1;
        }
      }
    }

    &__select {
      button {
        position: unset;
        align-items: flex-start;
      }

      h1 {
        display: flex;
        margin-right: var(--padding-m);
        text-align: left;
        text-wrap: nowrap;

        span {
          white-space:pre;
        word-wrap:break-word;
        }

      }

      i {
        display: inline-block;
      }

      &-header {
        display: flex;
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);
      }

      &-mask {
        position: sticky;
        z-index: 2;
        right: rem(20);
      }

      &-dropdown {
        position: fixed;
        z-index: calc(index('overlay-2') + 1);
        top: 0;
        left: 0;
        width: 100%;
        height: var(--ivh);
        pointer-events: none;
        user-select: none;
      }

      &-inner {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        pointer-events: all;
        transform: translateY(100%);
      }

      &-list {
          gap: rem(8);

          a {
            padding: 0;
          }
      }
    }

    &__filters {
      & > div {
        display: none;
      }
    }









    &__bottom {
      padding-left: 0;

      .editorial & {
        padding-bottom: var(--padding-m);
      }

      .facets {
        ul {
          gap: rem(14);
        }
      }
    }
  }

}
