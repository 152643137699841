@use 'sass:math';

.collection {
  &__wrapper {
    width: 100%;
    margin: 0 auto;
  }

  &__video {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(80) 0;

    iframe {
      width: grid(8,1);
      border: none;
      aspect-ratio: 16 / 9;
    }
  }

  &__intro {
    width: grid(6);

  }

  &__list {
    display: flex;
    flex-flow: wrap;
  }

  &__item {
    width: calc(100% / 3);
    flex-shrink: 0;
    aspect-ratio: 480 / 600;
    cursor: pointer;
    text-align: center;

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &-img {
      object-fit: cover;
      @include fullscreen;
      visibility: hidden;

      .reveal-show & {
        visibility: visible;
      }
    }

    &-position {
      @include style(typo-base-uppercase);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: fit-content;
      padding: grid(0, 1);
      filter: invert(100%);
      mix-blend-mode: difference;
      text-align: left;
    }
  }

  .collections {
    &__wrapper {
      overflow: hidden;
      max-width: var(--vw);
      padding: rem(100) 0 rem(20);
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }

    &__inner {
      display: flex;
      overflow: scroll hidden;
      width: 100%;
      gap: rem(16);

      &:not(.native) {
        cursor: grab;

        * {
          cursor: grab;
        }
      }

      &.grabbing {
        cursor: grabbing;

        * {
          cursor: grabbing;
        }
      }

      &.moving {
        a {
          pointer-events: none;
        }
      }

      &.native {
        .menu-pushes__inner {
          overflow: scroll hidden;

          .menu-pushes__push {
            &:first-child {
              margin-left: var(--grid-gutter);
            }
          }
        }
      }

      @include hide-scrollbar;
    }

    &__item {
      margin: 0;
      color: var(--color-grey-divider-light);
      cursor: inherit;
      transition: color .06s ease-in-out;
      white-space: nowrap;
      @include style(typo-display-uppercase);



      &.current {
        color: var(--color-main);
      }

      &:first-child {
        margin-left: grid(0, 1);
      }

      &:last-child {
        margin-right: grid(0, 1);
      }

      @include not-tablet-portrait {
        &:hover {
          color: var(--color-grey-medium);
        }
      }

    }
  }
}

@include tablet-portrait {
  .collection {
    &__intro {
      width: 100%;
      margin: 0;
    }

    &__list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      width: calc(100% / 2);
    }

    &__video {
      iframe {
        width: grid(6);
      }
    }

    .collections {
      &__wrapper {
        overflow: hidden;
        max-width: var(--vw);
        padding: rem(90) 0 var(--padding-m);
        margin-bottom: rem(60);
      }

      &__inner {
        gap: rem(20);
      }
    }
  }
}
