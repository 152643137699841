@font-face {
  font-display: swap;
  font-family: HelveticaNowDisplay;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
      url('../fonts/HelveticaNowDisplay-Bold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: PasswordDots;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/HelveticaNowDisplay-Bold.woff2') format('woff2'),
      url('../fonts/HelveticaNowDisplay-Bold.woff') format('woff');
  unicode-range: U+2022; /* Include both Bullet and Black Circle */

}

@font-face {
  font-display: swap;
  font-family: HelveticaNowDisplay;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/HelveticaNowDisplay-Medium.woff2') format('woff2'),
      url('../fonts/HelveticaNowDisplay-Medium.woff') format('woff');
}

