@use 'sass:math';

.product-gallery {
  position: relative;
  z-index: index('product-gallery');
  width: grid(6,1.5);



  &__list {
    position: relative;
    width: 100%;
  }

  &__item {
    > img, > video {
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    .appearing &:not(:nth-child(-n+2)) { //show only X first during transition
      display: none;
    }
  }

  &__pictures-track-wrapper {
    position: absolute;
    top: 0;
    left: grid(0, 1);
    height: 100%;
    padding-bottom: 50%;
  }

  &__pictures-track-wrapper-mobile {
    display: none;
  }

  &__pictures-track {
    position: sticky;
    z-index: 3;
    top: 50%;
    overflow: hidden;
    width: rem(43.6);
    max-height: calc(0.5 * var(--vh));
    transform: translateY(-50%);

    > li img, > li video {
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    &-inner {
      position: absolute;
      top: var(--zoom-thumb-top);
      left: 0;
      width: 100%;
      height: var(--zoom-thumb-height, 0);
      background: #001662;
      opacity: 0;
      pointer-events: none;
      transition: opacity 300ms ease-in-out;
    }
  }

  &__btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    & .product-gallery__btn-item {
      @include style(typo-base-uppercase-small-mobile);
      position: sticky;
      top: calc(100% - grid(0, 2));
      left: grid(0, 1);
      display: block;
      padding: rem(4) rem(6) rem(5);
      border-radius: var(--border-radius);
      margin-bottom: grid(0, 1);
      background-color: var(--color-background);
      pointer-events: all;
      text-transform: uppercase;
    }
  }

}

@include not-tablet-portrait {
  .product-gallery {
    &__list {
      transform: none !important;
    }
  }
}

@include tablet-portrait {
  .product-gallery {
    position: relative;
    overflow: hidden;
    width: 100%;

    &__pictures-track-wrapper-mobile {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: rem(2);
      background-color: rgb(249 249 245 / 30%);
    }

    &__pictures-track-mobile {
      width: var(--track-width);
      height: rem(2);
      background-color: var(--color-black);
    }

    &__pictures-track-wrapper {
      display: none;
    }

    &__list {
      display: flex;
      height:100%;
      flex-wrap: nowrap;
      padding-left: 0;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      @include hide-scrollbar;

      & li {
        scroll-snap-align: start;

      }
    }

    &__item {
      width: 100%;
      flex-shrink: 0;

      // @include keepRatio(math.div(375,515));

      & + & {
        margin-top: 0;
      }

      > img {
        object-fit: cover;
      }
    }
  }


}
