.checkout-popin {
  @include fullscreen(fixed);
  z-index: 300;
  display: flex;
  pointer-events: none;

  .appearing & {
    visibility: hidden;
  }

  .js & {
    &__wrapper, &__overlay, &__close {
      @include transition ('transform, opacity, visibility',.4s, $cubic-ease-out );
    }
  }

  &__wrapper {
    position: relative;
    width: oldGrid(10, 2);
    margin: rem(90) auto;
    background-color: var(--background);
    transform: translateY(-100vh);
  }

  &.opened & {
    &__wrapper {
      pointer-events: all;
      transform: none;
      visibility: visible;
    }

    &__close {
      visibility: visible;
    }

    &__overlay {
      opacity: .5;
      pointer-events: auto;
      pointer-events: all;
      visibility: visible;
    }
  }

  &__inner {
    --popin-height: calc(var(--vh) - #{rem(180)});
    position: relative;
    overflow: hidden auto;
    max-height: var(--popin-height);

    // transform: translateZ(0);
  }

  &__close {
    position: absolute;
    z-index: 100;
    top: oldGutters(.5);
    right: oldGutters(.5);
    visibility: hidden;
  }

  &__overlay {
    @include fullscreen;
    background-color: $black;
    opacity: 0;
  }
}

@include tablet-portrait {
  .checkout-popin {
    bottom: 0;

    &__wrapper {
      position: relative;
      width: gridM(6, 2);
      margin: 0;
    }

    &__inner {
      --popin-height: 100%;
      height: 100%;
    }

    &__close {
      top: rem(28);
      right: oldGuttersM(1.4);
      overflow: hidden;
      width: rem($header-height-m);
      background: no-repeat right/rem(12) url("../svg/close.svg");
      text-indent: 999em;
      white-space: nowrap;
    }
  }
}
