@use 'sass:math';

.editorial {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
  }

  .page-header__bottom {
    padding-right: 0;
    padding-bottom: rem(20);
  }

  &__item {
    &:hover {
      .editorial__item-description {
        color: var(--color-main);
      }
    }

    &-picture {
      width: 100%;
      aspect-ratio: #{math.div(480, 350)};
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-infos {
      display: flex;
      flex-direction: column;
      padding: var(--padding-m);
      padding-bottom: var(--padding-xl);
      gap: var(--grid-gutter);
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: rem(6);
    }

    &-tag {
      width: max-content;
      padding: rem(3) rem(6) rem(4);
      background-color: var(--color-grey-light);
      font-size: rem(10);
      font-weight: bold;
      text-transform: uppercase;
    }

    &-title {
      @include style(typo-display-uppercase-s);
    }


    &-description {
      @include style(typo-base-body);
      color: var(--color-grey-medium);
      transition: color var(--opacity-duration) ease-in-out;
      word-wrap: break-word;

    }
  }
}

@include medium {
  .editorial {
    &__list {
      grid-template-columns: repeat(2, 50%);
    }
  }
}

@include tablet-portrait {
  .editorial {
    &__list {
      grid-template-columns: repeat(1, 100%);
    }

    &__item {
      &-infos {
      padding-bottom: var(--padding-xxl);
      }

      &-body {
      gap: rem(8);
      }

      &-tag {
        font-size: rem(12) !important;
      }
    }

  }
}

