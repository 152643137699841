@use 'sass:math';

.article-video {
  width: grid(8);

  .player {
    display: inline-block;
    width: 100%;
    margin: auto;
  }

  video {
    width: 100%;
    height: 100%;
  }

  @include tablet-portrait {
    width: grid(6);

    video {
      width: 100%;
      height: auto;
    }
  }
}
