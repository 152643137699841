.checkout-success {
  @extend %typo-base-body;

  &__intro {
    @extend %typo-base-body;
    width: grid(7, 2);
    padding-right: grid(1, 2);
    padding-left: grid(1, 2);

    &-header {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: rem(52);
      margin-bottom: var(--padding-m);

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(32);
        height: rem(32);
        border: rem(.5) solid var(--color-green);
        transform: translateY(-50%);
      }
    }

    &-order {
      color: var(--color-main);
    }

    &-text {
      margin-bottom: rem(80);
      color: var(--color-grey-medium);

      p {
        margin-top: 0;
        margin-bottom: 0;
      }

      a {
        color: var(--color);
      }

      span {
        color: var(--color-black);
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: var(--padding-m);
  }

  &__contact {
    color: var(--color-grey-medium);
    text-align: left;
    @extend %typo-base-uppercase;

    span {
      color: var(--color-black);
    }
  }

  .recap__step {
    padding: var(--padding-m);
  }

  &-breadcrumb__step {
    pointer-events: none;
  }

  &__intro-subtitle {
    margin-bottom: var(--padding-s);
    color: var(--color-grey-medium);
  }
}

@include tablet-portrait {
  .checkout-success {
    &__intro {
      width: 100%;
      order: -1;
      padding: rem(10) var(--padding-m) rem(60) var(--padding-m);
      margin: 0;


      &-text {
        margin: 0;


      }

      &-inner {
        width: auto;
        margin-left: 0;
      }
    }

    &__buttons {
      flex-direction: column;
    }

    &__resume {
      width: 100%;
    }

    &__footer__inner {
      padding-bottom: rem(72.5);
    }

    .recap__step {
      padding: var(--padding-m);
      gap: rem(8);
    }

    .icon--hidden {
    }

    .recap__content {
      margin-left: 0;
    }
  }
}
