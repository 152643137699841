@use 'sass:math';

.article-gallery {
  position: relative;
  width: grid(10);
  margin-bottom: 0 !important;


  &__inner {
    display: grid;
    gap: grid(1);
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: var(--gap-s);

    span {
      @include style(typo-base-uppercase);
    }

    &-img {
      width: 100%;
    height: auto;
    aspect-ratio: #{math.div(217.5, 277)};


      > img, > video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }


    &-popin {
      @extend .collection-popin;

      &.opened & {
        &__wrapper, &__header {
          pointer-events: auto;
          transform: none;
        }

        &__header {
          opacity: 1;
        }
      }

      .desktop {
        display: flex;
      }

      .mobile {
        display: none;
      }

      &_wrapper, &__header {
        position: fixed;
        z-index: index('overlay-3');
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none
      }

      &__products {
        @extend .collection-popin__products;


        &-wrapper {
          @extend .collection-popin__products-wrapper;
          background-color: var(--color-background);
        }

        &-item {
         @extend .collection-popin__products-item;

          .swiper {
            display: none;
          }

          .row-product-card {
            &:last-child {
              border-bottom: none;
            }

            &__img {
              img {
                width: grid(0, 3);
              }
            }

            &__top {
              max-width: grid(1, 4.5);
              gap: rem(3);
            }

            & .price {
              align-items: flex-start;
            }
          }
        }
      }

      &__header {
        @extend .collection-popin__header;

        &-step {
          @extend .collection-popin__header-step;
        }

        &-close {
          @extend .collection-popin__header-close;
        }
      }

      &__wrapper {
        @extend .collection-popin__wrapper;
      }

      &__image {
        height: 100%;
        margin: 0 auto;

        img {
          height: 100%
        }


      }

      &__arrows {
        @extend .collection-popin__arrows;

        & .disabled {
          opacity: .5;
          pointer-events: none;
          transition: opacity .3s linear;
        }

        & .article-gallery__item-popin__arrow {
          display: flex;
          align-items: center;
          gap: rem(5);

          span {
            @include style(typo-base-uppercase);

          }
        }
      }


    }



  }

  @include tablet-portrait {
    width: grid(6);

    &__inner {
        gap: var(--grid-gutter);
        grid-template-columns: repeat(2, 1fr);
      }

    &__item {
      width: grid(3);

      &-popin {
        &__image {
          width: 100%;
          height: auto;
          margin: auto;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
