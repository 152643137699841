.seo-links {
  display: flex;
  flex-wrap: wrap;
  gap: grid(0,1);
  padding: grid(0,1);

  &__item {
    width: grid(4);
    padding: var(--padding-m);
    border: var(--border-size) solid var(--color-grey-divider-light);
    padding-bottom: var(--padding-xl);




    &-title {
      color: var(--color-grey-medium);
      margin-bottom: rem(10);
      @include style(typo-base-uppercase)
    }
  }
}

@include tablet-portrait {
  .seo-links {
    flex-direction: column;


    &__item {
      width: 100%;


    }
  }
}
