.price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--gap-xxxs);

  &__discount {
    display: flex;
    gap: var(--gap-xxs);
  }

  del, var, ins, & > * {
    white-space: nowrap;
  }

  del { color: var(--color-grey-medium); }

  var {
    color: var(--color-grey-medium) !important;
    font-style: normal;
  }

  ins {
    color: $red !important;
    text-decoration-line: none;
  }

  del + ins { margin-left: rem(8); }

  // var + ins { color: inherit !important; }

  @include tablet-portrait {
    .product-card & {
      line-height: remMin(18);

      del, var, ins {
        display: inline-block;
      }
    }

    del + ins { margin-left: rem(4); }

  }
}
