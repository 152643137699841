.home__search {
  position: relative;
  z-index: calc(index('header') - 1);
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 0;
  color: var(--color-main);

  .search-form {
    padding: 0;
  }

  .search-form__group {
    height: 100%;
    padding: 0;
  }

  &-inner {
    padding-top: var(--header-height);
    padding-right: grid(0,0.5);
    padding-left: grid(0,0.5);
  }

  button, &-input {
    display: flex;
    width: 100%;
    height: rem(38);
    align-items: center;
    border-radius: var(--border-radius);
  }


  .header__transparent & {
      color: var(--color-grey-light);

      .field__inner {
        background-color: rgb(247 247 247 / 32.8%);
      }

      input {
        color: var(--color-grey-light);

        &::placeholder {
          color: var(--color-grey-light);
        }
      }
    }






  .header__light & {
    .icon {
      filter: invert(0%);
      opacity: 0.8;
    }

      .field__inner {
        background-color: rgb(247 247 247 / 20%) !important;

      }

      button, &-input {
        color: var(--color-main);



        input {
          color: var(--color-main);


          &::placeholder {
            color: var(--color-main) !important;
          }

          &:focus {
            color: var(--color-main) !important;
          }
      }


    }
  }

    .header__dark & {
        .icon {
          filter: invert(100%);
          opacity: 0.8;
        }

        .field__inner {
          background-color: rgb(247 247 247 / 30%) !important;

        }

          button, &-input {
            color: var(--color-background);

            input {
              color: var(--color-background);

              &::placeholder {
                color: var(--color-background) !important;
              }

              &:focus {
                color: var(--color-background) !important;
              }
          }
    }
  }


  .header__solid & {
      color: var(--color-grey-medium);

      &-inner {
        padding-bottom: var(--padding-s);
      background-color: var(--color-background);

      }

      button, &-input {
        background-color: #F7F7F7;
      }

    .icon {
      filter: invert(0%);
      opacity: 0.433;
    }
}

@include tablet-portrait {
  display: block;

}
}
