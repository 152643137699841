.brand-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--padding-s);
  width: grid(8);
  margin-top: var(--padding-mega);
  margin-bottom: var(--padding-mega);

  &__title {
    font-size: rem(32);
    line-height: rem(32);
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-main);

    &::before {
      content: '“';
    }
    &::after {
      content: '”';
    }
  }

  &__sub {
    @include style(typo-base-uppercase);
    color: var(--color-grey-medium);
  }
}

@include tablet-portrait {
  .brand-title {
    width: grid(6);
    align-items: flex-start;
    margin-top: rem(90);
    margin-bottom: rem(90);

    &__title {
      font-size: rem(28);
      line-height: rem(28);
      text-align: left;
    }
  }
}
