.button {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: rem(15) rem(20);
  text-decoration: none;
  @include style(typo-base-uppercase);

  &[disabled="disabled"] {
    cursor: not-allowed;
    opacity: .5;
  }


  &__transparent {
    border: none;
    background-color: transparent;

    &.button__dark {
      color: var(--color-main);
    }

    &.button__light {
      color: var(--color-background);
    }
  }


  &__outline {
    border: rem(0.5) solid;
    background-color: transparent;

    &.button__dark {
      border-color: var(--color-main);
      color: var(--color-main);
    }

    &.button__light {
      border-color: var(--color-background);
      color: var(--color-background);
    }
  }

  &__inner {
    display: flex;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;

    &::before {
      pointer-events: none;
    }

    .add-to-cart-btn & {
      span {
      z-index: 2;
      mix-blend-mode: difference;

      }

      &::after {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-main);
        content:'';
        transform: scaleX(var(--loading-progress, 0));
        transform-origin: left;
      }

      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        content: var(--loading-text);
        mix-blend-mode: difference;
        opacity: 0;
        transform: translate(-50%, -100%);
        transition: all .3s ease-in-out;
    }


    }

    .add-to-cart-btn.complete & {
      .button__text-inner[data-hover] {
        span {
          opacity: 0;
          transform: translateY(100%);
        }

        &::after {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    }

    .no-transition & {
      * {
        transition: none;
      }

      &::before {
        transition: none;
      }

      &::after {
        transition: none;
      }

      .button__text-inner[data-hover] {
        &::after {
        transition:none;

        }
      }
    }


    .button:not(.button__has-aside, .add-to-cart-btn) & {
      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 50%;
        width: rem(20);
        height: 100%;
        background-image: url('../icons/loader.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        content: '';
        opacity: 0;
        transform: translate(-50%, -100%);
        transition: all .3s ease-in-out;
    }
    }

    .button.loading:not(.add-to-cart-btn) & {
      &::before {
        opacity: 1;
        transform: translate(-50%, 0);
      }
    }
  }

  &__reversed {
    .button__inner {
      flex-direction: row-reverse;
    }
  }

  &__square {
    width: rem(50);
    height: auto;
    padding: 0;
    aspect-ratio: 1;
  }

  &__slim-fit {
    width: auto;
  }

  &__grey {
    border-color: var(--color-grey-divider-light);
    color: var(--color-grey-medium);
  }

  &__grey-border {
    border-color: var(--color-grey-divider-light);
  }

  &__aside {
    position: relative;
    margin-left: auto;

    &-inner {
      transition: all .3s ease-in-out;
    }

    .button.button__has-aside & {
      &::before {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: rem(20);
        height: 100%;
        background-image: url('../icons/loader.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        content: '';
        opacity: 0;
        transform: translate(0%, -100%);
        transition: all .3s ease-in-out;
    }
    }

    .button.loading & {
      &-inner {
        opacity: 0;
        transform: translateY(100%);
      }

      &::before {
        opacity: 1;
        transform: translate(0%, 0);
      }
    }
  }



  &__solid {
    border: rem(1) solid transparent;

    &.button__dark {
      background-color: var(--color-main);
      color: var(--color-background);

      &.loading {
        border: rem(1) solid var(--color-main);
        background-color: var(--color-background);
      }
    }

    &.button__light {
      background-color: var(--color-background);
      color: var(--color-main);
    }
  }

  &__underline {
    position: relative;
    display: block;
    overflow: visible;
    width: max-content;
    height: auto;
    padding: 0;
    border: none;
    background-color: transparent;

    &::before {
      position: absolute;
      z-index: 2;
      bottom: rem(-2);
      left: 0%;
      width: 100%;
      height: rem(1);
      background-color: currentColor;
      background-image: unset;
      content: '';
      pointer-events: none;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform .3s $cubic-ease-out;
    }

    &::after {
      position: absolute;
      bottom: rem(-2);
      left: 0%;
      width: 100%;
      height: rem(1);
      background-color: currentColor;
      content: '';
      pointer-events: none;
      transform: scaleX(1);
      transform-origin: right;
      transition: transform .3s $cubic-ease-out;
      transition-delay: 0.15s;

    }

      &.button__dark {
        color: var(--color-main);

        &::after {
          background-color: currentColor;

        }
      }

  }

  &__text {
   &-wrapper {
    position: relative;
    display: block;
    overflow:hidden;
    width: 100%;
    transition: transform .3s ease-in-out;

    :not(.button__has-aside, .add-to-cart-btn).button.loading & {
      transform: translateY(100%);
    }
   }


    &-inner {
      position: relative;
      display: block;
      text-align: center;
      transition: transform .4s $cubic-ease-in-out, opacity .4s $cubic-ease-in-out;

      .button.button__has-aside & {
        text-align: left;
      }


      span {
        display: block;

        &.inline {
          display: inline;
        }
        transition: transform .4s $cubic-ease-in-out, opacity .4s $cubic-ease-in-out;
      }

      &[data-hover] {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          content: attr(data-hover);
          opacity: 0;
          pointer-events: none;
          transform: translateY(-100%);
          transition: transform .4s $cubic-ease-in-out, opacity .4s $cubic-ease-in-out;
          user-select: none;


          .button.button__has-aside & {
           justify-content: flex-start;
          }
        }

        .not-valid &[data-invalid] {
            &::after {
              width: 100%;
              content: attr(data-invalid);
              }

        }
      }
    }


  }


  &__no-style {
    border: none;
    background-color: transparent;
  }

  &__toggle {
    position: absolute;
    top: 0;
    left: 50%;
    pointer-events: none;
    text-align: center;
    transform: translateX(-50%) translateY(-100%);
    transition: transform .4s $cubic-ease-in-out, opacity .4s $cubic-ease-in-out;

  }

  &.toggle {
    .button__toggle {
      opacity: 1;
      pointer-events: all;
      transform: translateX(-50%) translateY(0%);
    }

    .button__text-inner {
      opacity: 0;
      transform: translateY(100%);
    }
  }



&__hover-classic {
&:not(.loading, [disabled]) {
  @include hover {
    &:hover {
      .button__text {
        &-inner {
          span {
            opacity: 0;
            transform: translateY(100%);
          }

          &[data-hover] {
            &::after {
              opacity: 1;
              transform: translateY(0%);
            }
          }

        }


      }
    }
  }
}
}

&__hover-line {
   @include hover {
    &:hover {
        &::before {
          transform: scaleX(1);
          transition-delay: 0.15s;
        }

        &::after {
          transform: scaleX(0);
          transition-delay: 0s;

        }
    }
   }
}
}


apple-pay-button {
  --apple-pay-button-width: 150px;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 3px;
  --apple-pay-button-padding: 0px 0px;
  --apple-pay-button-box-sizing: border-box;
  display: flex;
  width: 100%;
  height: rem(44);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: var(--border-size) solid var(--color-main);
}
