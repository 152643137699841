@import './styling';

@mixin button-rect {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: rem(50);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0;
  background: $dark;
  color: $white;
  cursor: pointer;
  @include transition('background, opacity, border-color, color', .3s, linear);

  &[disabled], &.disabled {
    pointer-events: none;
  }

  form.submitting & {
    cursor: wait;
    opacity: .8;
  }

  .button-rect__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    @include fullscreen;
  }

  &[data-hover] {
    &::after, .button-rect__inner {
      transition: transform .4s $cubic-ease-in-out, opacity .4s $cubic-ease-in-out;
    }

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;
      content: attr(data-hover);
      opacity: 0;
      pointer-events: none;
      transform: translateY(-30%);
      user-select: none;
      @include fullscreen;
    }

    &.active {
      &::after {
        opacity: 1;
        transform: none;
      }

      .button-rect__inner {
        opacity: 0;
        transform: translateY(50%);
      }
    }

    @include hover {
      &:hover, .submitted & {
        &::after {
          opacity: 1;
          transform: none;
        }

        .button-rect__inner {
          opacity: 0;
          transform: translateY(50%);
        }
      }
    }
  }
}

.button-rect {
  @include button-rect;

  @include hover {
    &:hover {
      background: $dark-grey;
    }
  }

  &[disabled], &.disabled, .not-valid & {
    color: rgb(255 255 255 / 35%);
  }
}

.button-rect-light {
  @include button-rect;

  // height: rem(49);

  &, &[type='submit'] {
    border: 1px solid var(--color);
  }
  background-color: var(--background);
  color: var(--color);

  @include hover {
    &:hover {
      background-color: $dark-grey;
      color: var(--background);
    }
  }

  &.active {
    background-color: $dark-grey;
    color: var(--background);
  }

  &[disabled], &.disabled, .not-valid & {
    background-color: var(--background);
    color: rgb(0 0 0 / 30%);

    &, &[type='submit'] {
      border: 1px solid rgb(0 0 0 / 10%);
    }
  }
}

.button-rect-grey {
  @include button-rect;

  &, &[type='submit'] {
    border: 1px solid $border-color;
  }
  background-color: var(--background);
  color: var(--color);
  @include hover {
    &:hover {
      background-color: $dark-grey;
      color: var(--background);
    }
  }

  &[disabled], &.disabled, .not-valid & {
    background-color: var(--background);
    color: rgb(0 0 0 / 30%);

    &, &[type='submit'] {
      border: 1px solid rgb(0 0 0 / 10%);
    }
  }

  // &[disabled], &.disabled {
  //   color: var(--color-grey-medium)
  // }
}

.button-underlined {
  position: relative;
  color: var(--color);

  &::after {
    position: absolute;
    bottom: -.3em;
    left: 0;
    width: 100%;
    height: 1px;
    backface-visibility: hidden;
    background: var(--color);
    content: '';
    opacity: 1;
    pointer-events: none;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .3s $cubic-ease-out;
  }

  &.grey {
    --color: #{$grey};
  }

  .desktop &:hover, &.current {
    &::after {
      transform: scaleX(1.001);
      transform-origin: left;
    }
  }

  &.reversed, &-reversed {
    &::after {
      transform: scaleX(1.001);
      transform-origin: left;
    }

    .desktop &:hover {
      &::after {
        transform: scaleX(0);
        transform-origin: right;
      }
    }
  }
}

@include tablet-portrait {
  .button-rect, .button-rect-grey, .button-rect-light {
    height: rem(50);
  }
}
