.checkout {
  &.page {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    padding: 0;
    background-color: var(--background);

    &.appearing, &.disappearing {
      overflow: visible;
      contain: none;
    }
  }


  [data-step-name] {
    scroll-margin-top: calc(var(--header-height-scrolled) + rem(115) );
  }

  .internal-page {
    height: max-content;
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  &__message {
    display: flex;
    flex-direction: column;
    padding: var(--padding-m);
    margin-bottom: var(--padding-m);
    background-color: var(--color-grey-light);
    gap: var(--padding-s);

    .checkout-personal-details & { margin: rem(-40) 0 rem(40); }

    &-title {
      @include style(typo-base-uppercase);
    }

    &-text {
      @include style(typo-footnote-l);
      margin: 0;
      color: var(--color-grey-medium);
    }

    &-button {
      text-decoration: underline;
    }

    a { text-decoration: underline; }
  }

  &__logo {
    width: auto;
    height: rem(10);

    & + & {
      margin-left: rem(8);
    }
  }

  &__wrapper {
    // display: flex;
    // justify-content: flex-end;
    min-height: calc(var(--vh) - var(--header-top));
    border-bottom: rem(.5) solid var(--color-grey-divider-light);
  }

  &__inner, &__step {
    width: grid(7, 2);
  }

  &__inner {
    position: relative;
    padding-bottom: rem(160)
  }

  &__step {
    padding-right: grid(1, 2);
    padding-left: grid(1, 2);
  }

  &-footer {
    background: var(--background);
  }

  &__summary {
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;
    width: grid(5, 1);
    min-height: calc(var(--vh) - var(--header-top));
    border-left: 1px solid $border-color;
    margin-top: rem(-$header-height);
    background: var(--background);
    float: right;
    @include overlay-children;

    .panel__page {
      .page__inner {
        padding: 0;
      }
    }
  }

  &__form-title {
    margin-top: rem(80);
    margin-bottom: rem(40);
    @include style(typo-base-body);

    // scroll-padding-top: rem(40);

    span {
      display: inline-block;
      margin-left: rem(2);
      color: var(--color-grey-medium)
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: rem(16);

    --split-margin: #{oldGutters(.25)};

    & h2 {
      @include style(typo-base-body);
      margin-bottom: rem(40);
    }

    & > .button {
      margin-top: rem(24);

      &__guest {
        position: absolute;
        right: grid(1, 2);
        bottom: 0;
        margin-top: 0;

        & .button__text-wrapper {
          border-bottom: none;
        }
      }
    }

    &__inline {
      margin-bottom: rem(24);
    }

    &.not-valid {
      & .button__solid {
        color: var(--color-grey-medium);
      }
    }

    & .field__show {
      margin-top: 0;
    }

    & .login__reset-password {
      @include style(typo-base-uppercase);
      color: var(--color-grey-medium);

      &-wrapper {
        width: 100%;
        margin-top: rem(-20);
        text-align: right;
      }
    }
  }

  .social-login {
    margin-top: rem(4);

    & > span {
      @include style(typo-base-uppercase);
      display: flex;
      color: var(--color-grey-medium);
      gap: rem(3);
      white-space: nowrap;

      & button {
        @include style(typo-base-uppercase);
        border: 0;
        color: var(--color-black);
      }
    }


  }


  &__identity-form {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  .add-to-cart-btn {
    margin-top: rem(20);
    transition: opacity .2s ease-in-out;


  }
}

@include not-tablet-portrait {
  .checkout {
    &__wrapper {
      .checkout-summary {
        .checkout-footer {
          display: flex;
        }
      }
    }

    .checkout-footer{
      display: none;
    }
  }


}


.paynow-hidden {
  opacity: 0 !important;
}

@include tablet-portrait {
  .checkout {
    [data-step-name] {
      scroll-margin-top: calc(var(--header-height-scrolled) + rem(60) );
    }

    &.page {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      padding-left: 0;
    }

    &__wrapper {
      display: flex;
      min-height: unset;
      flex-direction: column;
      border-bottom: 0;

      // min-height: var(--available-height);
    }


    [data-name=" checkout-success "] {
      min-height: auto;
      margin-bottom: 0;

      .checkout__inner {
        padding-bottom: 0;
      }

      .checkout-footer {
        display: none;
      }
    }

    &__inner {
      width: 100%;
      padding-bottom: rem(120);
      margin-top: 0;
    }

    &__step {
      width: 100%;
      padding: 0 var(--padding-m) 0 var(--padding-m);
      margin: auto;
    }

    &__footer {
      margin-top: 0;
    }

    .checkout-breadcrumb {
      order: -1;
    }

    &__summary {
      position: fixed;
      z-index: 300;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: rem(0);
      margin-top: 0;
      background: transparent;
      pointer-events: none;

      &.opened {
        pointer-events: auto;
      }

      .panel__page {
        min-height: auto;

        .page__inner {
          padding: 0;
        }
      }
    }

    &__form-title {
      margin-top: rem(60);
      font-size: rem(16);

      span {
        display: block;
        margin-top: rem(4);
        margin-left: 0;
        line-height: remMin(18);
      }
    }

    &__message {
      color: var(--color-grey-medium)
    }

    .checkout-personal-details  {
      .form {
      gap: rem(20);

        &>button {
          margin-top: rem(10);
        }
      }


    }

    .form {
      gap: rem(0);

      --split-margin: #{oldGuttersM(.5)};

      & h2 {
        margin-bottom: rem(30);
      }

      & > .button {
        margin-top: rem(30);

        &__guest {
          position: relative;
          right: 0;
          bottom: 0;
          margin-top: rem(20);
        }
      }

      &__inline {
        margin: rem(0) 0 rem(30);
      }

      & .form__split {
        & .field {
          margin-top: 0;
        }
      }

      .login__reset-password {
        &-wrapper {
          // margin-top: rem(0);
        }
      }
    }

    .social-login {
      flex-direction: column;
      align-items: flex-start;

      &>span {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;

        button {
          width: max-content;
        }
      }
      gap: var(--padding-m);

    }
  }
}
