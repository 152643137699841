.search-results {
  padding: 0;

  &__empty {
    padding-top: rem(80);
  }

  &__list {
    @extend .product-list;
  }

  .search-form {
    padding-top: rem(28);
    border-top: 0;


  }

  .product-related {
    margin-top: rem(160);
  }

  &.empty {
    .page-header__bottom {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }


  }

  @include tablet-portrait {
    .search-form {
      padding: rem(10);
    }

  }
}

