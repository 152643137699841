.home-category {
    // height: var(--ivh);
    color: var(--color-main);
    position: relative;

    &__inner {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    &.has-space {
      padding: var(--padding-m) 0;
    }
}



@include tablet-portrait {
  .home-category {
    &__inner {
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      @include hide-scrollbar;

      .category-card {
        flex-shrink: 0;
      }
    }

    // &__card {
    //   width: calc(50% - rem(10));
    //   aspect-ratio: 158 / 214;
    //   height: auto;
    //   object-fit: cover;

    //   &:last-child {
    //     display: none;
    //   }
    // }

  }
}
