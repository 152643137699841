/* stylelint-disable color-no-hex */
// -------
// colours
// -------

$white: #fff;
$black: #000;

$red: #D60000;
$orange: #ec4926;
$dark-red: #a00;
$green: #356029;
$blue: #2b709b;
$green-valid: #2ac200;
$error: $red;
$grey: #a6a19d;
$grey-hover: #444;
$dark-grey: #222;
$light: #cecece;
$white-hover: #fdfdfd;
$border-color: rgba(0, 0, 0, .05);
$overlay-color: rgba(0, 0, 0, .6);

$background: $white;
$alt-background: #fafafa;
$dark: $black;

// -------
// sizes
// -------

$width-reference: 1440;
$width-reference-m: 375;
$rem-value: var(--rem);


$icon-size: 24px;
$header-height: 90px;
$header-height-m: 68px;
$notification-height: 36px;
$notification-height-m: 32px;

$header-link-animation: 20px;

$dark-mode-transition: .2s;

// -------
// export
// -------

/* stylelint-disable */
:export {
  widthReference: $width-reference;
  widthReferenceM: $width-reference-m;
}
