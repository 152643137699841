.drawer-panel {
  overflow: hidden;

  .no-js & {
    height: auto;
  }

  .js & {
    transition: height .4s $cubic-ease-in-out;
  }

  &.opened {
    height: var(--drawer-height);
  }
}
