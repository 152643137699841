.notification {
  position: relative;
  z-index: index('overlay-1');
  max-width: var(--vw);
  height: var(--notification-height);
  border-bottom: 1px solid $border-color;
  background-color: var(--background);
  color: var(--color-grey-medium);
  font-family: $main-fontname;
  font-size: rem(10);
  font-weight: bold;
  line-height: 140%;
  overflow-x: hidden;
  text-transform: uppercase;

  .no-quick-access & {
    display: none;
  }

  &:not(.notification--dark) {
    background-color: var(--color-background);
    color: var(--color-main);
  }

  &--dark {
    background-color: var(--color-main);
    color: var(--color-background);
  }



  &__inner {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    grid-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
  }

  &__texts {
    position: relative;
    display: inline-flex;
    height: 100%;
    grid-area: 1 / 4 / 2 / 10;


    a {
      position: absolute;
      top: 50%;
      width: 100%;
      opacity: 0;
      pointer-events: none;
      text-align: center;


      &.active {
        pointer-events: auto;
      }
    }
  }

  &__links {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: grid(0, 1);
    font-size: rem(12);
    gap: var(--padding-m);
    grid-area: 1 / 10 / 2 / 13;
    text-transform: initial;

    a, button {
      @include hover {
        transition: color var(--opacity-duration) ease-in-out;

        &:hover {
          color: var(--color-grey-medium);
        }
      }
    }
  }

  a {
    display: inline;
    text-underline-offset: rem(3);
  }

  u {
    text-underline-offset: rem(3);
  }
}

@include tablet-portrait {
  .notification {
    &__links {
      display: none;
    }

  &__texts {
    grid-area: 1 / 1 / 2 / 13;
  }
  }
}
