.editorial-related-content {
  padding: rem(80) 0;

  .article & {
    padding-top: 0;
    padding-bottom: rem(120);

  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-xl) var(--padding-s) var(--padding-m) var(--padding-m);
    gap: rem(10);
  }

  &__title {
    @extend %typo-display-uppercase;
  }

  &__navigation {
    display: flex;

    button {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }
    }
  }

  &__list {
    display: flex;
  }

  &__body {
    @extend .editorial__item-body;

  }

  &__item {
      width: grid(3, 4);
      flex-shrink: 0;
      scroll-snap-align: start;
      @extend .editorial__item;

      &-picture {
       @extend .editorial__item-picture;

       img {
         @extend .editorial__item-img;
       }
      }

      &-img {
        @extend .editorial__item-img;
      }

      &-infos {
        @extend .editorial__item-infos;
      }

      &-tag {
        @extend .editorial__item-tag;
      }

      &-title {
        @extend .editorial__item-title;
      }

      &-description {
        @extend .editorial__item-description;
      }
  }

  @include tablet-portrait {
    padding-top: rem(90);
    padding-bottom: rem(60);

    &__header {
      padding: var(--padding-m);
      padding-top: var(--padding-l);
    }

    &__navigation {
      display: none;
    }

    &__item {
      width: grid(5, 1);

      &-tag {
        font-size: rem(12) !important;
      }
    }
  }
}
