.article-text-image {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-m);

  &__wide {
    padding: 0;

    .article-text-image__inner {
     border: 0;
     gap: unset;
    }

    .article-text-image__image{
      width: grid(6, 1.5);
    }

    .article-text-image__text {
      width: grid(6, 1.5);
      padding-left: grid(1, 1.5);
    }
  }

  &__inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    border: var(--border-size) solid var(--color-grey-divider-light);

    .rtl & {
      flex-direction: row-reverse;
    }
  }

  &__image {
    width: grid(6, .5);
    height: var(--vh);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }


  &__text {
    display: flex;
    width: grid(6, .5);
    flex-direction: column;
    word-wrap: break-word;

    .ltr & {
      padding-left: grid(1, 1.5);
    }

    .rtl & {
      padding-left: grid(1, 1);
    }

    h2, div {
    width: grid(4);
    text-wrap: wrap

    }

    & > div {
      @include style(typo-base-body);
      color: var(--color-grey-medium);


    }

  }

  h2 {
    @include style(typo-display-uppercase);
    width: grid(4);
    margin-bottom: rem(10);
  }

  h3 {
    @include style(typo-display-uppercase);
    width: grid(4);
    margin-top: rem(30);
    margin-bottom: rem(30);
    color: var(--color-black);
  }




  @include tablet-portrait {
    &__inner {
      flex-direction: column;
      gap: unset;

      .rtl & {
        flex-direction: column;
      }
    }

    &__wide {
      .article-text-image__image {
        width: 100%;
      }

      .article-text-image__text {
        width: 100%;
      }
    }

    &__text {
      padding: var(--padding-m) var(--padding-m) var(--padding-xxl) var(--padding-m) !important;

      div {
        width: 100%;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }


    h2 {
      width: 100%;
      align-self: flex-start;
    }

    p {
      width: 100%;
      align-self: flex-start;

    }
  }



}
