.checkout-pending {
  &__intro {
    @extend .checkout-success__intro;
    padding-top: rem(80);

    &-header {
      @extend .checkout-success__intro-header;

      .icon {
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(32);
        height: rem(32);
        border: rem(.5) solid var(--color-orange) !important;
        background-position: center;
        transform: translateY(-50%);
      }
    }

    & &-header::before {
      border: 1px solid $orange;
      background-image: url("../svg/pending.svg");
    }

    &-order {
      @extend .checkout-success__intro-order;
    }

    &-subtitle {
      @extend .checkout-success__intro-subtitle;
    }

    &-text {
      @extend .checkout-success__intro-text;
    }
  }

  &__buttons {
    @extend .checkout-success__buttons;
  }

  &__resume, &__restart {
    @extend .checkout-success__resume;
  }

  &__contact {
    @extend .checkout-success__contact;
  }
}
