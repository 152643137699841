.definitions {
  @include not-tablet-portrait {
    .page__title {
      position: sticky;
      bottom: oldGutters(1);
      left: oldGutters(1);
      align-self: flex-start;
      order: 999;
      margin: 0;
    }

    &.appearing .page__title {
      position: fixed;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__letter {
    display: flex;
    width: oldGrid(6);
    margin: 0 auto;

    &:first-child {
      margin-top: rem(180);
    }

    & + & {
      margin-top: oldGutters(3);
    }

    &-inner {
      display: flex;
      flex-basis: oldGrid(1, 1);
      flex-shrink: 0;
    }
  }

  &__item {
    display: flex;
    margin: 0 auto;

    & + & {
      margin-top: oldGutters(2);
    }

    &-description {
      color: var(--color-grey-medium)
    }

    &-title {
      flex-basis: oldGrid(1, 1);
      flex-shrink: 0;
      padding-right: oldGutters(.5);
    }

    &-image {
      width: 100%;
      margin-bottom: oldGutters(1);
    }
  }

  @media all and (max-height: 1000px) {
    .scroll-indicator {
      &__item {
        margin-bottom: remMin(4);
      }
    }
  }
  @media all and (max-height: 600px) {
    .scroll-indicator {
      display: none;
    }
  }
}

@include tablet-portrait {
  .definitions {
    &__letter {
      width: 100%;
      flex-direction: column;

      &:first-child, & + & {
        margin-top: oldGuttersM(1);
      }

      &-inner {
        display: none;
      }
    }

    .scroll-indicator {
      display: block;
      order: -1;

      &__inner {
        overflow-x: scroll;
      }

      &__list {
        display: inline-flex;
        width: auto;
      }

      &__item {
        margin-bottom: 0;

        &:not(:first-child) {
          margin-left: oldGuttersM(1);
        }
      }
    }

    &__item {
      flex-direction: column;
      text-align: center;

      & + & {
        margin-top: oldGuttersM(4);
      }

      &-title {
        flex-basis: auto;
        padding-right: 0;
        margin-bottom: oldGuttersM(1);
      }
    }
  }
}
