.adyen-checkout {
  &-contextual-text--error {
    @include style(typo-footnote);
    display: block;
    margin-top: rem(2);
    color: var(--color-red);
    text-transform: initial;
  }

  &__card__form {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  &-card-input__icon {
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 0;
    margin-bottom: 0;
    background-color: transparent;
    transform: translateY(-100%);

    .adyen-checkout__payment-method--bcmc & {
      display: none;
    }
  }

  &__payment-method {
    &--bcmc {
      .adyen-checkout__payment-method__header__title {
        .adyen-checkout__payment-method__image__wrapper {
          display: none;
        }
      }

    }
    cursor: pointer;

    &__brands {
      display: flex;
      flex-direction: row;
      align-items: center;

      .adyen-checkout__payment-method__brand-number {
        margin-left: rem(6);
      }

      img {
          width: rem(30);
          aspect-ratio: 30 / 20;
          object-fit: contain;
          object-position: center;
      }
    }
  }

&__card__cvc__hint  {
  display: none;
}

  &-contextual-text {
    display: none;
  }

  &__field__exp-date_hint {
    display: none;
  }

  &__spinner {
    .adyen-checkout__button & {
      width: 20px;
      height: 20px;
    }
    background-image: url('../icons/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;

    &__wrapper {
    }
  }

  &__card__brands {
    top: 0%;
    right: 0;
    bottom: 0;
    display: none;
    display: flex;
    height: max-content;
    align-items: center;
    margin-top: rem(16);
    margin-bottom: 0 !important;
    pointer-events: none;

    .adyen-checkout__payment-method__image {
      border: 1px solid $border-color;
    }
  }

  &__dropin {
    --split-margin: #{oldGutters(.25)};
  }

  &-error-panel--sr-only {
    position: absolute;
    top: auto;
    left: -10000px;
    overflow: hidden;
    width: 1px;
    height: 1px;
  }

  &__applepay__button {
    width: 100%;
    height: rem(45);
    appearance: -apple-pay-button;
  }

  &-form-instruction {
    display: none;
    margin-bottom: rem(30);
    color: var(--color-grey-medium);

  }


  &__image {
    width: rem(29);
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  &__icon {
    padding-top: rem(8);
  }

  &__field__exp-date_hint_wrapper {
    position: absolute;
    top: 40%;
    right: 0;
    transform: translateY(-50%);

    &--hidden {
      opacity: 0;
    }
  }

  &__field__cvc--back-hint {
    top: 40%;
    transform: translateY(-50%);
  }

  &__payment {
    &-methods-list {
      display: flex;
      flex-direction: column;
      gap: var(--padding-m);
    }

    &-method {
      padding: var(--padding-m);
      border: rem(.5) solid var(--color-grey-divider-light);

      & + & {
        border-top: 1px solid $border-color;
      }

      &--selected {
        .adyen-checkout__payment-method__header__title {
          &::after {
            display: block;
          }
        }

        .adyen-checkout__payment-method__details {
          height: auto;
          padding-top: var(--padding-m);
        }
      }



      &__header {
        position: relative;
        display: flex;

        &__title {
          display: flex;
          width: 100%;
          flex-grow: 1;
          align-items: center;
          @extend %typo-base-uppercase;

          .adyen-checkout__payment-method__image__wrapper {
            position: absolute;
            top: 0;
            right: 0;

            img {
              :not(.adyen-checkout__payment-method--paywithgoogle) & {
                width: rem(30);
                aspect-ratio: 30 / 20;
                object-fit: contain;
                object-position: center;
              }
            }

            .adyen-checkout__payment-method--credit:not(.adyen-checkout__payment-method--bcmc) &, .adyen-checkout__payment-method--credit:not(.adyen-checkout__payment-method--standalone) &, .adyen-checout__payment-method--credit:not(.adyen-checkout__payment-methods-list--storedPayments) & {
            display: none;
           }
          }

          &::before {
            display: block;
            width: 10px;
            height: 10px;
            border: 1px solid var(--color);
            margin-right: rem(6);
            content: '';
          }

          &::after {
            position: absolute;
            left: 2px;
            display: none;
            width: 8px;
            height: 8px;
            background: var(--color);
            content: '';
          }
        }
      }

      &__details {
        @extend %typo-base-uppercase;
        overflow: hidden;
        height: 0;
        transition: height .3s ease-in-out;
      }

      &__image {
        width: rem(20);
        height: auto;

        &__wrapper {
          display: block;

          & + & {
            margin-left: rem(7);
          }
        }
      }
    }
  }

  &__error {
    &-text {
      position: absolute;
      bottom: 0;
      padding-top: rem(5);
      color: $error;
      transform: translateY(100%);
    }
  }

  &-input__inline-validation, &__card__cvc__hint__wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    display: flex;
    display: none;
    align-items: flex-start;
    transform: translateY(-10%);
  }

  &__card {
    &__brands {
      display: none;
      margin-bottom: rem(16);
      gap: rem(6);
    }

    &__dual-branding__buttons {
      display: flex;

      img {
        & + img {
          margin-left: rem(7);
        }

        &[class*='not-selected'] {
          cursor: pointer;
          opacity: .3;
        }
      }
    }

    &__dual-branding__buttons, &__cardNumber__input > img {
      position: absolute;
      top: 40%;
      right: 0;
      transform: translateY(-50%);
    }

    &__cardNumber__input > img, &__cardNumber__brandIcon {
      width: 27px;
      height: auto;
    }
  }

  &__label {
    position: relative;
    display: block;

    &__text {
     display: none;
    }
  }

  &__field-wrapper {
    display: flex;
    gap: rem(16);

    & > * {
      flex-basis: 100%;
      flex-grow: 1;
    }
  }

  &__input {
    display: block;
    width: 100%;

    &--disabled {
      display: flex;

      // height: oldGutters(1) !important;
      align-items: center;
    }

    &-wrapper {
      @extend .field__inner;
      position: relative;
      flex-direction: row;

      img {
        top: 50%;
        transform: translateY(-50%);
      }


      .js-iframe {
        display: block;
        width: 100%;
        height: rem(34);
        border: 0;
        border-bottom: rem(.5) solid var(--color-grey-divider-light);

        // margin-bottom: rem(16);
        transition: border-color .3s cubic-bezier(.455, .03, .515, .955);

      }
    }

    &--error {
      .js-iframe {
        border-bottom-color: var(--color-red);
      }


    }

    &--focus {
      .js-iframe {
        border-bottom: rem(.5) solid var(--color-black);
      }
    }

    &--text {
      &.adyen-checkout__input--invalid {
        border-color: var(--color-red);
      }
      width: 100%;
      padding: var(--padding-s) 0;
      border:0;
      border-radius: 0;
      border-bottom: var(--border-size) var(--color-grey-divider-light) solid;
      appearance: none;
      background-color: transparent;
      font-family: $main-font;
      @include style(typo-base-uppercase);
      @extend .field__input;

      &:focus {
border-bottom-color:var(--color-main)
      }
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: rem(6);
    line-height: remMin(18);

    span {
      user-select: none;
    }

    input {
      display: none;
      appearance: none;

      & ~ .radio__box {
        width: rem(12);
        height: rem(12);

        &::after {
          width: rem(8);
          height: rem(8);
        }
      }

      &:checked ~ .radio__box::after {
        display: block;
      }
    }
  }

  &__button {
    @include button-rect;
    margin-top: rem(40);

    &__content {
      display: flex;
      align-items: center;

      img {
        display: none;
        margin-right: rem(10);
      }
    }
  }

  &__field--cardNumber &__input--error &__card__cardNumber__brandIcon, &__field--cardNumber &__input--valid:not(&__card__cardNumber__input--noBrand) + &-input__inline-validation--valid {
    display: none;
  }

  &__field--cardNumber &__input--valid:not(&__card__cardNumber__input--noBrand) + &-input__inline-validation--valid {
    display: none;
  }

  &__field--securityCode.adyen-checkout__field--error &__card__cvc__hint, &__field--securityCode.adyen-checkout__field--valid &__card__cvc__hint {
    opacity: 0;
  }

  @keyframes cvcIndicateLocation {
    from {
      opacity: 1;
    }

    to {
      opacity: .3;
    }
  }

  &__label--focused &__field__cvc--front-hint &__card__cvc__hint--front &__card__cvc__hint__location, &__label--focused &__field__cvc--back-hint &__card__cvc__hint--back &__card__cvc__hint__location {
    animation-direction: alternate;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: cvcIndicateLocation;
  }

  &__card__cvc__hint__wrapper {
    position: absolute;
    top: 40%;
    right: 0;
    display: flex;
    width: 27px;
    height: 100%;
    align-items: center;
    backface-visibility: visible;
    transform: translateY(-50%);
    transform-origin: center;
    transform-style: preserve-3d;
    transition: transform .3s cubic-bezier(.455, .03, .515, .955);
    will-change: transform;

    svg {
      width: 100%;
    }
  }

  &__field__cvc--front-hint.adyen-checkout__card__cvc__hint__wrapper {
    transform: rotateY(180deg);
  }

  &__card__cvc__hint {
    position: absolute;
    backface-visibility: hidden;
    transition: opacity .1s linear;
  }

  &__card__cvc__hint--front {
    transform: rotateY(180deg);
  }

  @media (prefers-reduced-motion: reduce) {
    &__card__cvc__hint__wrapper {
      transition: none;
    }
  }

  &__status {
    display: none;
  }

  &__card__holderName {
    margin-top: rem(20);
  }
}

@include tablet-portrait {
  .adyen-checkout {
    &__dropin {
      --split-margin: #{oldGuttersM(.5)};
    }

    &-form-instruction {
      font-size: rem(12);
    }

    &__payment {
      &-method {
        &__details {
          &__content {
            padding-top: var(--padding-m);
          }
        }

        &--selected {
          .adyen-checkout__payment-method__details {
            padding-top: 0;
          }
        }

        &__image {
          width: rem(20);

          &__wrapper {
            & + & {
              margin-left: rem(7);
            }
          }
        }
      }
    }

    &__error {
      &-text {
        padding-top: rem(5);
        font-size: remMin(7);
      }
    }

    &__card {
      &__cvc__hint__wrapper, &__cardNumber__input > img, &__cardNumber__brandIcon {
        width: 20px;
      }
    }

    &__icon {
      padding-top: rem(10);
    }

    &__label {
      font-size: rem(12);

      &__text {
        margin-bottom: rem(5);
      }
    }

    &__input {
      font-size: rem(12);



      &-wrapper {
        .js-iframe {
          height: rem(34.4);
        }
      }
    }

    &__checkbox {
      margin-bottom: oldGuttersM(1.5);
      line-height: remMin(18);

      input {
        margin-right: rem(10);
      }
    }

    &__field-wrapper {
      display: flex;
    }

    &__button {
      position: relative;


      &__content {
        img {
          margin-right: rem(10);
        }
      }
    }
  }
}

.adyen-checkout-payment-methods-list-label {
  display: block;
  padding-bottom: var(--padding-m);
  @include style(typo-base-body);
}

.adyen-checkout-payment-methods-container {
  &:not(:first-child) {
    .adyen-checkout-payment-methods-list-label {
      padding-top: var(--padding-m);
    }
  }
}

.adyen-checkout__store-details {
  display: flex;
  margin-top: var(--padding-m);
  gap: var(--padding-m);

  .adyen-checkout__checkbox {
    align-items: flex-start;
    margin-bottom: 0;
  }
}
