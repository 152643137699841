@use 'sass:math';

.form {
  --split-margin: #{rem(8)};


  &__inline {
    display: flex;
    align-items: center;
  }

  hr {
    height: var(--border-size);
    border: none;
    background-color: var(--color-grey-divider-light);
  }

  &__subtitle {
   @include style(typo-base-uppercase);

    &.large {
      margin-top: var(--padding-s);
    }
  }

  &__split {
    display: flex;

    & > * {
      flex-basis: 100%;
      flex-grow: 1;
      margin-right: var(--split-margin);
      margin-left: var(--split-margin);

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &-third {
      @supports (-webkit-appearance:none) and (not (overflow:-webkit-marquee)) and (not (-ms-ime-align:auto)) and (not (-moz-appearance:none)) {
       align-items: flex-start;
      }

      & > :first-child {
        flex-basis: calc(33.3333% - (var(--split-margin) * 5/3));
        flex-shrink: 0;
      }
    }

    &-third2 {
      & > :last-child {
        flex-basis: calc(33.3333% - (var(--split-margin) * 4/3));
        flex-shrink: 0;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: rem(24);
      gap: var(--padding-m);


      & > * {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  &__dob {
    margin-top: rem(24);
  }
}

@include tablet-portrait {
  .form {
    --split-margin: #{oldGuttersM(.5)};

    &__group {
      gap: var(--padding-m);
    }

    &__subtitle {
      &.large {
        margin-top: 0;
      }
    }

    &__split-column {
      margin-top: rem(14);
    }

    &__inline {
      flex-wrap: wrap;
    }

    &__dob {
      margin-top: rem(20);
    }
  }
}
