.alert {
  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;

    // padding-bottom: rem($header-height-m);
  }

  .close__button {
    position: absolute;
    top: rem(10);
    right: rem(10);
  }

  &.page {
    height: 1px;
    min-height: calc(var(--vh) - var(--header-top));
  }

  .page__inner {
    width: 100%;
    height: 100%;
  }

  &__col {
    position: relative;
    display: flex;
    flex-basis: 50%;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    & + & {
      &::before {
        position: absolute;
        top: rem($header-height-m);
        bottom: 0;
        left: 0;
        display: block;
        width: 1px;
        background-color: $border-color;
        content: '';
      }
    }
  }

  &__subtitle {
    margin-bottom: rem(40);
    @extend %typo-base-body;
  }
}

.catalog-alert {
  &__header {
    @extend .panel__header;
    padding-left: var(--padding-s);
  }

  &__title {
    display: flex;
    width: 100%;
    align-items: center;
    @extend %typo-base-uppercase;

    & span {
      margin-left: rem(5);
      color: var(--color-grey-medium);
    }
  }

  .close__button {
    position: absolute;
    top: rem(10);
    right: rem(10);
  }

  &__content {
    width: 100%;
    padding: var(--padding-m);

    & .checkbox {
      margin-top: rem(40);
      margin-bottom: var(--padding-m);
    }

    & .native-select {
      display: none;
    }
  }

  &__submit {
    margin-top: rem(20);
  }
}

@include tablet-portrait {
  .alert {
    // &.page {
    //   height: 1px;
    //   min-height: calc(var(--vh) - var(--header-top));
    // }

    .popin__title {
      display: none;
    }

    &__col {
      flex-basis: 100%;

      &:first-child {
        display: none;
      }

      & + & {
        &::before {
          display: none;
        }
      }
    }

    &__form {
      width: 100%;
    }

    &__subtitle {
      margin-bottom: oldGuttersM(1.5);
    }

    &__product {
      width: oldGrid(2, 2);
      pointer-events: none;
    }
  }
}
