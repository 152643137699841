.orders, .returns {
  &__tabs {
    display: flex;
    padding: rem(10) grid(0, 1);
    padding-top: rem(30);
    gap: rem(16);
    @include style(typo-base-uppercase);
  }

  .fullwidth {
    padding: 0;
  }

  &__empty {
    width: 100%;

    p {
      @include style(typo-base-body);
    }

  }

  &__tab {
    color: var(--color-grey-medium);

    &.current {
      color: var(--color-black)
    }
  }

  &__more {
    display: none;
    height: 0%;

    &.visible {
      display: flex;
    width: 100%;
    height: max-content;
    flex-wrap: wrap;
    padding: 0;
    gap: grid(0, 1);
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    padding: rem(80) grid(0, 1);
    gap: grid(0, 1);

    & .text-button {
      padding: 0;
    }

    & .price {
      align-items: flex-start;
    }
  }

  .account-item {
    width: grid(4);
    min-height: rem(250);

    &__body {
      & .button {
        margin-top: var(--padding-m);
      }
    }
  }

  &__error {
    padding: var(--padding-mega) var(--padding-m) var(--padding-mega) var(--padding-m);
  }
}

@include tablet-portrait {
  .orders, .returns {
    &__items {
      width: 100%;
      flex-direction: column;
      padding: rem(60) grid(0, 1);
    }

    .account-item {
      width: 100%;
    }
  }
}


