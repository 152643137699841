.loading-bar {
  position: fixed;
  z-index: 10000;
  top: 0;
  width: 100%;
  height: rem(4);
  pointer-events: none;

  &__inner {
    width: 100%;
    height: 100%;
    background-color: var(--color-main);
    transform: scaleX(0);
    transform-origin: left;
  }
}
