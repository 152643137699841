@use 'sass:math';

.szb-vfr-btns {
  display: flex;
  justify-content: space-between;

  button {
    width: max-content;
    height: unset;
    margin: 0;
    text-underline-offset: rem(5);
  }
}

.product-sizes {
  position: relative;
  padding: oldGutters(1) 0;
  border-bottom: 1px solid $border-color;


  &__guide {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__item {
    position: relative;

    & + & {
      margin-top: oldGutters(1);
    }

    &:last-child {
      & :has(.product-sizes__list-wrapper) > div {
        z-index: 9;
      }
    }
  }

  &__title {
    color: var(--color-grey-medium)
  }

  &__select {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $border-color;
    margin-top: oldGutters(.5);
  }

  &__label {
    display: block;
    width: 100%;
    padding: rem(20) rem(12);

    &:hover {
      cursor: pointer;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: rem(20);
      width: 6px;
      height: 6px;
      backface-visibility: hidden;
      background-image: url("../svg/down-small.svg");
      background-position: center;
      background-size: contain;
      content: '';
      filter: brightness(0);
      transform: translateY(-50%);
      transition: transform .4s $cubic-ease-in-out;

      .size-opened & {
        transform: translateY(-50%) rotate(0deg);
      }
    }
  }

  &__list-wrapper {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    overflow: hidden;
    width: calc(100% + 2px);
    height: 0;
    border: 1px solid $border-color;
    border-top: 0;
    margin-left: -1px;
    background-color: white;
    transition: height .4s $cubic-ease-in-out;

    .size-opened & {
      height: calc(var(--select-height));
    }
  }

  &__list {
    display: block;
    max-height: var(--max-height);
    overflow-y: auto;
  }

  &__size {
    position: relative;
    padding: rem(10) rem(12);

    &:first-child {
      padding-top: rem(20);
    }

    &:last-child {
      padding-bottom: rem(12);
    }

    &-oos {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: var(--color-grey-medium)
    }

    &-label {
      position: relative;
      display: block;
      width: 100%;
    }

    &-radio {
      z-index: 10;
      cursor: pointer;
      opacity: 0;
      @include fullscreen;
    }

    &-radio:checked + &-label {
      &::after {
        transform: none;
        transform-origin: left;
      }
    }
  }
}

@include tablet-portrait {
  .product-sizes {
    // display: none;
    position: fixed;
    z-index: 300;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    max-height: calc(100% - rem(30));
    padding: 0 0 rem(74);
    background: var(--background);
    transform: translateY(100%);
    transition: transform .7s $cubic-ease-out;

    .disappearing & {
      display: none;
    }

    .size-opened & {
      transform: none;
    }

    &::after {
      position: absolute;
      right: oldGuttersM(1);
      bottom: rem(74);
      left: oldGuttersM(1);
      height: 1px;
      background-color: $border-color;
      content: '';
    }

    &.single & {
      &__title {
        display: none;
      }
    }

    &__item {
      position: static;
      flex-basis: 50%;
      flex-grow: 1;
      padding: 0 oldGuttersM(1);
      overflow-y: auto;

      & + & {
        border-left: 1px solid $border-color;
        margin-top: 0;
      }

      &:nth-of-type(2) {
        .product-sizes__guide {
          left: 50%;
          margin-left: guttersM(1);
        }
      }
    }

    &__list {
      max-height: none;
      flex-direction: column;
      padding: oldGuttersM(2) 0;
      margin: 0;
      overflow-y: visible;
    }

    &__title {
      display: flex;
      height: rem(70);
      align-items: center;
      padding: 0;
      border-bottom: 1px solid $border-color;
      margin: 0;
      color: var(--color);
    }

    &__select {
      display: flex;
      flex-wrap: wrap;
      border: none;
      margin-top: 0;
    }

    &__label {
      display: none;
    }

    &__list-wrapper {
      position: relative;
      height: auto !important;
      border: none;
    }


    &__guide {
      top: initial;
      right: initial;
      bottom: rem(30);
      left: oldGuttersM(1);
    }

    &__size {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin: 0;

      & + & {
        margin-top: oldGuttersM(2);
        margin-left: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      &-label {
        width: auto;

        &::after {
          position: absolute;
          bottom: -.3em;
          left: 0;
          width: 100%;
          height: 1px;
          backface-visibility: hidden;
          background: var(--color);
          content: '';
          transform: scaleX(0);
          transform-origin: right;
          transition: transform .3s linear;
        }
      }

      &-oos {
        display: flex;
        width: 100%;
        justify-content: space-between;

        span {
          display: block;
        }
      }
    }
  }
}
