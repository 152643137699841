@use 'sass:math';

.article-image {
  display: flex;
  width: grid(6);
  flex-direction: column;
  padding-top: var(--padding-xl);
  padding-bottom: var(--padding-xl);
  gap: var(--gap-s);

  &.portrait {
    width: grid(6);
  }

  &__img {
    width: 100%;
    height: auto;
  }

  span {
    @include style(typo-base-uppercase);
  }

  @include tablet-portrait {
    width: 100%;
    padding: 0 var(--padding-m);


    &.portrait {
      width: 100%;
    }

    span {
      // padding: 0 var(--padding-m);
    }
  }
}
