.filters {
  position: fixed;
  top: 0;

  .drawer__wrapper {
    display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  }

  &__content {
    overflow: hidden auto;
    @include custom-scrollbar;
    height: calc(100% - rem(62.5)); // fitlers header + filters bottom
    // padding-bottom: var(--padding-xl);
    scrollbar-gutter: stable;

    .accordion {
      &::after {
        position: absolute;
        top: 100%;
        left: 100%;
        width: rem(2);
        height: var(--border-size);
        background-color: var(--color-grey-divider-light);
        content:''
      }

      &__inner {
        padding-bottom: var(--padding-xl);
      }
    }
  }

  label {
    transition: color .06s ease-in-out;

    @include hover {
      &:hover {
        color: var(--color-main);
    }
    }
  }

  &__header {
    @extend .panel__header;
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);

  }

  &__item {
    &-input {
      display: none;



      &:checked ~ label {
        color: var(--color-main);
      }


    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: rem(6);

    &-item {
      @include style(typo-base-uppercase);
      color: var(--color-grey-medium);
      cursor: pointer;

      label {
        cursor: pointer;
        user-select: none;
      }
    }
  }

  &__sizes {
    display: flex;
    flex-wrap: wrap;
    transform: translateX(rem(3)) translateY(rem(.5));

    &-item {
        display: flex;
      width: calc(100% / 6);
        align-items: center;
        justify-content: center;
      border:var(--border-size) solid var(--color-grey-divider-light);
        margin-top: calc(-1 * var(--border-size));
        margin-left: calc(-1 * var(--border-size));
      aspect-ratio: 1;
      color: var(--color-grey-medium);
      cursor: pointer;
      @include style(typo-base-uppercase);



      label {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;

      }
    }
  }

  &__color {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: rem(6);

    &-item {
      display: flex;
      align-items: center;
      color: var(--color-grey-medium);
      cursor: pointer;
      gap: var(--gap-s);
      @include style(typo-base-uppercase);

      label {
        cursor: pointer;
        user-select: none;
      }
    }

    &-swatch {
     @include style(color-swatch);
     background-color: var(--filter-color);

     &[style="--filter-color:#fff"] {
      border: var(--border-size) solid var(--color-grey-divider-light);
     }
    }
  }


  &__buttons {
    display: flex;
    width: 100%;
    align-items: flex-end;
    padding: var(--padding-m);
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    background-color: var(--color-background);
    justify-self: flex-end;
    text-align: center;

    & .button__dark {
      border: var(--border-size) solid var(--color-main);
    }
  }

  &__mobile-button {
    display: none;
  }

  @include tablet-portrait {
    width: grid(6,2);

    &__list {
    gap: rem(8) rem(8);

    }

    &__buttons {
      > :not(.filters__mobile-button) {
        display: none;
      }

    }

    &__mobile-button {
      display: block;
    }

  }
}
