.wishlist {
  &__wrapper {
    display: grid;
    overflow: hidden;
    box-sizing: border-box;
    grid-auto-rows: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    @include small {
      grid-template-columns: repeat(3, 1fr);
    }
    @include tablet-portrait {
    }
  }

  &-header {
    &__link {
      color: var(--color-grey-medium);

      &.current {
        color: var(--color-main);
      }
    }
  }

  &__empty {
    @include style(typo-base-body);
    display: flex;
    flex-direction: column;
    margin-top: rem(80);
    margin-bottom: rem(150);
    gap: var(--padding-m);


    & > .button {
      width: grid(3);
    }
  }

  @include medium {
    &__empty {
      & > .button {
        width: grid(4)
      }
    }
  }

  @include tablet-portrait {
    &__wrapper {
      grid-template-columns: repeat(1, 100%);

    }

    &__empty {
       margin-top: rem(40);

       & > .button {
        width: 100%
      }
    }
  }
}

