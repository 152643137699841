.brand {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .page-header {
    width: 100%;
    gap: 0;
    padding-bottom: var(--padding-m);
  }

  @include tablet-portrait {
  }
}
