.error-page {
  &__wrapper {
    display: flex;
    min-height: calc(var(--available-height) - rem(50));
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: var(--gap-xxl) 0;
    gap: rem(160);

    h2, h3, h4, h5 {
      color: var(--color);
      text-transform: initial;
    }

    p {
      font-size: rem(14);
      text-transform: initial;
    }
  }

  @include tablet-portrait {
    &__wrapper {
      padding-top: var(--padding-xl);
      gap: var(--gap-xl);
    }
  }
}
