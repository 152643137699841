.payment {
  .account-item {
    max-width: grid(4);
    min-height: rem(240);

    &__body-ctas {
      justify-content: flex-end;
      padding-top: rem(60);
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: var(--gap-xxl) grid(0,1);
    gap: var(--grid-gutter);
  }

  &__empty {
    padding-top: rem(80);
    padding-bottom: rem(150);
  }

    .page-tabs__content-left.fullwidth {
      padding-right: 0;
      padding-left: 0;
    }

}


@include tablet-portrait {
  .payment {
    flex-direction: column;



    .account-item {
      max-width: grid(6);

      &__body-content {
        font-size: rem(16);
      }

      &__body-ctas {
        color: var(--color-grey-medium);
      }
    }


    .accordion {
      width: 100%;
    }

    &__cards {
      flex-direction: column;
    }
  }
}
