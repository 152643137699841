.checkout-payment {
  .radio {
    img {
      height: rem(10);
      margin-left: rem(6);
    }
  }

  &__logos {
    display: flex;
  }

  &__terms {
    margin-top: rem(40);
    color: var(--color-grey-medium);
    & .checkbox__label {
      display: inline-block;
    }
    & a {
      z-index: 999;
      pointer-events: all;
      color: var(--color-black);
    }
  }

  .field__suggestion {
    padding: oldGutters(.4);
  }
}
