/** Typography */
%typo-display-l {
  font-size: rem(180);
  font-weight: 500;
  letter-spacing: -0.02em;
  line-height: 90%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(60);
    line-height: 100%;
  }
}

%typo-display-m {
  font-size: rem(100);
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 90%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(46);
    line-height: 100%;
  }
}

%typo-display-uppercase {
  font-size: rem(24);
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(22);
  }
}

%typo-display-uppercase-s {
  font-size: rem(18);
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(16);
  }
}

%typo-display-uppercase-base-mobile {
  font-size: rem(18);
  font-weight: bold;
  line-height: 120%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(14);
  }
}
%typo-base-body-l {
  font-size: rem(24);
  font-weight: bold;
  line-height: 110%;
  @include tablet-portrait {
    font-size: rem(22);
    line-height: 120%;
  }
}
%typo-base-body {
  font-size: rem(16);
  font-weight: bold;
  line-height: 120%;
}

%typo-base-uppercase {
  font-size: rem(12);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 120%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(14);
  }
}

%typo-base-uppercase-link {
  font-size: rem(12);
  font-weight: bold;
  line-height: 120%;
  text-decoration: underline;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(14);
  }

}

%typo-base-uppercase-small-mobile {
  font-size: rem(12);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 120%;
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(12);
    font-weight: bold;
    line-height: 120%;
  }
}

%typo-small-uppercase {
  text-transform: uppercase;
  @include tablet-portrait {
    font-size: rem(12);
    font-weight: bold;
    line-height: 120%;
  }
}

%typo-footnote {
  font-size: rem(12);
  font-weight: 500;
  line-height: 120%;
  @include tablet-portrait {
  font-size: rem(13);
  }
}

%typo-footnote-l {
  font-size: rem(14);
  font-weight: 500;
  line-height: 120%;
}




%color-swatch {
  &[style="background-color: #fff"] {
    border: rem(1.5) solid var(--color-grey-divider-light);
  }
  width: rem(8);
  height: rem(8);
  background-color: var(--color,var(--color-navy));
}
