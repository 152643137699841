.home-links {
  background-color: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: center;
  @include style(typo-display-uppercase);
  position: relative;
  max-width: var(--vw);
  padding: var(--padding-xl) 0;


  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: grid(1);
    content: '';
    display: block;
    height: 100%;
    background: linear-gradient(to left, rgb(255 255 255 / 0%), var(--color-background) 90%);
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: grid(1);
    content: '';
    display: block;
    height: 100%;
    background: linear-gradient(to right, rgb(255 255 255 / 0%), var(--color-background) 90%);
  }



&__list {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
}

&__link {
  padding-right: rem(16);
  width: max-content;
  transition: color .2s ease-in-out;

  &.inactive {
    color: var(--color-grey-medium);
  }

  &.active {
    color: var(--color-main);
  }
}
@include tablet-portrait {
  font-size: rem(16);
}

}
