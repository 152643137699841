.filter-bar-item {
  position: relative;
  display: flex;

  &:not(:last-child) {
    margin-bottom: oldGutters(.5);
  }

  &__color {
    display: block;
    width: rem(10);
    height: rem(10);
    border: 1px solid $border-color;
    border-radius: 50%;
    margin-right: rem(10);
    background: var(--filter-color);
  }

  &__input {
    cursor: pointer;
    opacity: 0;
    @include fullscreen;
  }

  &__label {
    color: var(--color-grey-medium);
    transition: color .15s linear;
  }

  &__input:checked ~ &__label {
    color: var(--color);
  }
}
