.checkout-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: rem(.5) solid var(--color-grey-divider-light);
  margin-top: var(--padding-m);

  &__link {
    @extend %typo-base-uppercase;
  }

  &__item {
    display: flex;
    width: 100%;
    padding: var(--padding-m) var(--padding-m) var(--padding-m) rem(15);
    gap: var(--padding-s);

    &:not(:last-child) {
      border-bottom: rem(.5) solid var(--color-grey-divider-light);
    }

    &-icon {
      transform: translateY(rem(-8));

      .icon {
        width: rem(32);
        height: rem(32);
      }
    }

    &-credit-card-icons {
      display: flex;
      margin-top: rem(5);
      gap: rem(6);

      .icon {
        width: rem(32);
        height: rem(32);
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &__inner {
    padding-top: oldGutters(.25);
    color: var(--color-grey-medium);
    @include style(typo-footnote-l);

    &.wysiwyg-block a {
      color: var(--color-grey-medium);
      text-decoration: none;
    }

    &.wysiwyg-block ul {
      padding-left: 0;

      & li {
        line-height: 120%;

        & a {
          color: var(--color-grey-medium);
        }
      }
    }

    &.support > * { display: block; }

    .footer__reinsurance-content:not(:last-child) {
      margin-bottom: var(--gap-xxxs);
    }
  }
}

@include tablet-portrait {
  .checkout-footer {
    // display: none;
    &__item {
      padding-bottom: var(--padding-xl);
    }
  }
}
