@use 'sass:math';

.category-card {
  width: calc(100% / 3);

  &.has-space {
    width: grid(4);

    .category-card__content {
      padding: var(--padding-s) 0 var(--padding-m);
    }

    &:first-child {
      margin-left: grid(0, 1);
    }

    &:last-child {
      margin-right: grid(0, 1);
    }
  }

  &__img {
    width: 100%;
    height: auto;
    aspect-ratio: 4/5;
    object-fit: cover;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--padding-s) var(--padding-m) var(--padding-m);

    &-title {
      @include style(typo-base-body);
      text-transform: uppercase;
    }

    &-link {
      @include style(typo-base-uppercase-link)
    }
  }
}

@include tablet-portrait {
  .category-card {
    width: grid(4, 1);

    &__content {
      &-title {
        font-size: rem(14);
      }
    }

    &.has-space {
      width: grid(4);
      margin-left: grid(0, 1);
    }
  }
}
