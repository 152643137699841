.menu {
  &.page {
    height: 100%;

    .page__inner {
      position: relative;
      display: flex;
      min-height: 100%;
      flex-direction: column;
      padding-top: rem(30);
    }
  }

  &.page:not(.shown) {
    .categories-list__item {
      &-title, &-subtitle {
        .js & {
          // opacity: 0;
        }
      }
    }
  }

  &__footer {
    margin-top: oldGuttersM(2);
  }

  &__link {
    display: block;

    & + & {
      margin-top: rem(16);
    }
  }
}
