.brand-diptych-image-text {
  position: relative;
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;

  &:nth-of-type(odd) {
    flex-direction: row-reverse;
  }

  .overlay {
    display: none;
  }

  .brand-diptych {
    &__content {
      position: sticky;
      bottom: grid(0, 1);
      display: flex;
      width: grid(4);
      flex-direction: column;
      margin: grid(0, 1);
      gap: var(--gap-s);
    }

    &__title {
      @include style(typo-display-uppercase);
    }

    &__text {
      @include style(typo-base-body);
      margin: 0;
      color: var(--color-grey-medium);

      strong {
        color: var(--color-black);
      }

      &-more {
        display: none;
      }
    }

    &__more {
      display: none;
    }

    &__image {
      width: grid(8, 1);
    }

  }

  @include tablet-portrait {
    position: relative;
    height: var(--ivh);

    .overlay {
      position: absolute;
      z-index: 0;
      display: block;
      opacity: 0.1;
      visibility: visible;

      &.visible {
        opacity: .4;
      }
    }

    .brand-diptych {
      &__content {
        // position: absolute;
        // bottom: 0;
        width: 100%;
        padding: grid(0, 1);
        padding-bottom: 0;
        margin: 0;
        margin-bottom: grid(0, 1);
        gap: rem(8);

        & > * {
          color: var(--color-background);
        }
      }

      &__text {
        &.visible {
          display: block;
        }

        &-more {
          display: block;
        }

        @include ellipsis(3, rem(19.2));

        &.revealed {
          display: block;
          overflow: visible;
          max-height: unset;
        }


        &.hidden {
          display: none;
        }

        &.isTruncated {
          @include ellipsis(4,220%);
        }



      }

      &__more {
        display: inline-block;
        width: max-content;
      }

      &__image {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.expanded {
      .brand-diptych {
        &__text {
          -webkit-line-clamp: unset;
        }

        &__more {
          & .more {
            display: none !important;
          }

          & .less {
            display: inline !important;
          }
        }
      }
    }

  }
}
