.checkout-total {
  padding: rem(16) var(--padding-m) var(--padding-m) var(--padding-m);

  h5.checkout-total__title {
    @extend %typo-base-uppercase;
  }

  &__value {
    @extend %typo-base-uppercase;
  }


  &__line {
    display: flex;
    justify-content: space-between;

    & + & {
      margin-top: var(--padding-m);

      &.checkout-total__total {
        padding-top: var(--padding-m);
        border-top: rem(.5) solid var(--color-grey-divider-light);
        @extend %typo-base-body;

        .checkout-total__subtitle {
          @extend %typo-base-uppercase;
        }
      }
    }
  }

  &__total {
    .checkout-total__value {
      @include style(typo-base-body);
    }
  }

  &__col {
    display: flex;
    align-items: baseline;
    gap: rem(3);
  }

  &__title span, &__subtitle, &__title input {
    display: inline-block;
    color: var(--color-grey-medium);
    @extend %typo-base-uppercase;

  }

  &__shipping {
    color: var(--color-grey-medium);

    .checkout-total__value {
      max-width: grid(3);
      text-align: right;
    }
  }

  &__subtitle {
    margin-top: rem(6);
    white-space: nowrap;
  }

  &__title {
    span {
      color: var(--color-grey-medium);
    }
  }
}

@include tablet-portrait {
  .checkout-total {
    padding: oldGuttersM(1);

    &__shipping {
      .checkout-total__title {
        color: var(--color-black);
      }
    }

    &__value {
      display: flex;
      align-items: flex-end;
    }

    &__line {
      & + & {
        margin-top: var(--padding-m);

        &.checkout-total__total {
          padding-top: oldGuttersM(1);
          margin-top: oldGuttersM(1);
        }
      }
    }
  }
}
