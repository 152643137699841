.shop-look {
  width: grid(10,1);

  &__left {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }

  &__right {
    width: 50%;
    background-color: var(--color-background);

    &-title {
      @extend .panel__header;
    }
  }

  &__products {
    width: grid(5, 1);
    height: calc(var(--ivh) - (var(--padding-s) + rem(32) + var(--gap-xl)));
    padding: 0 var(--padding-m);
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    overflow-y: auto;

    @include custom-scrollbar;

    &-item {
      position: relative;
      display: flex;
      padding: var(--padding-m) 0;
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);

      img {
        width: grid(0.9);
        margin-right: var(--padding-m);
      }

      &-wishlist {
        position: absolute;
        top: 0;
        right: calc(var(--padding-s) * -1);
      }
    }
  }

  &__details {
    text-transform: uppercase;

    &-price {
      margin-top: rem(6);
      color: var(--color-grey-medium);
    }

    &-colors {
      display: flex;
      margin-top: rem(12);
      gap: rem(8);
      list-style: none;

      li {
        width: rem(8);
        height: rem(8);

        span {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

    }
  }
}

@include tablet-portrait {
  $title-size: calc(var(--padding-s) + rem(32) + var(--padding-xl));

  .shop-look {
    top: unset;
    bottom: 0;
    width: grid(6,2) !important;
    background-color: transparent;
    pointer-events: none !important;

    &__wrapper {
      pointer-events: none;
    }


    &__left {
      display: none;
    }

    &__right {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      width: grid(6,2) !important;
      height: calc(var(--vh) - var(--vertical-drawer-offset-top));
      flex-direction: column;
      pointer-events: all;
    }

    &__products {
      position: relative;
      width: grid(6,2);
      height: 100%;
    }
  }
}
