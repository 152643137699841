@use 'sass:math';

.categories-list {
  $margin: rem(12);

  &__item {
    & + & {
      margin-top: $margin;

      &[data-slug="brand"] {
        margin-top: $margin * 2;
      }
    }

    &.opened {
      .categories-list__sublist-wrapper {
        height: var(--drawer-height);
      }
    }
  }

  &__sublist {
    padding-top: math.div($margin, 2);

    &-wrapper {
      margin-left: rem(15);
    }
  }

  &__subitem {
    color: var(--color-grey-medium);

    a {
      display: block;
      padding: math.div($margin, 2) 0;
      transition: color .3s linear;

      &::after {
        padding-top: math.div($margin, 2);
      }

      &.current, .desktop &:hover {
        color: var(--color);
      }
    }

    &:last-child a {
      padding-bottom: 0;
    }
  }

  a, button {
    &.red {
      color: $red;

      &.current, .desktop &:hover {
        color: $red;
      }
    }
  }
}

@include tablet-portrait {
  .categories-list {
    $margin: rem(20);

    &__item {
      & + & {
        margin-top: $margin;
      }
    }

    &__sublist {
      padding-top: math.div($margin, 2);

      &-wrapper {
        margin-left: rem(15);
      }
    }

    &__subitem {
      a {
        padding: math.div($margin, 2) 0;

        &::after {
          padding-top: math.div($margin, 2);
        }
      }
    }
  }
}
