.page {
  position: relative;
  max-width: var(--vw);
  min-height: var(--available-height);
  box-sizing: border-box;

  &__title {
    display: block;
    margin: oldGutters(-.5) auto oldGutters(1);
    text-align: center;

    span {
      color: var(--color-grey-medium)
    }
  }

  .js & {
    opacity: 0;
  }

  &.appearing, &.disappearing {
    pointer-events: none;
  }

  &.panel__page {
    width: 100%;
    padding: 0;
    background: var(--background);

    .page__inner {
    }

    &.appearing {
      top: 0;
      contain: none;
    }
  }
}

.internal-router, [data-ref="internalRouter"] {
  @include overlay-children;
}

.internal-page {
  max-width: var(--vw);
  opacity: 0;

  &.appearing {
    z-index: 20;
  }


  &.disappearing, &.appearing {
    will-change: transform, opacity;
  }
}

.preparing {
  position: absolute;
  z-index: -9999;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

@include tablet-portrait {
  .page {
    min-height: var(--available-height);

    &.panel__page {
      .page__inner {
        padding: oldGuttersM(1);
        padding-top: 0;
      }
    }

    &__title {
      margin: oldGuttersM(2) auto;
    }
  }
}

.container {
  @include overlay-children;

  .js & > .page {
    opacity: 0;
  }
}
