.collection-popin {
  display: none;

  &.opened & {
    &__wrapper, &__header {
      pointer-events: auto;
      transform: none;
    }

    &__header {
      opacity: 1;
    }
  }

  &__wrapper, &__header {
    position: fixed;
    z-index: index('overlay-3');
    top: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__header {
    z-index: calc(index('overlay-3') + 1);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: var(--gap-s) var(--gap-s) 0 grid(0, 1);

    &-step {
      @include style(typo-base-uppercase);
    }

    &-close {
      // margin-top: calc(-1 * var(--gap-s));
    }
  }

  &__wrapper {
    right: 0;
    left: 0;
    width: 100%;
    height: var(--vh);
    background-color: $background;
  }

  &__products {
    height: 100%;

    &-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-end;
      opacity: 0;
      pointer-events: none;

      &.show {
       pointer-events: all;
      }
    }

    &-item {
      // display: none;
      position: absolute;
      display: none;
      width: grid(3, 1);
      padding-left: grid(0, 1);

      .swiper {
        display: none;
      }

      .show &, .transition-in & {
        display: block;
        visibility: visible;
      }

      .row-product-card {
        &:last-child {
          border-bottom: none;
        }

        &__img {
          img {
            width: grid(0, 3);
          }
        }

        &__see {
          right: 0;
          left: unset;
        }

        &__top {
          max-width: grid(1, 4.5);
          gap: rem(3);
        }

        & .price {
          align-items: flex-start;
        }

        & .add-to-wishlist {
        }
      }
    }
  }

  &__image {
    height: 100%;
    margin: auto;

    // visibility: hidden;

    // &.show {
    //   visibility: visible;
    // }

    &-item {
      display: none;
      max-width: grid(5, 5);
      height: 100%;
      object-fit: contain;
      pointer-events: none;


      .show &, .transition-in & {
        display: block;
        visibility: visible;
      }
    }
  }

  &__arrows {
    position: absolute;
    right: var(--padding-s);
    bottom: var(--padding-s);
    display: flex;
    gap: var(--padding-m);

    & .disabled {
      opacity: .5;
      pointer-events: none;
      transition: opacity .3s linear;
    }

    & .collection-popin__arrow {
      display: flex;
      align-items: center;
      gap: rem(5);

      span {
        @include style(typo-base-uppercase);
      }
    }
  }

  .desktop {
    display: flex;
  }

  .mobile {
    display: none;
  }
}


@include tablet-portrait {
  .collection-popin {
    &__products {
      &-wrapper {
        flex-direction: column;
        overflow-y: scroll;

        // @include hide-scrollbar;
      }

      &-item {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-right: grid(0, 1);
        margin-top: var(--look-offset, calc(var(--ivh) * .5));
        background-color: $background;

        .swiper {
          display: block;
          width: rem(40);
          height: rem(2);
          border-radius: var(--border-radius);
          margin: var(--padding-s) auto var(--padding-m);
          background-color: var(--color-grey-medium-light);
        }

        .row-product-card {
          &__inner {
            padding: rem(8) 0;
          }

          &__img {
            img {
              width: grid(2);
            }
          }

          &__top {
            max-width: grid(3);
          }
        }
      }
    }

    &__image {
      position: fixed;
      top: 0;
      width: 100%;
      height: auto;

      &-item {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      .collection-popin__arrows {
        z-index: 1000;
        top: 50%;
        left: 50%;
        display: flex;
        width: 100%;
        justify-content: space-between;
        transform: translate(-50%, -50%);

        & > * {
          &:nth-child(1) {
            padding-left: grid(0, 0.5);
          }

          &:nth-child(2) {
            padding-right: grid(0, 0.5);
          }
        }
      }
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }


}
