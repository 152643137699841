.overlay {
    position: fixed;
    z-index: 50;
    background-color: var(--color-black);
    cursor: pointer;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
    visibility: hidden;

    &__strength {
      &-1 {
        z-index: calc(index('header') - 1);
      }

      &-2 {
        z-index: index('overlay-1');
      }

      &-3 {
        z-index: index('overlay-2');
      }

      &-4 {
        z-index: index('overlay-3');
      }

      &-5 {
        z-index: index('overlay-4');
      }

      &-9 {
        z-index: index('overlay-8');
      }
    }

    &.visible {
      opacity: .8;
      pointer-events: all;
      visibility: visible;
    }
}
