.mask-reveal {
  $duration: .8s;
  $easing: $cubic-ease-out;

  flex-direction: column;

  /*  &::before {
    content: '';
    // background: red;
    display: block;
    pointer-events: none;
    @include fullscreen;
  } */

  .word {
    display: inline-flex;
    line-height: inherit;
    overflow: hidden;
  }

  &.reveal-show .inner-word {
    //    background: red;
    transform: none;
    opacity: 1;
  }

  .inner-word {
    opacity: 0;
    line-height: inherit;
    display: block;
    contain: paint;
    transition: opacity $duration $easing var(--stagger-delay), transform $duration $easing var(--stagger-delay);
    transform: translateY(100%);
  }
}
