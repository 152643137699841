.add-to-wishlist {
  width: max-content;

  &__small {
    @include hover-scale-down;
    transition: opacity .3s ease-in-out;
    @include hover {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .icon__wishlist-active {
    display: none;
  }

  &.active {
    .icon__wishlist {
      display: none;
    }

    .icon__wishlist-active {
      display: block;
    }
  }
}
