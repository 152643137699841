.address-label {
  padding-bottom: var(--padding-m);

  p {
    margin: 0;
    color: var(--color-grey-medium);
  @include style(typo-base-body);
  text-transform: uppercase;

  }
}
