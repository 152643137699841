.profile {
  &.internal-page {
  }

  .page-tabs__content-left {
    width: grid(6,1);
    padding: rem(80) 0 rem(80) var(--padding-m);
  }

  .page-tabs__right {
    padding-top: rem(80);

  }

  .account-item {
   &__body {
    color: var(--color-grey-medium);

    &-ctas {
      color: var(--color-main);
    }
   }

   &.hidden {
    display: none;
   }
  }



}

@include tablet-portrait {
  .profile {
    &.internal-page {
    }

    .page-tabs__content-left {
      padding: rem(60) 0 rem(60) var(--padding-m);
    }

    .page-tabs__right {
      padding-top: 0;
    }


  }
}
