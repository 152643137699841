.address {
  z-index: 400;
  height: 100%;

  .page__inner {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  &__header {
    @extend .panel__header;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  &__footer {
    display: flex;
    width: 100%;
    padding: var(--padding-m) 0;
    border-top: 0.5px solid var(--color-grey-divider-light);
    justify-self: flex-end;
  }

  .form {
    width: 100%;
    height: 100%;
    padding: 0 var(--padding-m);
  }

  @include tablet-portrait {
    &__content {
      gap: rem(20);
    }

    &__header {
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);

    }

    .form {
      padding-top: var(--padding-m);
    }
  }
}
