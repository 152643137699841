@mixin icons {
  position: relative;
  display: block;
  background-repeat: no-repeat;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';

    // outline: rem(0.1) solid rgb(0 0 255 / 15.8%);

  }

  /** Variants */
  &-large {
    width: rem(32);
    height: rem(32);
  }

  &-small {
    width: rem(20);
    height: rem(20);
  }

  /** Icons */
  &__menu {
    background-image: url('../icons/burger.svg');
  }

  &__search {
    background-image: url('../icons/search.svg');
  }

  &__wishlist {
    background-image: url('../icons/wishlist.svg');
  }

  &__wishlist-active {
    background-image: url('../icons/wishlist-active.svg');
  }

  &__account {
    background-image: url('../icons/account.svg');
  }

  &__cart {
    background-image: url('../icons/cart.svg');
  }

  &__chevron-left {
    background-image: url('../icons/chevrons/chevron-left.svg');
  }

  &__chevron-right {
    background-image: url('../icons/chevrons/chevron-right.svg');
  }

  &__chevron-down {
    background-image: url('../icons/chevrons/chevron-down.svg');
  }

  &__chevron-down-s {
    background-image: url('../icons/chevrons/chevron-down-s.svg');
  }

  &__chevron-up {
    background-image: url('../icons/chevrons/chevron-up.svg');
  }

  &__chevron-up-s {
    background-image: url('../icons/chevrons/chevron-up-s.svg');
  }

  &__close {
    background-image: url('../icons/close.svg');
  }

  &__filters {
    background-image: url('../icons/filter.svg');
  }

  &__minus {
    background-image: url('../icons/minus.svg');
  }

  &__minus-alt {
    background-image: url('../icons/minus-alt.svg');
  }

  &__plus {
    background-image: url('../icons/plus.svg');
  }

  &__plus-alt {
    background-image: url('../icons/plus-alt.svg');
  }

  &__shipping {
    background-image: url('../icons/shipping.svg');
  }

  &__returns {
    background-image: url('../icons/return.svg');
  }

  &__contact {
    background-image: url('../icons/contact.svg');
  }

  &__credit-card {
    background-image: url('../icons/credit-card.svg');
  }

  &__visa {
    background-image: url('../svg/payment-methods/visa.svg')
  }

  &__mastercard {
    background-image: url('../svg/payment-methods/mastercard.svg');
  }

  &__paypal {
    background-image: url('../svg/payment-methods/paypal.svg')
  }

  &__amex {
    background-image: url('../svg/payment-methods/amex.svg')
  }

  &__map {
    background-image: url('../icons/map.svg');
  }

  &__check {
    background-image: url('../icons/check.svg');
  }

  &__green-check {
    background-image: url('../icons/green-check.svg');
  }

  &__pending {
    background-image: url('../icons/pending.svg');
  }

  &__error {
    background-image: url('../icons/error.svg');
  }

  &__fullscreen {
    background-image: url('../icons/fullscreen.svg');
  }

  &__play {
    background-image: url('../icons/play.svg');
  }

  &__pause {
    background-image: url('../icons/pause.svg');
  }
}

%icon-inverted {
  filter: invert(100%);
}
