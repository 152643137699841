.social-login {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .google-button {
    position: absolute;
    z-index: 10;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 110%;
    cursor: pointer;
    opacity: 0;


    iframe {
      transform: scale(1);
    }
  }

  .google-big-button {
    position: relative;
    overflow: hidden;
    width: 50%;
    border-left: rem(0.5) solid rgba($color: #000, $alpha: 80%);

    &>div {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      opacity: 0;
      transform: scale(2);


    }

    button {
      width: 100%;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    position: relative;
    width: 50%;
    @include basic-underline;

    &[name="guest"] {
      position: relative;
      width: max-content;

      &::after {
        display: none;
      }
    }

    &:first-child {
      border-right: rem(0.5) solid rgba($color: #000, $alpha: 80%);
    }

    &:last-child {
      border-left: 0;
    }
  }
}
