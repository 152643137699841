@use 'sass:math';

.home-product {
  position: relative;
  display: block;
  width: 100%;
  height: auto;

  // cursor: grab;
  // pointer-events: none;

  // cursor: grab;
  // pointer-events: none;

  & img, & video {
    width: 100%;
    aspect-ratio: #{math.div(480,660)};
    object-fit: cover;
    pointer-events: none;
  }


  img {
    &:last-child {
     position: absolute;
     z-index: 2;
     top: 0;
     left: 0;
     opacity: 0;
    }
  }

@include hover {
  &:hover {
    img {
      &:last-child {
        opacity: 1;
   }
    }
  }
}
}
