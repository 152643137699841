[data-dc-wrapper] {
  &:not(.native) {
    cursor: grab;
  }

  &.has-gradient {
    position: relative;

    &::before, &::after {
      position: absolute;
      z-index: 1;
      top: 0;
      display: block;
      width: grid(1);
      height: 100%;
      content: '';
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s ease-in-out;
    }

    &.left {
      &::before {
        z-index: 1;
        left: 0;
        background: linear-gradient(to left, rgb(255 255 255 / 0%), var(--color-background));
        opacity: 1;
      }
    }

    &.right {
      &::after {
        z-index: 1;
        right: 0;
        background: linear-gradient(to right, rgb(255 255 255 / 0%), var(--color-background));
        opacity: 1;
      }
    }
  }

  &.grabbing {
    * {
      cursor: grabbing;
    }
  }

  img {
    pointer-events: none;
  }

  *.grabbing & {
    pointer-events: none;

    * {
      user-select: none;
    }

    img {
      pointer-events: none;
    }
  }

  &.moving {
    * {
      user-select: none;
    }

    img, a {
      pointer-events: none;
    }
  }

  &.native {
    [data-dc-inner] {
      overflow: scroll hidden;
    }
  }
}
