@use 'sass:math';
$rem-reference: 16px;

@function rem($pixel) {
  @return math.div(strip-unit($pixel), strip-unit($rem-reference)) * 1rem;
}

@function remMin($pixel) {
  @return calc(var(--rem-clamp) * #{strip-unit($pixel)});
}


@mixin variables {
  /** Units */
  --vw: 100vw;
  --vh: 100vh;
  --ivh: 100vh;
  --rem: #{$rem-reference};

  @supports (width: 100dvh) {
    --vh: 100dvh;
  }

  /** Colors */
--color-black:#000;
--color-navy: #15142A;
--color-white-df:#F9F9F5;
--color-white: #fff;
--color-grey-lighter: #FCFCFC;
--color-grey-light:#F7F7F7;
--color-grey-medium: #757575;
--color-grey-medium-light: #C8D2C8;
--color-grey-divider-light: #E6E6E6;
--color-blue: #2b709b;
--color-red: #D60000;
--color-green: green;
--color-orange: #FD6735;

/** Color shortcuts */
--color-main: var(--color-black);
--color-background: var(--color-white);


/** Spacings */
--padding-xs: #{rem(5)};
--padding-s: #{rem(10)};
--padding-m: #{rem(20)};
--padding-l: #{rem(30)};
--padding-xl: #{rem(40)};
--padding-xxl: #{rem(60)};
--padding-mega: #{rem(120)};
--gap-xxxs: #{rem(3)};
--gap-xxs: #{rem(4)};
--gap-xs: #{rem(5)};
--gap-s: #{rem(10)};
--gap-m: #{rem(24)};
--gap-l: #{rem(30)};
--gap-xl: #{rem(60)};
--gap-xxl: #{rem(80)};

/** Spacings -> Header */
--padding-header: #{rem(12) rem(14) rem(12) rem(10)};
--padding-header-mobile: #{rem(12)};
--padding-header-scrolled: #{rem(12) rem(12) rem(12) rem(10)};
--padding-header-scrolled-mobile: #{rem(12) rem(12) rem(12) rem(7)};
--vertical-drawer-offset-top: #{rem(150)};

/** Sizes */
--border-size: #{rem(0.5)};
--border-radius: #{rem(2)};
--notification-height: #{rem(25)};
--scrollbar-width: #{rem(2)};

/** Sizes -> Header */
--header-height: #{rem(86)};
--header-height-scrolled: #{rem(56)};
--product-card-infos-height: #{rem(82)};
--product-card-infos-height-mobile: #{rem(76)};

/** Grid */
--grid-margin: #{rem(20)};
--grid-gutter: #{rem(20)};
--grid-columns: 12;
--grid-column: #{computeGridColumn(12)};

/** Durations */
--opacity-duration: 0.06s;
--header-duration:.2s;



@include tablet-portrait {
  --header-height: #{rem(56)};
  --header-height-scrolled: var(--header-height);
  --notification-height: #{rem(25)};
  --grid-columns: 6;
  --grid-column: #{computeGridColumn(6)};
}
}
