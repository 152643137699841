.editorial-subnav {
  position: sticky;
  z-index: 49;
  top: var(--header-height);
  width: 100%;
  background-color: var(--color-background);
  transition: all var(--header-duration) $cubic-ease-in-out;

  .scroll-down & {
    top: var(--header-height-scrolled);
  }

  &__title {
    &:not(.native) {
      cursor: grab;
    }

    &.grabbing {
      * {
        cursor: grabbing;
      }
    }

    &.moving {
      * {
        user-select: none;
      }

      img, a {
        pointer-events: none;
      }
    }

    &.native {
      .editorial-subnav__title-inner {
        overflow: scroll hidden;

        // .menu-pushes__push {
        //   &:first-child {
        //     margin-left: var(--grid-gutter);
        //   }
        // }
      }
    }

    &-inner {
      position: relative;
      display: flex;
      overflow: hidden;
      flex-direction: row;
      align-items: flex-start;
      padding: var(--padding-m);
      padding-top: rem(28);
      gap: rem(16);
      transition: padding 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

      @include style(typo-display-uppercase);
      @include hide-scrollbar;


      .scroll-down & {
        padding-top: var(--padding-m);
      }

      button {
        margin-top: rem(1);
        color: var(--color-grey-divider-light);
      }

      h1, a {
        text-wrap: nowrap;
        white-space: nowrap;
        user-select: none;
      }

      a {
        color: var(--color-grey-divider-light);

        .grabbing & {
          pointer-events: none;
        }

        &:last-child {
          margin-right: grid(.5);
        }

        &:hover {
          color: var(--color-grey-medium);
          transition:color .06s ease-in-out;
        }
      }
    }

  }

  &__track {
    height: rem(2);
    background-color: var(--color-grey-light);

    &-inner {
      width: 100%;
      height: 100%;
      background-color: var(--color-main);
      transform: scaleX(0);
      transform-origin: left;
    }
  }
  @include tablet-portrait {
    &__title {
      &-inner {
        gap: rem(20);
      }
    }
  }
}
