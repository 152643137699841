.article-text {
  width: grid(8);
  margin: 0 auto;
  color: var(--color-grey-medium);
  text-align: center;
  word-wrap: break-word;

  &.display {
    @include style(typo-base-body-l);
  }
  @include style(typo-base-body);

  h2, strong {
    @include style(typo-display-uppercase);
    color: var(--color-main);
  }

  a {
    color: var(--color-main);
  }

  @include tablet-portrait {
    width: grid(6);
    font-size: rem(18);
    text-align: left;

    &.display {
      font-size: rem(22);
    }
  }
}
