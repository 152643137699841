.collections {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__bloc {
    // width: oldGrid(6, 0);
    display: flex;
    width: oldGrid(4, 0);
    flex-direction: column;
    align-items: center;
    margin: auto;

    & + & {
      margin-top: rem(120);
    }

    &-img {
      width: 100%;
      margin-bottom: rem(32);
    }

    &-title, &-text {
      text-align: center;
    }

    &-title {
      margin-bottom: rem(10);
    }

    &-text {
      color: var(--color-grey-medium)
    }

    @include tablet-portrait {
      width: gridM(4, 2);
    }
  }
}

@include tablet-portrait {
  .collections {
    &__wrapper {
    }

    &__bloc {
      &-title {
        margin-top: rem(10);
      }

      @include tablet-portrait {
        width: gridM(4, 2);
      }
    }

    &__year, &__bloc {
      & + & {
        margin-top: rem(60);
      }
    }
  }
}
