.quickbuy {
  .panel__header {
    position: sticky;
    z-index: 2;
    top: 0;
    @extend .panel__header;
  }

  form {
    height: calc(var(--vh) - var(--size-confirm-height) - var(--size-header-height));
    @include custom-scrollbar;
    overflow-y: auto;
  }

  .size-selector {
    &__inner {
      overflow: unset;
      height: 100%;
    }

    &__confirm {
      position: absolute;
    }
  }
}
