.color-selector {
  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    flex-shrink: 0;
  }

  &__title {
    @extend .panel__header;
  }

  &__products {
   display: grid;
    width: grid(5, 1);
    height: calc(var(--ivh) - var(--color-header-height));
    justify-content: space-between;
    padding: grid(0, 1);
    border-top: var(--border-size) solid var(--color-grey-divider-light);
    gap: grid(0, 1);
   grid-auto-rows: min-content;
   grid-template-columns: repeat(2,1fr);
    overflow-y: auto;

    @include custom-scrollbar;

    &:has(.hovered) {
      .current {
        &::after {
          opacity: 0;
        }

        &.hovered {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
}

@include tablet-portrait {
  $title-size: calc(var(--padding-s) + rem(32) + var(--padding-xl));

  .color-selector {
    top: unset;
    bottom: 0;
    width: grid(6, 2);
    height: calc(var(--vh) - var(--vertical-drawer-offset-top));
    transform: translateX(0);





    &__products {
      width: 100%;
      height: unset;
      max-height: 100%;
      gap: var(--gap-xl) grid(0, 1);

    }
  }
}
