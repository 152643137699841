.reveal, .reveal-list > * {
  $duration-out: .3s;
  $duration: .5s;
  $easing: $ease-out;

  .js & {
    opacity: 0;
    transition: opacity $duration-out linear, transform $duration-out $easing;
  }

  @include not-tablet-portrait {
  }

  .js &.reveal-show {
    opacity: 1;
    transition-delay: var(--stagger-delay);
    transition-duration: $duration;

    // transition: opacity $duration linear var(--stagger-delay), transform $duration $easing var(--stagger-delay);
  }
}
