@use 'sass:math';

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin show-scrollbar {
  -ms-overflow-style: auto;
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    display: auto;
  }
}

@mixin basic-underline {
  @include hover {
    &:hover {
      text-decoration: underline;
      text-underline-offset: rem(5);
    }
  }
}


@mixin custom-scrollbar(
  $width: var(--scrollbar-width),
  $track-color: var(--color-grey-divider-light),
  $thumb-color: var(--color-black)
) {
  .firefox & {
    scrollbar-color: black transparent;
  scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
  }

  // Styles for Firefox
  // scrollbar-width: thin;
  // scrollbar-color: $thumb-color $track-color;
  // -ms-overflow-style: none; // IE and Edge
}

@mixin overlay-children {
  display: grid;
  grid-template-columns: 1fr;

  & > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }
}

@mixin style($style) {
  @extend #{'%' + $style};
}

@mixin keepRatio($ratio: 3/4) {
  position: relative;

  & > * {
    @include fullscreen;
  }

  &::before {
    display: block;
    padding-bottom: math.div(1, $ratio) * 100%;
    content: '';
  }
}

@mixin background-image-retina($file, $type, $width, $height) {
  background-image: url($file + '.' + $type);
  background-repeat: no-repeat;
  background-size: $width $height;

  @media (min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
    & {
      background-image: url($file + '@2x.' + $type);
    }
  }
}

@mixin transition($properties :'visibility, opacity', $duration:.5s, $ease:$quad-ease-out, $delay:0s) {
  $value: '';
  $list: explode($properties, ',');

  @each $property in $list {
    @if value != '' {
      $value: $value + ', ';
    }

    $value: $value+'#{$property} #{$duration} #{$ease} #{$delay}';
  }
  transition: #{$value};
}

@mixin on-line-text-no-wrap() {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space: nowrap;
}

@mixin ellipsis($lines-to-show, $line-height: '1em') {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  max-height: calc($lines-to-show * $line-height);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
  text-overflow: ellipsis;
}

@mixin hover() {
  @media screen and (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hover-scale-down {

  @include hover {
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(.93);
    }
  }
}

@mixin fullscreen( $position: absolute ) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin fullscreen-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-cover() {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

@mixin fullscreen-contain() {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

@mixin vertical-align-children($target : '*') {
  .flexbox & {
    display: flex;
    align-items: center;
  }

  .no-flexbox & > #{$target} {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
  }
}

@mixin center-children($target : '*', $modernizrAddons:'') {
  #{$modernizrAddons}.flexbox & {
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-box-align: center;
  }

  #{$modernizrAddons}.no-flexbox & > #{$target} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin fade-transition($show : true, $selector : '&.show') {
  @include transition;

  @if $show {
    opacity: 0;
    visibility: hidden;
  } @else {
    opacity: 1;
    visibility: visible;
  }

  #{$selector} {
    @if $show {
      opacity: 1;
      visibility: visible;
    } @else {
      opacity: 0;
      visibility: hidden;
    }
  }
}

@mixin box-shadow($distance :5px, $opacity : .5) {
  box-shadow: 0 0 $distance rgba(0, 0, 0, $opacity);
}

@mixin text-shadow($distance :5px, $color : rgba(0, 0, 0, .5)) {
  text-shadow: 0 0 $distance $color;
}

@mixin filter-shadow($distance : 2px, $opacity : .3) {
  filter: drop-shadow(0 0 $distance rgba(0, 0, 0, $opacity));
}

@mixin css-parralax($translateZ, $perspective: 1px) {
  transform: translateZ($translateZ) scale(parralax-depth-correct($translateZ, $perspective));
}

@mixin justify-one-line() {
  text-align: justify;

  &::after {
    display: inline-block;
    width: 100%;
    content: '';
  }
}

@mixin mix-blend-mode() {
  @supports (mix-blend-mode: exclusion) {
    @content;
  }
}
