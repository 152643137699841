.error-message {
  max-width: oldGrid(5, 1);
  margin: 0 auto oldGutters(1);
  color: $error;
  text-align: center;


  form & {
    margin-bottom: oldGutters(.5);
    text-align: left;
  }
}

@include tablet-portrait {
  .error-message {
    margin-bottom: oldGuttersM(1.5);

    form & {
      margin-bottom: oldGrid(.5);
    }
  }
}
