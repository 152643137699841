.returns {
  [data-step-name] {
    scroll-margin-top: calc(var(--header-height-scrolled) + rem(115) );
  }

  &__wrapper {
    @extend .checkout__wrapper;
  }

  &__aside {
    @extend .checkout__summary;
    z-index: unset;
    display: flex;
    height: 100%;
    flex-direction: column;
    padding:  var(--padding-m);
    background-color: var(--color-grey-light);

    &-toggle {
      position: sticky;
      z-index: 200;
      bottom: 0;
      display: none;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: var(--padding-m)  var(--padding-s)  var(--padding-m)  var(--padding-m);
      border-top: var(--border-size) solid var(--color-grey-divider-light);
      background: var(--background);
      @include style(typo-base-uppercase);
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: var(--border-size) solid var(--color-grey-divider-light);

      button {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: var(--padding-s) var(--padding-m) var(--padding-m) var(--padding-m);
        padding-right: var(--padding-s);
      background: var(--background);

        h3 {
          @include style(typo-base-uppercase);
        }

        .icon {
          display: none;
        }

      }



      &-text {
      padding: 0 var(--padding-m) var(--padding-m) var(--padding-m);
      background: var(--background);
        color: var(--color-grey-medium);

        @include style(typo-base-body)
      }
    }

  }

  &__inner {
    @extend .checkout__inner;
    width: grid(5, 2);
    margin: 0 auto;

  }

  &__title {
    @include style(typo-base-body);

    &:first-child {
      margin-bottom: var(--padding-m);
    }
  }

  &__items-list {
    display: flex;
    flex-direction: column;
    gap: var(--padding-xl)
  }

  &__items-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: var(--padding-xl);

    &.not-valid {
    [type="submit"] {
      color: var(--color-grey-medium);
        cursor: not-allowed;
      }
    }

    .native-select.filled select {
      color: var(--color-grey-medium);
    }
  }



  &__step {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: var(--padding-xl);
  }


  &-breadcrumb {
    @extend .checkout-breadcrumb;

    &__inner {
      @extend .checkout-breadcrumb__inner;
    }

    &__step {
      @extend .checkout-breadcrumb__step;
    }
  }


  &__refund-recap {
    display: flex;
    flex-direction: column;
    padding: var(--padding-m);
    border: var(--border-size) solid var(--color-grey-divider-light);
    gap: var(--padding-m);

    @include style(typo-base-uppercase);


    hr {
      width: 100%;
      height: var(--border-size);
      border:none;
      margin: 0;
      background-color: var(--color-grey-divider-light);
    }

    &-row {
      display: flex;
      justify-content: space-between;

      &-grayed {
        color: var(--color-grey-medium);
      }

      &-total {
        @include style(typo-base-body);
        text-transform: initial;
      }

    }

    &-total {
      display: flex;
      flex-direction: column;
      gap: var(--padding-s);
    }

    &-note {
      @include style(typo-footnote-l);
      max-width: grid(4,1);
      color: var(--color-grey-medium);
      text-transform: initial;

    }
  }

  .checkout-footer {
    height: max-content;
  }

  &__message {
    @extend .auth__message;
    margin-top: var(--padding-m);

    &-title {
      @extend .auth__message-title;
    }

    &-text {
      @extend .auth__message-text;
    }
  }


  &-success {
    &__intro {
      @extend .checkout-success__intro;
      display: flex;
      width: grid(5,2);
      flex-direction: column;
      padding: 0;
      gap: var(--padding-m);

      &-header {
        @extend .checkout-success__intro-header;
      }

      &-order {
        @extend .checkout-success__intro-order;
      }

      &-subtitle {
        @extend .checkout-success__intro-subtitle;
      }

      &-text {
        @extend .checkout-success__intro-text;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        gap: var(--padding-m);

        ul {
          display: flex;
          flex-direction: column;
          padding-left: var(--padding-m);
          gap: var(--padding-m);
          list-style: square;


          li {
            padding-left: var(--padding-xs);
            list-style-type: square;
          }
        }
      }

      button {
        margin-top: var(--padding-m);
      }
    }
  }




  @include tablet-portrait {
    .variants {
      border-top: var(--border-size) solid var(--color-grey-divider-light);
    }

    &__inner {
    width: grid(6);
    box-sizing: border-box;
    margin: 0 auto;

    }

    &-success {
      &__intro {
        width: grid(6);
      }
    }

    &__form {
    overflow: hidden;
      width: 100%;

    }



    &__aside {
      position: relative;
      z-index: unset;
      order:3;

      &-toggle {
        display: flex;
      }

      &-overlay {
        position: fixed;
        z-index: 201;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-black);
        opacity: 0;
        transition: opacity 200ms ease-in-out;

        .opened & {
          opacity: 0.8;
          pointer-events: all;
        }
      }



      &-content {
        position: fixed;
        z-index: 202;
        top: 0;
        left: 0;
        display: flex;
        height: var(--vh);
        flex-direction: column;
        justify-content: flex-end;
        border:none;

        &-inner {
          z-index: 203;
          pointer-events: all;
          transform: translateY(100%);

          .opened & {
            z-index: 400;
          }
        }




        button {
          border-bottom: var(--border-size) solid var(--color-grey-divider-light);

          .icon {
            display: block;
          }
        }

        &-text {
          padding-top: var(--padding-m);
        }
      }
    }
  }
}

.returnsItemSelectSize {
  .page__inner {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .panel__header {
    position: sticky;
    z-index: 10;
    top: 0;
    align-items: flex-start;
    padding-bottom: var(--padding-m);
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    background-color: var(--background);

    &-inner {
      display: flex;
      padding-top: var(--padding-s);
      gap: var(--padding-m);

      img {
        width: auto;
        height: rem(88);
        @include tablet-portrait {
          height: rem(55);
        }
      }
    }
  }

  .form {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .size-selector {
    &__confirm {
      position: sticky;
      bottom: 0;
    }

    &__inner {
      height: unset;
    }
  }
}
