.filter-bar {
  $bar-height: oldGutters(2);
  $height: '(var(--vh) - #{oldGutters(2) + $bar-height})';

  .search-panel & {
    $height: '(var(--vh) - #{2 * rem($header-height) + $bar-height})';
  }
  display: none;
  flex-grow: 1;
  overflow: hidden;
  height: calc((#{$height}) * var(--filter-height));
  background-color: var(--background);

  &__inner {
    height: calc(#{$height});
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &__groups {
    display: flex;
    align-items: stretch;
    padding: oldGutters(1) 0;
    position: relative;
    flex-grow: 1;
  }

  &__group {
    flex-basis: 25%;
    padding: 0 oldGutters(1);
    display: flex;
    height: 100%;
    flex-direction: column;

    &-title {
      margin-bottom: oldGutters(.5);
    }

    & + & {
      border-left: 1px solid $border-color;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    @include not-tablet-portrait {
      height: 100% !important;
    }
  }

  .drawer-panel {
    @include not-tablet-portrait {
      height: 100% !important;
    }
  }

  &__list {
    &[data-column] {
      // column-gap: rem(10);
      flex-grow: 1;
      columns: 2;

      .no-firefox & {
        height: 100%;
        column-fill: auto;
      }

      // overflow: hidden;
      // flex-grow: 10;
      // display: flex;
      // flex-flow: column wrap;
      // align-content: flex-start;
    }
  }

  &__button {
    & + & {
      margin-left: oldGutters(1);
    }
  }

  &__buttons {
    padding: 0 oldGutters(1) oldGutters(1);
    display: flex;
  }
}

@include tablet-portrait {
  .filter-bar {
    $bar-height: oldGuttersM(3);
    $height: '(var(--vh) - #{oldGuttersM(1) + $bar-height})';
    height: calc((#{$height}) * var(--filter-height));

    &__inner {
      height: calc(#{$height});
    }

    .panel &, .search-panel & {
      $bar-height: oldGuttersM(3) + rem($header-height);
      $height: '(var(--vh) - #{oldGuttersM(1) + $bar-height})';
      height: calc((#{$height}) * var(--filter-height));

      &__inner {
        height: calc(#{$height});
      }
    }

    &__groups {
      align-items: stretch;
      padding: 0 oldGuttersM(1);
      justify-content: flex-start;
      flex-direction: column;
    }

    &__group {
      padding: 0;
      height: auto;
      flex-basis: auto;

      &-title {
        margin-bottom: 0;
        height: oldGuttersM(4);
        display: flex;
        white-space: pre-wrap;
        align-items: center;
        border-bottom: 1px solid $border-color;

        span {
          flex-grow: 1;
        }

        &::after {
          content: '';
          display: block;
          height: rem($icon-size);
          width: rem($icon-size);
          background-image: url("../svg/down.svg");
          background-size: contain;
          background-position: right;
          background-repeat: no-repeat;
          pointer-events: none;
          transition: transform .3s $cubic-ease-in-out;
        }
      }

      & + & {
        border-left: 0;
      }

      &.opened {
        .filter-bar__group-title::after {
          transform: rotate(-180deg);
        }

        .drawer-panel {
          height: var(--drawer-height);
        }
      }
    }

    &__list {
      height: auto;
      padding: oldGuttersM(1) 0;
      flex-grow: 1;

      &[data-column] {
        min-height: rem(100);
      }
    }

    &__button {
      & + & {
        margin-top: oldGuttersM(1);
        margin-left: 0;
      }
    }

    &__buttons {
      padding: oldGuttersM(1);
      flex-direction: column;
    }
  }
}
