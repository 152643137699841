.xlinking {
  padding-top: var(--padding-xl);

  &__title {
    @include style(typo-base-body);
    display: block;
  padding:  grid(0,1);
  padding-top: 0;
    color: var(--color-grey-medium);

  }

  &__list {
  position: relative;
  overflow: hidden;
    width: 100%;
  border-top: var(--border-size) solid var(--color-grey-divider-light);

  @include style(typo-base-uppercase);




  ul {
    position: relative;
    display: flex;
    overflow: scroll hidden;
    padding: grid(0,1) 0 grid(0,1) grid(0,1);
    padding-bottom: var(--padding-xl);
    padding-left: 0;
    gap: rem(16);
    @include hide-scrollbar;

    li {
  color: var(--color-main);
      text-wrap: nowrap;

      a {
        @include basic-underline;

      }

  &:first-child {
    margin-left: grid(0, 1);
  }

  &:last-child {
    margin-right: grid(1);
  }
    }
  }
  }

  &__item {
    transition: color var(--opacity-duration) ease-in-out;

    &.inactive {
      color: var(--color-grey-medium);
    }

    &.active {
      color: var(--color-main);
    }
  }

}

@include tablet-portrait {
  .xlinking {
    &__list {
      padding-top: rem(20);
      padding-bottom: rem(30);
      padding-left: 0;
    }

    &__title {
      padding-top: var(--padding-l);
    }
  }
}
