.home-diptych-products {
  &__inner {
    position: relative;
    display: flex;
  }

  &__media {
    width: 50%;
    height: auto;

    &-img {
      position: sticky;
      top: 0;

      .header_solid & {
        top: var(--header-height-scrolled);
      }
      width: 100%;
      height: auto;
      min-height: var(--vh);
      object-fit: cover;
      object-position: center;
    }
  }

  &__cards {
    &-list {
      position: sticky;
      top: 0;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &-item {
      width: calc(var(--vw) / 4);
    }
  }
}

@include tablet-portrait {
  .home-diptych-products {
    &.has-scroll {
      .home-diptych-products__cards-list {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        @include hide-scrollbar;
      }
    }

    &__inner {
      flex-direction: column;
    }

    &__media {
      position: relative;
      top: 0;
      width: 100%;

      &-img {
        min-height: unset;

        // aspect-ratio: 4/5;
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;

      &-list {
        position: relative;
        display: flex;
        width: 100%;
      }

      &-item {
        width: grid(4, 1);
        flex-shrink: 0;
        scroll-snap-align: start;
      }
    }
  }

}
