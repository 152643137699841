@use 'sass:math';

.catalog-push {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: #{math.div(196.5,270)};
  cursor: grab;

  &.grabbing {
    cursor: grabbing;
  }

  &__track {
    position: absolute;
    z-index: 10;
    top: var(--padding-m);
    left: var(--padding-m);
    width: calc(100% - var(--padding-m) * 2);
    height: rem(2);
    background-color: rgb(255 255 255 / 30%);
    transition: opacity .3s ease-in-out;

    &.hidden {
      opacity: 0;
    }

    &-inner {
      width:100%;
      height: 100%;
      background-color: var(--color-white);
      transform: scaleX(0);
      transform-origin: left;
      will-change: transform;
    }
  }

  &__slider {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__slide {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scroll-margin-top: calc(rem(85) + rem(63));

    // opacity: 0;
    // pointer-events: none;

    &-reassurance {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color:var(--color-navy);

    }

    // transform: translateX(100%);

    &.active {
      pointer-events: all;

      // z-index:1;

      // transform: translateX(0);
    }

    &-content {
      position: relative;
      z-index: 2;
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: flex-end;
      padding: var(--padding-m);
      gap: var(--padding-m);

      .button {
        cursor: pointer;
        pointer-events: all !important;
      }

    }

    &-text {
      display: flex;
      flex-direction: column;
      color: var(--color-background);
      gap: var(--gap-s);

      h2 {
        @include style(typo-display-uppercase-base-mobile)
      }


    }
  }

  &__reinsurance-item {
    display: flex;
    align-items: center;
    padding: var(--padding-m) var(--padding-m) var(--padding-m) rem(12);
    border-top: var(--border-size) solid #353446;
    color: var(--color-background);
    gap: var(--gap-s);
    @include style(typo-base-uppercase-small-mobile);

    .icon {
      @include style(icon-inverted);
    }
  }

  &__body {
    &.hidden {
      display: none;
    }

    &-full {
      &.hidden {
        display: none;
      }
    }
    @include style(typo-base-uppercase-small-mobile);
      margin: 0;

    .button {
      display: inline-block;
      padding: 0;
      cursor: pointer;
      pointer-events: all !important;
      vertical-align: 2%;

      &__text {
        display: block;
        height: min-content;
    @include style(typo-base-uppercase-small-mobile);

      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      position: absolute;
      background-color: var(--color-main);
      content: '';
      inset: 0;
      opacity: .2;
    }
  }

  &__reinsurance {
    &.catalog-push {
      height: auto;
      cursor: default;
    }
  }


  .links__button, .push__read {
    @include style(typo-base-uppercase-small-mobile);
  }

  @include tablet-portrait {
  aspect-ratio: #{math.div(196.5,270)};

    &__links & {
      &__slide-content {
        gap: var(--gap-s);
      }
    }

    &__reinsurance-item {
      padding: var(--padding-s) var(--padding-s) var(--padding-s) rem(12);
      gap: rem(8);
    }



    .links__button {
      display: block;
      width: max-content;
      height: auto;
      padding: 0;
      border:none;
        border-bottom: rem(1) solid currentColor;
      background-color: transparent;
      text-align: left;
      text-decoration: underline;
      text-underline-offset: rem(3.5);

    }
  }
}
