@use 'sass:math';

.article-carousel {
  width: 100%;
  background-color: var(--color-background);

  .scroll-down & {
    top: var(--header-height-scrolled);
  }

  &__wrapper {
    &:not(.native) {
      cursor: grab;
    }

    &.grabbing {
      * {
        cursor: grabbing;
      }
    }

    &.moving {
      * {
        user-select: none;
      }

      img, a {
        pointer-events: none;
        user-select: none;
      }
    }

    &.native {
      .article-carousel__inner {
        overflow: scroll hidden;
        scroll-snap-type: x mandatory;
      }
    }
  }

  &__inner {
    @include style(typo-display-uppercase);
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--padding-m);
    gap: grid(2, 2);

    @include hide-scrollbar;

    img {
      width: grid(4);

      .grabbing & {
        pointer-events: none;
        user-select: none;
      }
    }
  }

  &__item {
    display: flex;
    scroll-snap-align: center;
      will-change: transform;

    .no-safari & {
      &:last-child {
        position: relative;

        &::after {
          position: relative;
          top: 0;
          left: 0;
          min-width: grid(4, 1);
          max-width: grid(4, 1);
          height: 100px;
          content: ''
        }
    }
    }

    &:first-child {
      margin-left: grid(4, 1);
    }



    span {
      @include style(typo-base-uppercase);
      display: block;
      overflow: hidden;
      width: grid(4);
      margin-top: var(--padding-s);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include tablet-portrait {
    &__wrapper {
      width: 100%;
    }

    &__inner {
      gap: grid(0, 1);
    }

    &__item {
      &:first-child {
        margin-left: grid(1, 1);
      }

      &:last-child {
        margin-right: grid(1, 1);
      }
    }
  }
}
