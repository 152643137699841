$quad-ease-in: cubic-bezier(.55, .085, .68, .53);
$cubic-ease-in: cubic-bezier(.55, .055, .675, .19);
$quart-ease-in: cubic-bezier(.895, .03, .685, .22);
$quint-ease-in: cubic-bezier(.755, .05, .855, .06);
$sine-ease-in: cubic-bezier(.47, 0, .745, .715);
$expo-ease-in: cubic-bezier(.95, .05, .795, .035);
$circ-ease-in: cubic-bezier(.6, .04, .98, .335);

$quad-ease-out: cubic-bezier(.25, .46, .45, .94);
$cubic-ease-out: cubic-bezier(.215, .61, .355, 1);
$quart-ease-out: cubic-bezier(.165, .84, .44, 1);
$quint-ease-out: cubic-bezier(.23, 1, .32, 1);
$sine-ease-out: cubic-bezier(.39, .575, .565, 1);
$expo-ease-out: cubic-bezier(.19, 1, .22, 1);
$circ-ease-out: cubic-bezier(.075, .82, .165, 1);

$quad-ease-in-out: cubic-bezier(.455, .03, .515, .955);
$cubic-ease-in-out: cubic-bezier(.645, .045, .355, 1);
$quart-ease-in-out: cubic-bezier(.77, 0, .175, 1);
$quint-ease-in-out: cubic-bezier(.86, 0, .07, 1);
$sine-ease-in-out: cubic-bezier(.445, .05, .55, .95);
$expo-ease-in-out: cubic-bezier(1, 0, 0, 1);
$circ-ease-in-out: cubic-bezier(.785, .135, .15, .86);

$ease-in: cubic-bezier(0.8, 0, 1, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
