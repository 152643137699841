.footer-links {
	display: flex;
	width: grid(6);
	flex-wrap: wrap;
	padding-top: rem(10);
	gap: rem(20);

  &__group {
    display: flex;
    flex-direction: column;
    gap: var(--padding-xl);
  }

	&__block {
		display: flex;
		width: grid(2);
		flex-direction: column;
		@extend %typo-base-uppercase;
	}

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: rem(12);
		text-align: left;


		&::after {
			display: none;
			margin-right: rem(-10);
			content: '';
			@extend .icon__plus-alt;
			@extend .icon-large;
		}
	}


	&__content {
		display: flex;
		flex-direction: column;

    & > a {
      color: var(--color-grey-medium);
      transition: color var(--opacity-duration) ease-in-out;

      &:not(:last-child) {
        margin-bottom: rem(3);
      }

      &.inactive {
        color: var(--color-grey-medium);
      }

      &.active {
        color: var(--color-main);
      }
    }
	}

	&__link {
		color: var(--color-grey-medium);
		text-align: left;



    &::after {
      content: none;
    }
	}
}

@include tablet-portrait {
	.footer-links {
		width: 100%;
		flex-direction: column;
    padding-top: 0;
		gap: 0;

    &:last-child {
      border-bottom: 1px solid var(--color-grey-divider-light);
    }

		&__header {
			height: rem(72);
			border-top: 1px solid var(--color-grey-divider-light);
			margin: 0;

			&::after {
				display: block;
			}
		}

    &__group {
      gap: 0;
    }

		&__block {
			width: grid(6, 2);

			& > * {
				padding: grid(0, 1);
			}

			&.opened {
				.footer-links__content {
					display: flex;
				}
			}
		}

		&__content {
			display: none;
			padding-top: 0;
		}
	}
}

@include not-tablet-portrait {
	.footer-links {
		.icon__plus {
			display: none;
		}

		&__header {
			pointer-events: none;
		}
	}
}
