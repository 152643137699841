.cart {
  overflow: hidden scroll;
  height: 100%;

  @include custom-scrollbar;

  .page__inner {
    height: 100%;
  }

  &__header {
    position: sticky;
    z-index: 20;
    top: 0;
    background: var(--color-background);

    &-inner {
      @extend .panel__header;
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);
    }

  }

  &__content {
    display: flex;
    max-width: grid(5,1);
    height: calc(100% - var(--cart-header-height) - var(--cart-reinsurance-height, 0px));
    flex-direction: column;
    justify-content: space-between;

  }

  &__promo {
    padding: var(--padding-m)
  }

  &__reinsurance {
    @include style(typo-base-uppercase);
    position: relative;
    display: flex;
    height: rem(52);
    background-color: #F7F7F7;
    color: var(--color-grey-medium);
    gap: rem(1);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: grid(1, 0);
      height: 100%;
      background: linear-gradient(to right, rgb(247 247 247 / 0%) 0%, #F7F7F7 100%);
      content: '';
      pointer-events: none;
    }

    &::before {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      display: block;
      width: grid(1, 0);
      height: 100%;
      background: linear-gradient(270deg, rgb(247 247 247 / 0%) 0%, #F7F7F7 100%);
      content: '';
      pointer-events: none;
    }

    &-item {
      position: absolute;
      top: rem(10) !important;
      flex-shrink: 0;
      opacity: 0;
      transform-origin: center;

      &-inner {
        display: flex;
        width: max-content;
        align-items: center;
        gap: var(--padding-s);
      }



      &.active {
        opacity: 1;
      }

      .icon {
        opacity: 0.432;
      }
    }

  }

  &__list-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.empty {
      // height: calc(100% - var(--cart-header-height));
      height: 100%;
    }
  }

  .checkout-total {
    padding-bottom: oldGutters(.5);
  }

  &__button {
    width: auto;
    margin: 0 oldGutters(1) oldGutters(1);
  }

  &__list {
    padding: 0 var(--padding-m);
  }

  &__list-item {
    & + & {
      border-top: var(--border-size) solid var(--color-grey-divider-light)
    }
  }

  &__empty {
    @include style(typo-base-body);
    padding: var(--padding-m);
  }

  &__related {
    h3 {
      @include style(typo-base-uppercase);
      padding: 0 var(--padding-m) var(--padding-m);
    }

    &-list {
      display: grid;
      overflow: hidden;
      box-sizing: border-box;
      grid-auto-rows: auto;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto;
    }
  }

  &__actions {
    position: sticky;
    z-index: 20;
    bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: var(--padding-m);
    margin-bottom: rem(100);
    background: var(--color-background);
    gap: var(--padding-m);

    &::before {
      position: absolute;
      top: 0;
      left: var(--padding-m);
      width: calc(100% - var(--padding-m) * 2);
      height: var(--border-size);
      background-color:var(--color-grey-divider-light);
      content:'';
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: var(--padding-m);
  }


  &__apple-pay {
    font-family: $sf-fontname;
    font-size: rem(18);
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0;
    line-height: rem(28);
    text-transform: unset;

    .button__inner {
      gap: rem(6);
    }

    .button__aside {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
    }

   svg {
    width: rem(38);
    margin-top: rem(3);
   }
  }
}

@include tablet-portrait {
  .cart {
    &__actions {
      position: sticky;
      bottom: 0;
      order: 4;
      margin-bottom: 0;

      &::before {
        left: 0;
        width: 100%;
      }
    }

    &__promo {
      order: 2;
      margin-bottom: rem(70);
    }

    &__content {
      max-width:var(--vw);

      // height: unset;
    }

    &__list {
      order: 1;
    }

    &__related {
      order: 3;
    }


    &__reinsurance {
      &-item {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        &-inner {
        }
      }
    }
  }
}
