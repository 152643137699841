.drawer {
  position: fixed;
  z-index: index('drawer');
  top: 0;
  width: grid(5, 1);
  height: var(--vh);
  max-height: var(--vh);
  background-color: var(--color-background);
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);

  &.opened {
    pointer-events: auto;
  }

  &.translating {
    .accordion__wrapper.drawer-panel {
      transition: unset !important;
      }
    }

  &__classic {
    left: 0;
  }

  &__reversed {
    right: 0;
  }

  &.size-double {
    width: grid(10, 1);
  }

  &__wrapper {
    display: flex;
    height: 100%;


  }

  @include tablet-portrait {
    $title-size: calc(var(--padding-s) + rem(32) + var(--padding-xl));
    width: grid(6, 2);
}
}
