.content-page {
  &__inner {
    @include wysiwyg;
    max-width: oldGrid(6);
    margin: oldGutters(-.5) auto 0;

    h1, h2, h3, h4 {
      margin: oldGutters(2) 0 oldGutters(1);
      text-align: center;

      &:first-child {
        margin-top: 0;
      }
    }

    .idz-online {
      color: $black;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;

    a {
      --color: #{$grey};
    }

    & > * {
      flex-basis: 33%;
      padding: 0 rem(10);
      margin-bottom: rem(20);
      text-align: center;
    }
  }
}

@include tablet-portrait {
  .content-page {
    &__inner {
      margin: 0 auto;

      h1, h2, h3, h4 {
        &__title {
          margin: oldGuttersM(2) 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    &__columns {
      flex-flow: column nowrap;
      padding: 0 oldGuttersM(1);

      & > * {
        flex-basis: 100%;
        margin-bottom: rem(10);
      }
    }
  }
}
