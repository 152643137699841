.scroll-indicator {
  $offset: oldGutters(1) + rem($header-height);
  position: sticky;
  z-index: 100;
  bottom: 0;
  width: 100%;
  height: 0;
  order: 999;
  padding-bottom: $offset;
  margin-top: -$offset;
  pointer-events: none;
  text-align: right;

  .appearing & {
    position: relative;
  }

  &.bottom-left {
    top: initial;
    bottom: oldGutters(1);
    padding-bottom: 0;
    margin-top: 0;
    text-align: left;

    .appearing & {
      position: absolute;
    }

    .scroll-indicator__inner {
      top: initial;
      right: initial;
      bottom: 0;
      left: oldGutters(-1);
      height: auto;
    }
  }

  &__inner {
    position: absolute;

    // right: oldGutters(-1);
    right: 0;
    bottom: 0;
    display: flex;
    height: var(--vh);
    align-items: center;
  }

  &__list {
    padding-left: oldGutters(1);
    pointer-events: all;
  }

  &__item {
    margin-bottom: remMin(12);
    color: var(--color-grey-medium);
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: remMin(12);
    }
  }
}

@include tablet-portrait {
  .scroll-indicator {
    top: rem($header-height-m);
    bottom: initial;
    width: 100%;
    height: rem($header-height-m);
    order: initial;
    padding: 0;
    margin: 0;
    transition: transform .5s $cubic-ease-out;

    &.bottom-left {
      display: none;
    }

    &::before {
      position: absolute;
      display: block;
      background: var(--background);
      content: '';
      inset: rem(-$header-height-m) 0 0;
    }

    .appearing & {
      top: 0;
    }

    .scroll-down & {
      transform: translateY(-100%);
    }

    &__inner {
      position: static;
      height: 100%;
      padding: 0 oldGuttersM(1);
      margin: 0 oldGuttersM(-1);
      background: var(--background);
      pointer-events: all;
    }

    &__item {
      &, &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    & &__list::after {
      position: absolute;
      z-index: 200;
      bottom: 0;
      width: gridM(6, 0);
      border-bottom: 1px solid $border-color;
      content: '';
      opacity: 0;
    }

    &:not(.show) &__list::after {
      opacity: 1;
      transition: opacity .5s $cubic-ease-in-out;
    }

    &__list {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }
  }
}
