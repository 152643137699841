.pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--padding-m);
  margin: var(--padding-m) grid(0, 1);

  &__text {
    @include style(typo-base-uppercase);
    color: var(--color-grey-medium);
  }

  &__track {
    position: relative;
    width: grid(4);
    height: rem(2);
    background-color: var(--color-grey-divider-light);

    &-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: var(--track-width);
      background-color: var(--color-main);
    }
  }

  .button {
    padding: rem(15) rem(50);
  }

}
