.blueshift-popin {
  &__wrapper {
    position: fixed;
    z-index: 450;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 60%;
    transform: translate(-50%, -60%);
  }

  &__inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: var(--grid-gutter);
    background-color: var(--color-background);
    opacity: 0;

    --no-prevent-scroll: 1;
  }

  &__wrapper, &__overlay {
    visibility: hidden;
  }

  .app-started & {
    &__wrapper, &__overlay, &__inner {
      @include transition ('transform, opacity, visibility',.4s, $cubic-ease-out );
    }
  }

  &.opened & {
    &__wrapper {
      transform: translate(-50%, -50%);
      visibility: visible;
    }

    &__inner {
      opacity: 1;
    }

    &__overlay {
      opacity: .7;
      pointer-events: auto;
      visibility: visible;
    }
  }

  &__overlay {
    @include fullscreen(fixed);
    z-index: 449;
    background: $black;
    opacity: 0;
    pointer-events: none;
  }
}
