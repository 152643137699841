.sitemap {
  &-wrapper  {
    display: flex;
    justify-content: space-between;
    padding: var(--padding-m);
    margin-top: var(--gap-l);
  }

  &-list {
    display: flex;
    width: grid(6);
    flex-direction: column;
    gap: var(--gap-xl);
  }

  &-aside {
    display: flex;
    width: grid(6);
    flex-direction: column;
    gap: var(--padding-xl);

    & > * {
      width: grid(5);
      margin-left: auto;
    }
  }

  &-block {
    display: flex;
    flex-direction: column;

    h2 {
      @include style(typo-base-uppercase);
      position: sticky;
      z-index: 10;
      top: calc(var(--page-top));
      padding-bottom: rem(12);
      background-color: var(--color-background);
      transition: all var(--header-duration) cubic-bezier(0.645, 0.045, 0.355, 1);

      .scroll-down & {
      top: calc(var(--header-height-scrolled));

      }
    }
  }

  &-items {
    display: grid;
    color: var(--color-grey-medium);
    gap: rem(6) var(--grid-gutter);
    grid-template-columns: repeat(3, 1fr);
    @include style(typo-base-uppercase);

    a {
      transition: color var(--opacity-duration) ease-in-out;
      @include hover {
        &:hover {
          color: var(--color-main);

        }
      }
    }
  }

  @include tablet-portrait {
    &-wrapper {
      flex-direction: column;
      margin-top: var(--gap-xl);
      gap: var(--gap-xl);
    }

    &-list {
      width: 100%;
    }

    &-aside {
      width: 100%;

      & > * {
        width: grid(6);
      }
    }

    &-block {
      h2 {
        top: calc(var(--header-height));
        padding-bottom: rem(16);
      }
    }

    &-items {
      gap: rem(8) var(--grid-gutter);
      grid-template-columns: repeat(2, 1fr);

    }

  }
}
