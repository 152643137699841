.tabs {
  &__header-track {
    position: absolute;
    bottom: rem(-0.5);
    left: 0;
    width: 0;
    height: rem(0.5);
    background-color: var(--color-black);
    transition: left .25s ease-in-out, width .25s ease-in-out;
  }

  &__header-links {
    position: relative;
    display: flex;
    gap: rem(16);
  }

  &__header-link {
    padding: rem(10) 0;
    @extend %typo-base-uppercase;
    color: var(--color-grey-medium);
    transition: color var(--opacity-duration) ease-in-out;

    &.active {
      color: var(--color-black);
    }

    @include hover {
      &:hover {
        color: var(--color-main);
      }
    }

    [data-page="Customer"] & {
      &:last-of-type {
        position: absolute;
        top: 0;
        right: 0;
        color: var(--color-black);
      }
    }
  }


  @include tablet-portrait {
    max-width: var(--vw);

    &__header-track {
      display: none;
    }

    &__header-links {
      overflow-x: scroll;
      @include hide-scrollbar;
    }

    &__header-link {
      padding: var(--padding-m) 0;
      white-space: nowrap;

      &.active {
        border-bottom: none;
        color: var(--color-black);
      }

      &:first-of-type {
        margin-left: var(--padding-m);
      }

      &:last-of-type {
        position: relative;
        display: flex;

        &::after {
          position: relative;
          top: 0;
          right: 0;
          display: block;
          width: var(--padding-m);
          height: 100%;
          content:'';
        }
      }

      [data-page="Customer"] & {
        &:last-of-type {
          position: relative;
          color: var(--color-grey-medium);
        }
      }
    }
  }
}
