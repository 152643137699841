.image-zoom {
  position: fixed;
  z-index: index('overlay-1');
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  opacity: 0;
  overflow-x: hidden;
  pointer-events: none;

  &.opened  {
    pointer-events: all;
  }

  .close__button {
    position: fixed;
    z-index: 5;
    top: var(--padding-s);
    right: var(--padding-s);
    filter: invert(100%);
    mix-blend-mode: difference;

  }

  &__navigation {
    position: absolute;
    z-index: 5;
    right: var(--padding-s);
    bottom: var(--padding-s);
    display: flex;
    align-items: center;
    filter: invert(100%);
    gap: var(--padding-m);
    mix-blend-mode: difference;
  }

  &__prev, &__next {
    @include style(typo-base-uppercase);
    display: flex;
    align-items: center;
    gap: rem(5);
  }

  &__item {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: var(--vh);
    max-height: 100%;
    overflow-y: scroll;
    @include hide-scrollbar;


    &.active {
      z-index: 1;
      display: block;
    }

    &.enter {
      z-index: 2;
    }
  }


  &__header {
    position: absolute;
    top: var(--padding-m);
    left: var(--padding-m);
    @include style(typo-base-uppercase);
    filter: invert(100%);
    mix-blend-mode: difference;
  }

  &__image {
    display: flex;
    max-width: grid(5,1);
    height: var(--vh);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  &__products {
    position: absolute;
    z-index: 10;
    bottom: var(--padding-m);
    left: var(--padding-m);
    overflow: hidden auto;
    width: grid(3,2);
    max-height: calc(var(--vh) * 0.7);
    padding-right: var(--padding-m);
    @include custom-scrollbar;
  }


  @include tablet-portrait {
    &__header {
      z-index: 2;
    }

    &__item {
      overflow: hidden;

      &.active {
        .collection & {
          z-index: unset;
        }
      }
    }

    &__navigation {
      z-index: 5;
      transition: opacity 300ms ease-in-out;

      .nav-hidden & {
        opacity: 0;
        pointer-events: none;
      }

      .collection & {
        position: absolute;
        top: calc(var(--look-offset) / 2);
        right: 0;
        bottom: unset;
        width: 100%;
        justify-content: space-between;
        padding: 0 var(--padding-s);




        button {
          span {
            display: none;
          }
        }
      }
    }

    &__swiper {
      display: block;
      padding: var(--padding-s) 0 var(--padding-m) 0;

      &-inner {
        width: rem(40);
      height: rem(2);
      margin: 0 auto;
      background-color: var(--color-grey-medium);
      }

    }

      &__image {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        max-width: unset;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;

        .article-gallery & {
          height: var(--vh);
          align-items: center;
          justify-content: center;
        }


        img {
          width: 100%;
          max-width: unset;
          height: auto;
        }
      }

      &__products {
        position: relative;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--vh);
        max-height: unset;
        padding-right: 0;
        overflow-y: scroll;
        overscroll-behavior: none;


        &-inner {
          &::before {
            position: relative;
            z-index: -1;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height:calc(var(--vh) * .8);
            content:'';
            pointer-events: none;
          }
        }

        &-list {
          position: relative;
          z-index: 4;
          padding: 0 var(--padding-m);

          // margin-top: -160px;
          background-color: var(--color-background);
          pointer-events: all;
          transition: margin-top var(--header-duration) ease-in-out;
        }
      }
  }
}
