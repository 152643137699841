.list-block-content {
  &__wrapper {
    width: 100%;

    &:nth-child(even) {
      .list-block-content__link {
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: 0;
      }
    }
  }

  &__inner {
  }

  &__link {
    position: relative;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__text {
    position: sticky;
    bottom: 0;
    left: 0;
    width: grid(4, 2);
    padding: grid(0, 1);

    &-title {
      @include style(typo-display-m);
      font-size: clamp(rem(60), 7.5vw, rem(140));
      word-wrap: break-word;
    }

    &-subtitle {
      @include style(typo-base-body);
      margin: 0;
    }
  }

  &__img {
    width: grid(8, 1);
    min-height: var(--ivh);
    object-fit: cover;

  }
}

@include tablet-portrait {
  .list-block-content {
    &__wrapper {
      &:nth-child(even) {
        .list-block-content__link {
          flex-direction: row;
          justify-content: flex-start;
        }
      }
    }

    &__link {
      height: var(--ivh);
    }

    &__text {
      z-index: 20;
      width: 100%;
      color: var(--color-background);

      &-title {
        font-size: rem(60);
        letter-spacing: -0.02em;
      }
    }

    &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
