.redirect-page {
  &.page {
    @include fullscreen(fixed);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &__subtitle {
    margin-top: rem(10);
    color: var(--color-grey-medium);
  }
}
