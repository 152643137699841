.pickup {
  $top: rem(50);

  &.page {
    height: 100%;
    padding: 0;
  }

  &__title {
    display: none;
  }

  &__text {
    width: 100%;
    margin: 0 auto;
    color: var(--color-grey-medium);
    text-align: center;

  }

  &__wrapper {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    margin-right: 0;
  }

  &__submit[type='submit'] {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    padding-left: rem(20);
    color: var(--color-grey-medium);
    opacity: 0;
    transform: translateY(rem(-5));
    @include transition('opacity, transform', .3s, $cubic-ease-out);
  }

  &__map {
    @extend .stores__map;

    &-wrapper {
      @extend .stores__map-wrapper;
    }

    .pickup & {
      top: 0;
      width: oldGrid(6, 2);
      height: var(--popin-height);
      margin: 0;

      &-wrapper {
        margin: 0;
      }
    }
  }

  &__inner {
    --background: #{$alt-background};
    @extend .stores__inner;
    padding: 0 oldGutters(1);

    .pickup & {
      width: oldGrid(4, 1);
      margin-top: 0;
    }
  }

  &__router {
    @extend .stores__router;
  }

  &__header {
    @extend .stores__header;

    .field {
      margin-bottom: 0 !important;
    }

    &.valid .pickup__submit {
      opacity: 1;
      transform: none;
    }

    .pickup & {
      padding: $top 0 0;
    }

    &-inner {
      position: relative;
    }
  }

  &__list {
    @extend .stores__list;
  }

  &__item {
    position: relative;
    @extend .stores__item;

    &-hours {
      @extend .stores__item-hours;
    }

    &-subtitle {
      @extend .stores__item-subtitle;
    }

    &-contact {
      @extend .stores__item-contact;
    }

    &-link {
      @extend .stores__item-link;
    }

    &-distance {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 !important;
      color: var(--color-grey-medium)
    }
  }
}

@include tablet-portrait {
  $top: rem($header-height-m);

  .pickup {
    &__wrapper {
      flex-direction: column;
    }

    &__title {
      position: sticky;
      z-index: 52;
      top: 0;
      display: flex;
      height: rem($header-height-m);
      align-items: center;
      padding-left: oldGuttersM(1);
    }

    & &__map {
      width: gridM(6, 2);
      height: gridM(6, 2);

      &-wrapper {
        position: relative;
        z-index: 50;
      }
    }

    & &__inner {
      --background: #{$background};

      &::before {
        display: none;
      }
      width: 100%;
      margin-top: -$top + oldGuttersM(1);
    }

    & &__header {
      top: 0;
      padding: ($top ) 0 0;
    }

    & &__list {
      background-color: transparent;
    }
  }
}
