.checkout-shipping {
  .radio-group{
    &__content {
      .field:last-child, .select:first-child {
        margin-bottom: 0;
      }
    }

    &__item {
      border: 1px solid $border-color;
      margin-bottom: var(--padding-m);

      &:last-child {
        margin-bottom: 0;
      }

      &-inner {
        padding: var(--padding-m);
      }
    }

  }
}
