.variants {
  @include style(typo-base-uppercase);
  display: flex;
  border-top: var(--border-size) solid var(--color-grey-divider-light);
  color: var(--color-grey-medium);

  &.has_alt_colors {
    flex-direction: column;

    .variants__variant-item {
      padding: 0;

      &:first-child:not(:last-child) {
        padding: 0;
        border-right: none;
      }
    }


    .variants__variant-item.variants__variant-color {
      border-bottom: var(--border-size) solid var(--color-grey-divider-light);

      .returns__item-variants & {
        padding-left: var(--padding-m);

        & + .variants__variant-size {
              padding-right: var(--padding-s);
            border-right: var(--border-size) solid var(--color-grey-divider-light);

            &[data-one-size="true"] {
              padding-right: var(--padding-m);
              border-right: var(--border-size) solid var(--color-grey-divider-light);
            }
        }
      }
    }

    // & > * {
    //   &:not(:first-child) {
    //     border-top: var(--border-size) solid var(--color-grey-divider-light);
    //   }
    // }
  }

  &.is_bundle {
    flex-direction: column;

    .variants__variant-item.variants__variant-color {
        border-right: 0;
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);

        .variants__variant-item-inner {
          padding-left: 0;
        }
    }

    .variants__variant-item.variants__variant-size {
      padding-left: 0;



      .variants__variant-item-inner {
        padding-left: 0;

        &:last-of-type {
          padding-left: rem(10);
        }
      }
    }
  }

  .variants__variant-size {
    border-bottom: var(--border-size) solid var(--color-grey-divider-light);


  }


  &__variant {
    &-toggle {
      display: flex;
      align-items: center;
    }

    &-item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;


      &-inner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: rem(8) 0;
        gap: rem(20);

        .icon {
          transition: opacity var(--opacity-duration) ease-in-out;

        }

        button:not(:disabled) & {
          @include hover {
            &:hover {
              color: var(--color-main);
              transition: color var(--opacity-duration) ease-in-out;

              .icon {
                opacity: 1;
              }
            }
          }
        }

        &.is_os {
          height: rem(48);
        }

        &:first-child {
          &:not(:last-child) {
            padding-right: var(--padding-s);
            border-right: var(--border-size) solid var(--color-grey-divider-light);
          }
        }
      }



      span {
        display: flex;
        align-items: center;
      }

      .icon {
        opacity: 0.453;
      }

      &:first-child:not(:last-child) {
        padding-right: rem(10);
        border-right: var(--border-size) solid var(--color-grey-divider-light);
      }

      &:last-child {
        padding-left: rem(10);
      }
    }

    &-size[data-one-size] {
      pointer-events: none;
    }
  }

  &__color {
    display: flex;
    height: rem(32);
    align-items: center;
    gap: var(--gap-s);

    &-swatch {
      @include style(color-swatch)
    }

    p {
      overflow: hidden;
      max-width: grid(2, 2);
      margin-top: 0;
      text-align: right;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}


@include tablet-portrait {
  .variants {
    flex-direction: column;
    border-top: 0;
    color: var(--color-black);

    &:not(.has_alt_colors) &:not(.returns__item-variants) {
      display: none;
    }

    &.has_alt_colors {
      .variants__variant-item {
        &:first-child:not(:last-child) {
          padding-right: var(--padding-s);
          padding-left: grid(0, 1);
        }
      }
    }

    &__variant-size {
      display: none;

      .returns__item-variants & {
        display: flex;
        height: unset;
        color: var(--color-grey-medium);
        text-transform: uppercase;

        .icon {
          opacity: 0.453;
        }

         span {
            font-size: rem(14);
            line-height: 120%;
          }
      }
    }

    &__variant-item {
      height: rem(72);
      padding-right: var(--padding-s);
      padding-left: grid(0, 1);
      text-transform: capitalize;

      &-inner {
        gap: grid(0, 1);

        & > span {
          font-size: rem(16);
          line-height: 120%;
        }
      }


      .icon {
        opacity: 1;
      }
    }

    &__variant-toggle {
      width: 100%;
      justify-content: flex-end;
    }

    &__color {
      height: unset;
      justify-content: space-between;
      text-transform: uppercase;

      p {
        overflow: hidden;
        max-width: grid(3, 3);
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-swatch {
        min-width: rem(8);
      }

      .single & {
        padding-right: var(--padding-s);
      }
    }
  }


}
