.product-infos {
  z-index: 400;

  u {
    text-decoration: none;
  }

  &__sku {
    &::before {
      position: relative;
      content: attr(data-label);
    }
  }

 &__right {
   width: 100%;
   background-color: var(--color-background);

   &-title {
     display: flex;
     align-items: flex-start;
     justify-content: space-between;
     padding: var(--padding-s) var(--padding-s) var(--padding-m) var(--padding-m);
     border: var(--border-size) solid var(--color-grey-divider-light);
     text-transform: uppercase;

     & h2 {
      display: flex;
      padding-top: var(--padding-s);
      gap: grid(0,1);
     }
   }

   &-image {
     width: rem(88);

     & img, & video {
       width: 100%;
       height: 100%;
       object-fit: cover;
       object-position: center;
      }
   }



   .infos {
    @include style(typo-base-body);
    display: flex;
    flex-direction: column;
    color: var(--color-grey-medium);
    gap: var(--padding-m);

    table {
      width: 100% !important;
    }

    p {
      margin-top: rem(0);
      margin-bottom: 0;
    }

    & ul {
      position: relative;
      margin-left: rem(16);
    }

    & li {
      &::before {
        position: absolute;
        left: rem(-15);
        content: '•';
      }
    }


    & a {
      text-decoration: underline;
      text-underline-offset: rem(3);
    }

   }
 }

 &__products {
   width: 100%;
   height: calc(var(--ivh) - var(--product-infos-right-height));
   overflow-y: auto;

  //  border-top: 0.5px solid var(--color-grey-divider-light);

   @include custom-scrollbar;

   &-item {
     position: relative;
     display: flex;
     padding: var(--padding-m) 0;
     border-bottom: 0.5px solid var(--color-grey-divider-light);

     img {
       width: grid(0.9);
       margin-right: var(--padding-m);
     }

     &-wishlist {
       position: absolute;
       top: 0;
       right: calc(var(--padding-s) * -1);
     }
   }

   .accordion__inner {
    padding-bottom: var(--padding-xl);
   }
 }

  &__guides {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

 &__details {
   text-transform: uppercase;

   &-price {
     margin-top: rem(6);
     color: var(--color-grey-medium);
   }

   &-colors {
     display: flex;
     margin-top: rem(12);
     gap: rem(8);
     list-style: none;

     li {
       width: rem(8);
       height: rem(8);

       span {
         display: block;
         width: 100%;
         height: 100%;
       }
     }

   }
 }
}

@include tablet-portrait {
 $top-offset: rem(150);
 $title-size: calc(var(--padding-s) + rem(32) + var(--padding-xl));

 .product-infos {
  &.drawer {
    position: relative;
    z-index: 3;
    height: auto;
    max-height: unset;
    opacity: 1 !important;
    pointer-events: all;
    transform: none !important;
  }

  .drawer__wrapper {
    opacity: 1 !important;
  }

   &__right {
     width: 100%;

     &-title {
       display: none;
       padding-bottom: var(--padding-xl);
     }
   }

   &__products {
     width: 100%;
     height: auto;
   }
 }
}
