.order-block {
  display: flex;
  height: oldGutters(4);
  flex-direction: column;
  justify-content: space-between;
  padding: rem(30);
  border: 1px solid $border-color;

  & + & {
    margin-top: oldGutters(1);
  }

  &__header, &__footer {
    display: flex;
    align-items: flex-start;
  }

  &__link {
    margin-left: rem(6);
    white-space: nowrap;
  }

  &__id {
    margin-bottom: rem(10);
  }

  &__date, &__link, &__open {
    color: var(--color-grey-medium)
  }
}

@include tablet-portrait {
  .order-block {
    display: flex;
    height: rem(160);
    flex-direction: column;
    justify-content: space-between;
    padding: oldGuttersM(1);
    border: 1px solid $border-color;

    &__link {
      display: none;
    }

    & + & {
      margin-top: oldGuttersM(1);
    }
  }
}
