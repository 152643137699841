.auth {
    &__title {
      @extend .panel__header;

      .scrollable & {
        position: sticky;
        z-index: 10;
        top: 0;
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);
        background-color: var(--color-background);
      }
    }

    &__wrapper {
      display: flex;
      height: calc(100% - rem(102));
      flex-direction: column;
      justify-content: space-between;
      gap: rem(40);


      form {
        display: flex;
        flex-direction: column;
        gap: var(--grid-gutter);
      }
    }

    &__form {
      &-link {
        display: block;
        width: 100%;
        margin-top: rem(-20);
        color: var(--color-grey-medium);
        text-align: right;
        transition: color var(--opacity-duration) ease-in-out;
        @include style(typo-base-uppercase);

        &:hover {
          color: var(--color-main);
        }
      }

      &-group {
        display: flex;
        flex-direction: column;
        gap: rem(16);
      }
    }

    &__top {
      display: flex;
      height: 100%;
      flex-direction: column;
      gap: var(--grid-gutter);
    }

    &__bottom {
      position: sticky;
      bottom: 0;
      display: flex;
      flex-direction: column;
      border-top: var(--border-size) solid var(--color-grey-divider-light);
      background-color: var(--color-background);
      gap: var(--grid-gutter);

    }

    &__switch {
      @include style(typo-base-uppercase);

      span {
        color: var(--color-grey-medium);
      }

      a {
        @include basic-underline;
      }
    }

    &__register {
      &-opt-ins {
        display: flex;
        flex-direction: column;
        gap: var(--padding-m);
      }

      &-submit {
        padding: var(--padding-m)  var(--padding-m) 0;

        .scrollable & {
          padding-bottom: var(--padding-m);
        }
      }
    }

    &__message {
      display: flex;
      flex-direction: column;
      padding: var(--padding-m);
      background-color: var(--color-grey-light);
      gap: var(--padding-s);

      .checkout-personal-details & { margin: 0 0 rem(40); }

      .checkout & {
        padding: 0;
        background-color: transparent;
        color: var(--color-main);
      gap: 0;

        &-title {
        @include style(typo-base-body);
        text-transform: unset;
        }
      }

      &-title {
        @include style(typo-base-uppercase);
      }

      &-text {
        @include style(typo-footnote-l);
        margin: 0;
        color: var(--color-grey-medium);
      }
    }
}

.login, .register {
  height: 100%;

  .page__inner {
    height: 100%;
  }
}

.register {
  form {
    height: 100%;
    justify-content: space-between;

    button[type="submit"] {
      align-self: flex-end;
    }
  }

  .auth__wrapper {
    gap: var(--padding-m);

    .form-group {
      display: flex;
      flex-direction: column;
      padding: var(--padding-m) 0;
      gap: rem(16);
    }
  }

  .auth__form-group {
    display: flex;
    flex-direction: column;
    padding: 0 var(--padding-m);
    gap: rem(16);

    .form__inline {
      padding-bottom: rem(24);
    }

    .form-group {
      padding: rem(24) 0;
    }
  }

  .auth__switch {
    padding: 0 var(--padding-m)  var(--padding-m) ;

  }
}

.login, .reset-password {
  .auth__wrapper {
    padding: 0 var(--padding-m) var(--padding-m) var(--padding-m);

  }
}
@include tablet-portrait {
 .auth {
  &__register {
    &-submit {
      border-top:0;
    }
  }

  &__wrapper {
    height: calc(100% - rem(82));

  }
 }

 .register {
    .auth {
      &__register-submit {
        padding-bottom: 0;

      }

      &__title {
        border-bottom: var(--border-size) solid var(--color-grey-divider-light);
      }

      &__wrapper {
        height: 100%;
      }

      &__form-group {
        padding-top: var(--padding-xl);
        gap: var(--padding-m);

        .form__inline {
          padding-bottom: var(--padding-m);
        }

        .form-group {
          padding: var(--padding-m) 0;
        }
      }


    }
 }
}
